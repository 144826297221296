import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import Loading from '../Helpers/Loading';
import { Resizable } from 're-resizable';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Verse from './Verse.js';
import { PortalWithState } from 'react-portal';
import TextareaAutosize from 'react-autosize-textarea';
import jsPDF from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faPrint,
	faChevronLeft,
	faChevronRight,
	faClone,
	faCopy,
	faFileAlt,
	faInfoCircle,
	faShareAlt,
	faMap,
	faCheckCircle,
} from '@fortawesome/fontawesome-free-solid';
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV';
import { Scrollbars } from 'react-custom-scrollbars';
import Volumes from '../Library/Volumes.js';
import Books from '../Library/Books.js';
import VersesChapters from './VersesChapters';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Switch from 'react-switch';
import '../../assets/css/verses.css';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn, DeletePopUpWrapper } from '../Shared/SharedStyles';

import { VersesContext } from '../../ContextProviders/VersesProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { BulkMoveContext } from '../../ContextProviders/BulkMoveProvider';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import {
	removeMarkTag,
	bbwBold,
	bbwUnderline,
	bbwItalic,
	bbwStrike,
	bbwProcessToggle,
	goodToProceedToggle,
	bbwClear,
	replaceAllMarks,
	bbwIsSelectionValid,
	unnecessaryTags,
} from '../BBWFormatter/BBWFormatter';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
import { VerseOptionsContext } from '../../ContextProviders/VerseOptionsProvider';
import VerseOptions from './VerseOptions.js';
const hoverColor = '#1a56eb';
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: ${hoverColor};
	}
`;

const ImagePopUpWrapper = styled.div`
	left: 25%;
	right: 25%;
	top: 10%;
	// height: 100%;
	// widht: 100%
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	text-align: center;
`;

const PrintIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 30px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;
const LinkIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 50px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;

const GrabPaneArea = styled.div`
	cursor: grab;
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	position: absolute;
	top: 0;
	left: 0;
	height: 200px;
	// overflow: hidden;
	padding-top: 35px;
	:focus {
		outline: none;
	}
	// z-index: 100
`;

const ToggleLDSFootnotes = styled.label`
	position: absolute;
	left: 0px;
	top: 10px;
	text-align: center;
	padding: 0px;
	display: flex;
	justify-content: center;
	font-size: 13px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	line-height: 16px;
`;

const BreadCrumbWrapper = styled.div`
	width: 100%;
`;

const BreadCrumbList = styled.ul`
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	font-size: 0;
`;

const BreadCrumbItem = styled.li`
	color: #6a7583;
	font-size: 16px;
	line-height: 22px;
	display: inline-block;
	margin-right: 6px;
`;

// const BreadCrumbItemText = styled.h6`
// 	cursor: pointer;
// 	font-size: 18px;
// 	&:hover {
// 		background-color: #ececec;
// 	}
// `;
const BreadCrumbItemText = styled.button`
	padding: 0;
	cursor: pointer;
	font-size: 18px;
	color: #6a7583;
	outline: none;
	font-weight: 500;
	background-color: transparent;
	border: none;
	&:hover {
		background-color: #ececec;
	}
	&:focus {
		outline: none;
	}
`;

const ToggleSideWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	margin-top: 10px;
`;

const MultiDragWrapper = styled.div`
	display: flex;
	position: absolute;
	right: 20%;
	cursor: pointer;
	color: #6a7583;
	font-size: 18px;
`;

const NextChapterWrapper = styled.div`
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;
`;
//? Just in case to revert
// const NextChapterText = styled.span`
// 	cursor: pointer;
// 	margin-right: 5px;
// 	color: #6a7583;
// 	&:hover {
// 		background-color: #ececec;
// 	}
// 	// border: 1px solid black;
// `;

const NextChapterText = styled.button`
	display: flex;
	padding: 0;
	cursor: pointer;
	margin-right: 5px;
	color: #6a7583;
	outline: none;
	background-color: transparent;
	border: none;
	&:hover {
		background-color: #ececec;
	}
	&:focus {
		outline: none;
	}
	// border: 1px solid black;
`;

const OpenPaneIconWrapper = styled.span`
	position: absolute;
	right: 0;
	font-size: 22px;
	color: #7d8085;
	cursor: pointer;
	padding-right: 10px;
	&:hover {
		color: #1a56eb;
	}
`;

const VersesDisplayWrapper = styled.div`
	width: 100%;
	// height: 700px;
	height: 100%;
	visibility: visible;
`;

const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const ChapterHeadingWrapper = styled.div`
	margin-bottom: 3px;
	margin-top: px;
	display: inline-flex;
	// width: 100%;
	background: #fafafa;
	padding: 5px 5px 0px 11px;
	width: calc(100% - 40px);
`;

const ChapterHeadingTextWrapper = styled.div`
	padding-left: 0px;
	width: 90%;
`;

const ChapterHeadingNumber = styled.h2`
	color: #393f47;
	font-size: 21px;
	line-height: 27px;
	text-align: left;
	margin-right: 10px;
`;

const ChapterHeadingText = styled.div`
	font-size: 15px;
	text-align: left;
	margin-top: 5px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-style: italic;
`;

const VerseNoteTextboxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	position: relative;
	width: 100%;
	min-height: 1px;
	padding: 0 15px;
	padding-left: 8px;
	padding-right: 6px;
	// padding-right: 4px;
	// padding-left: 25px;
	margin-top: 50px;
`;

const LinkText = styled.span`
	color: #0000ff;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
const DraggingItem = styled.div`
	background-color: transparent;
	height: 50px;
	width 50px;
	color: #6a7583;
	font-size: 75px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-left: 250px;
	
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	padding-top: 12px;
	padding-bottom: 0;
`;
const InfoIcon = styled.div`
	margin-left: 5px;
	color: #7d8085;
	padding-bottom: 8px;
	&:hover {
		cursor: pointer;
		color: #1a56eb;
	}
`;
const LinkInfo = styled.div`
	// border: 1px solid blue;
	color: #324599;
`;

const ResourceButton = styled.button`
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	background-color: #324599;
	border: none;
	color: #fff;
	&:hover {
		background-color: #1a56eb;
	}
	padding: 0 5px;
`;
const TextFormatter = styled.div`
	position: absolute;
	background-color: #444444;
	border-radius: 13px;
	padding: 2px 15px;
	z-index: 999;
`;
const FormatBtn = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	outline: 0;
	font-family: serif;
`;
const FormatBtnBold = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
`;
const FormatBtnBgColor = styled.button`
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
	background-color: #727272;
	padding: 0 2px;
	line-height: 16px;
	margin: 0 4px;
`;
const VerseOverlay = styled.div`
	width: 100%;
	color: rgb(14, 173, 58);
	position: absolute;
	z-index: 5000;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	// align-items: flex-end;
	height: 100%;
`;
const FormatBtnItalic = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	font-family: serif;
`;
const FormatBtnClear = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	font-family: serif;
`;
const FormatBtnDelete = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	font-family: serif;
`;
const FormatBtnUnderline = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	text-decoration: underline;
	outline: 0;
	font-family: serif;
`;
const FormatBtnDobUnderline = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	text-decoration: underline;
	outline: 0;
	font-family: serif;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
`;
const FormatBtnColor = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	position: relative;
`;
const TextColorWrapper = styled.ul`
	position: absolute;
	list-style: none;
	padding: 4px;
	margin: 0;
	width: 96px;
	top: 23px;
	z-index: 99;
	background-color: #444444;
	text-align: left;
	display: none;
`;
const TextColorHolder = styled.li`
	display: inline-block;
	width: 15px;
	height: 15px;
	margin: 2px;
`;
const ChapterImageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	cursor: pointer;
`;
const ChapterImage = styled.img`
	width: 100%;
`;
const ChapterImageModal = styled.img`
	width: 80%;
`;
const ShareLink = styled.p`
	overflow-wrap: break-word;
	word-wrap: break-word;
	&:hover {
		cursor: pointer;
	}
`;
const ShareLinkTitle = styled.h3`
	color: #324599;
`;
const MapLink = styled.div`
	color: #b2b7be;
	&:hover {
		cursor: pointer;
		color: #1a56eb;
	}
`;
const getListStyle = isDraggingOver => ({
	background: isDraggingOver && 'lightblue',
	padding: '5px 10px 5px 5px',
	zIndex: 1000,
});

const Verses = props => {
	const [chapterNoteText, setChapterNoteText] = useState('');
	const [chapterHeading, setChapterHeading] = useState('');
	const [lastChapterNoteText, setLastChapterNoteText] = useState('');
	const [show, setShow] = useState(false);
	const [width, setWidth] = useState(400);
	const [volumeObj, setVolumeObj] = useState(null);
	const [bookObj, setBookObj] = useState(null);
	const [chapterNumber, setChapterNumber] = useState(null);
	const [chapterId, setChapterId] = useState(null);
	const [chapterCount, setChapterCount] = useState(null);
	const [volumesBlock, setVolumesBlock] = useState(false);
	const [booksBlock, setBooksBlock] = useState(false);
	const [chaptersBlock, setChaptersBlock] = useState(false);
	const [versesBlock, setVersesBlock] = useState(true);
	const [zIndex, setZIndex] = useState(1);
	const [toggleLdsFootnotes, setToggleLdsFootnotes] = useState(false);
	const [loading, setLoading] = useState(true);
	const [verses, setVerses] = useState([]);
	const [volumeId, setVolumeId] = useState(null);
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [currentPane, setCurrentPane] = useState(null);
	const [verseNoteUpdating, setVerseNoteUpdating] = useState(false);
	const [bulkMove, setBulkMove] = useState(false);
	const [selectAllVerses, setSelectAllVerses] = useState(false);
	const [resources, setResources] = useState(false);
	const [nextPrevChapters, setNextPrevChapters] = useState([]);
	const [chapterImageUrl, setChapterImageUrl] = useState('');
	const [affiliateCode, setAffiliateCode] = useState('');
	const [mapLink, setMapLink] = useState('');
	const [seenList, setSeenList] = useState([]);

	const { updateVerseNote, saveUserFootnote, deleteUserMarkup } = useContext(CollectionsContext);
	const { isProUser, authState, hasProAccount } = useContext(AuthContext);
	const { bulkMoveState, addVerse, removeVerse, bulkMoveDispatch } = useContext(BulkMoveContext);
	const {
		selectedTextState,
		setSelectedTextState,
		selectedVerseId,
		setSelectedVerseId,
		optionVerseId,
		setOptionVerseId,
		setOptionVerseProps,
		isOpenOptions,
		setIsOpenOptions,
		setOptionXPosition,
		optionYPosition,
		setOptionYPosition,
		setOptionOpacity,
		setOptionPaneUniqueId,
		selectedFullLi,
		setSelectedFullLi,
	} = useContext(VerseOptionsContext);
	//const unnecessaryTags = ['<span style></span>', '<strong></strong>', '<s></s>', '<em></em>', '<u></u>', '<em></em>', '<div>', '</div>'];

	const handleScroll = e => {
		setOptionOpacity('0.2');
		setOptionXPosition('15px');
		setOptionYPosition('15px');
	};
	const activeVerseEl = useCallback(node => {
		if (node !== null) {
			node.firstChild.firstChild.classList.add('linked-verse');
			node.scrollIntoView(false);
		}
	}, []);
	const textFormatter = useRef(0);
	const showTextColors = useRef(0);
	const showTextBgColors = useRef(0);
	const verseScrollRef = useRef(0);
	/*useEffect(()=>{

	},[])
	*/

	// try {
	// 	textFormatter.current.style.display = 'none';
	// } catch { }
	const replaceAll = (str, find, replace) => {
		return str.replace(new RegExp(find, 'g'), replace);
	};
	const makeClear = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwClear();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		//return;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};

		//newTextVar=replaceAll(newTextVar,"<div>","");
		//newTextVar=replaceAll(newTextVar,"</div>","");
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');

		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const deleteMarkup = async () => {
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		const verse = await deleteUserMarkup({
			userId: userId,
			verseId: verseId,
		});
		versesDispatch({ type: 'DELETE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: null, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: verse.text, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const makeBold = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		// if(!goodToProceedToggle()){
		//   alert("Invalid selection");
		//   return;
		// }

		bbwBold();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;

		//return;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};

		//newTextVar=replaceAll(newTextVar,"<div>","");
		//newTextVar=replaceAll(newTextVar,"</div>","");
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');

		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const makeItalic = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwItalic();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const makeUnderline = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwUnderline();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const makeDobUnderline = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwStrike();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const findMeaning = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;
		window.open('https://webstersdictionary1828.com/Dictionary/' + finalStr, '_blank');
	};
	const findLexicon = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.blueletterbible.org/search/search.cfm?Criteria=' + finalStr + '&t=KJV&lexcSt=2#s=s_lexiconc', '_blank');
	};
	const findThesaurus = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.powerthesaurus.org/' + finalStr + '/synonyms', '_blank');
	};
	const findEtymology = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.etymonline.com/search?q=' + finalStr, '_blank');
	};

	const adTextColors = color => {
		// alert(color)
		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');

		span.append(selectedText);

		var spansList = span.querySelectorAll('span');
		for (var i = 0; i < spansList.length; i++) {
			spansList[i].style.removeProperty('color');
		}

		span.setAttribute('style', 'color:' + color);

		selection.insertNode(span);
		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const adTextBgColors = color => {
		// alert(color)
		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.append(selectedText);

		var spansList = span.querySelectorAll('span');
		for (var i = 0; i < spansList.length; i++) {
			spansList[i].style.removeProperty('background-color');
		}

		span.setAttribute('style', 'background-color:' + color);

		selection.insertNode(span);
		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: newTextVar, verseId } });
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const showColors = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		showTextBgColors.current.style.display = 'none';
		if (showTextColors.current.style.display == 'block') {
			showTextColors.current.style.display = 'none';
		} else {
			showTextColors.current.style.display = 'block';
		}
	};
	const showBgColors = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		showTextColors.current.style.display = 'none';
		if (showTextBgColors.current.style.display == 'block') {
			showTextBgColors.current.style.display = 'none';
		} else {
			showTextBgColors.current.style.display = 'block';
		}
	};
	const chapterHeadingEl = useCallback(
		node => {
			if (node !== null) {
				node.scrollIntoView();
			}
		},
		[chapterId]
	);
	const { dispatch, handleOpenVerses, state, handleOpenVolume, handleOpenBook, handleUpdateVersesPane } = useContext(NavContext);

	const {
		versesState,
		getVerses,
		handleSetVolumesBooksNChapters,
		handleSetBooks,
		handleSetChapters,
		updateUserChapterNote,
		handleChooseChapterByNumber,
		versesDispatch,
		getVersesNonUser,
	} = useContext(VersesContext);
	useEffect(() => {
		if (authState.user.id) {
			getVerses({ uniqueKey: props.uniqueKey, bookId: props.bookId, chapterId: props.chapterId, userId: props.user.id });
			affiliateCheck();
		} else {
			getVersesNonUser({ uniqueKey: props.uniqueKey, bookId: props.bookId, chapterId: props.chapterId });
			if (!authState.user.id) {
				dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `authPane_${unique()}`, type: 'authPane' } });
				dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `googleReviews_${unique()}`, type: 'googleReviews' } });
			}
		}
		let currentPaneCopy = state.find(pane => pane.key === props.uniqueKey);
		setCurrentPane(currentPaneCopy);
		setIsInitialRender(false);
		// checkForResources()
	}, []);
	useEffect(() => {
		// const socket = io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] });
		const socket = props.socket;
		if (!authState.user.id) {
			if (
				versesState[props.uniqueKey] &&
				versesState[props.uniqueKey].verses &&
				versesState[props.uniqueKey].verses !== 'No chapter found with that book and order'
			) {
				if (
					volumeObj === null &&
					versesState[props.uniqueKey] &&
					versesState[props.uniqueKey].verses &&
					versesState[props.uniqueKey].verses.length
				) {
					setVolumeObj(versesState[props.uniqueKey].verses[0].chapter.book.volume);
				}
				if (
					bookObj === null &&
					versesState[props.uniqueKey] &&
					versesState[props.uniqueKey].verses &&
					versesState[props.uniqueKey].verses.length
				) {
					setBookObj(versesState[props.uniqueKey].verses[0].chapter.book);
				}

				if (versesState[props.uniqueKey].verses[0].chapter.book.volume.id === 17) {
					const sortedVerses = versesState[props.uniqueKey].verses.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
					setVerses(sortedVerses);
				} else setVerses(versesState[props.uniqueKey].verses);

				if (versesState[props.uniqueKey] && versesState[props.uniqueKey].verses && versesState[props.uniqueKey].verses.length) {
					setLoading(false);
					setChapterNumber(versesState[props.uniqueKey].verses[0].chapter.order);
					setChapterCount(parseInt(versesState[props.uniqueKey].verses[0].chapter.chapterCount));
					setChapterId(versesState[props.uniqueKey].verses[0].chapter.id);
					setChapterHeading(versesState[props.uniqueKey].verses[0].chapter_heading);
					versesState[props.uniqueKey].verses[0].chapter.chapterImageUrl &&
						setChapterImageUrl(versesState[props.uniqueKey].verses[0].chapter.chapterImageUrl);
				}
			}
		}
		if (
			authState &&
			authState.user &&
			authState.user.id &&
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses !== 'No chapter found with that book and order'
		) {
			if (
				volumeObj === null &&
				versesState[props.uniqueKey] &&
				versesState[props.uniqueKey].verses &&
				versesState[props.uniqueKey].verses.length
			) {
				setVolumeObj(versesState[props.uniqueKey].verses[0].chapter.book.volume);
			}
			if (bookObj === null && versesState[props.uniqueKey] && versesState[props.uniqueKey].verses && versesState[props.uniqueKey].verses.length) {
				setBookObj(versesState[props.uniqueKey].verses[0].chapter.book);
			}

			if (versesState[props.uniqueKey].verses[0].chapter.book.volume.id === 17) {
				const sortedVerses = versesState[props.uniqueKey].verses.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
				setVerses(sortedVerses);
			} else setVerses(versesState[props.uniqueKey].verses);

			if (versesState[props.uniqueKey] && versesState[props.uniqueKey].verses && versesState[props.uniqueKey].verses.length) {
				setLoading(false);
				setChapterNumber(versesState[props.uniqueKey].verses[0].chapter.order);
				setChapterCount(parseInt(versesState[props.uniqueKey].verses[0].chapter.chapterCount));
				setChapterId(versesState[props.uniqueKey].verses[0].chapter.id);
				setChapterHeading(versesState[props.uniqueKey].verses[0].chapter_heading);
				versesState[props.uniqueKey].verses[0].chapter.chapterImageUrl &&
					setChapterImageUrl(versesState[props.uniqueKey].verses[0].chapter.chapterImageUrl);
			}

			socket.on('get_updated_verse_' + authState.user.id, ({ verseId }) => {
				if (versesState[props.uniqueKey].verses.find(v => v.id === verseId)) {
					getVerses({ uniqueKey: props.uniqueKey, bookId: props.bookId, chapterId, userId: props.user.id });
					// getVerses({ uniqueKey: props.uniqueKey, bookId: props.bookId, chapterId: props.chapterId, userId: props.user.id });
				}
			});

			socket.on('get_updated_chapter_heading_' + authState.user.id, ({ chapterId, newNoteText }) => {
				if (chapterId === versesState[props.uniqueKey].verses[0].chapter_id) {
					setChapterNoteText(newNoteText);
				}
			});
			checkForResources();
			checkForMaps();
		}

		if (
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses.length &&
			versesState[props.uniqueKey].verses[0].userChapterNote &&
			versesState[props.uniqueKey].verses[0].chapter.id === versesState[props.uniqueKey].verses[0].userChapterNote.chapter_id
		) {
			setChapterNoteText(versesState[props.uniqueKey].verses[0].userChapterNote.text);
		} else setChapterNoteText('');
		if (
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses.length &&
			versesState[props.uniqueKey].verses[0].abbreviation !== null
		) {
			handleFetchNextPrev();
		}
		return () => {
			socket.off('get_updated_verse_' + authState.user.id);
			socket.off('get_updated_chapter_heading_' + authState.user.id);
		};
	}, [versesState[props.uniqueKey], authState, chapterId]);
	useEffect(() => {
		// Callback when you click on extend pane icon

		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;
		var nwidth = oldwidth;
		nwidth = nwidth - oldwidth;
		var toscrollright = nwidth - rightposition;
		if (show && !isInitialRender) {
			if (remaingLeft > nwidth && toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		} else if (!show && !isInitialRender) {
			// panelsBlock.scroll({
			// 	left: panelsBlock.scrollLeft - 250,
			// 	behavior: 'smooth',
			// });
		}
	}, [show]);
	useEffect(() => {
		if (authState.user.id) {
			getVerses({ uniqueKey: props.uniqueKey, bookId: props.bookId, chapterId: props.chapterId, userId: props.user.id });
			affiliateCheck();
		}
	}, [authState.user.id]);
	const AUTOSAVE_INTERVAL = 100;
	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		if (lastChapterNoteText != chapterNoteText) {
	// 			updateUserChapterNote({ chapterId: chapterId, userId: props.user.id, chapterNoteText: chapterNoteText });
	// 			setLastChapterNoteText(chapterNoteText);
	// 		}
	// 	}, AUTOSAVE_INTERVAL);
	// 	return () => clearTimeout(timer);
	// }, [chapterNoteText]);

	const handleExtendPane = bool => {
		if (bool === false) {
			setWidth(600);
			setShow(true);
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
			setTimeout(() => {
				droppablewrapper.style.width = draggablewrapper.offsetWidth + 200 + 'px';
			}, 300);
		} else {
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
			setTimeout(() => {
				droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
			}, 300);
			setWidth(400);
			setShow(false);
		}
	};

	const handleOnPrint = () => {
		// let stringHtml = '',
		// 	doc = new jsPDF();

		// const hyphenFix = new RegExp(/—/g);
		// const apostropheFix = new RegExp(/’/g);

		const mappedVerses = verses.map((verse, i) => {
			// return 'blank';
			return `<p'> ${verse.userMarkup ? verse.userMarkup.verse_text_with_markup : `${verse.text}`}</p>
			<p style='margin-left: 10px;'>
			${
				verse.verseNote && verse.verseNote.verse_note
					? `<span style="font-weight: bold">Verse Notes: </span> ${verse.verseNote.verse_note.split(`\n`).join('<br />')}`
					: ''
			}</p>`;
		});
		const chapterName = verses[0].chapter_name;
		{
			let child = window.open('about:blank', `Chapter ${verses[0].chapter.order}`);
			child.document.write(`<html>
			  <head><title>Chapter Report</title></head>
			  <body>
			  <h1>${volumeObj.title}</h1>
			  <h2>${bookObj.title}</h2>
			  <h3>${chapterName ? chapterName : `Chapter ${verses[0].chapter.order}`}</h3>
			  <div style='margin-left: 20px;'>${mappedVerses.join('')}</div>
			  </body>
			 </html>`);
			child.document.close();
		}
		// verses.forEach(verse => {
		// 	stringHtml += `<p> ${verse.userMarkup ? verse.userMarkup.verse_text_with_markup : `${verse.text}`}</p>
		//   <p>${
		// 		verse.verseNote && verse.verseNote.verse_note ? `<span style="font-weight: bold">Verse Notes: </span> ${verse.verseNote.verse_note}` : ''
		// 	}</p>`;
		// 	stringHtml = stringHtml.replace(hyphenFix, '-').replace(apostropheFix, "'");
		// });
		// 	const dashRegex = new RegExp(/u>/g);
		// 	doc.setFontSize(22);
		// 	doc.text(`Chapter ${chapterNumber}`, 15, 15);
		// 	stringHtml = stringHtml.replace(dashRegex, 'strong>');
		// 	doc.fromHTML(stringHtml, 15, 20, { width: 180 });

		// 	window.open(doc.output('bloburl'), '_blank');
	};

	const checkForResources = async () => {
		const chapterId = versesState[props.uniqueKey].verses[0].chapter.id;

		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/checkForResources/${chapterId}`)
			.then(res => res.json())
			.then(result => {
				setResources(result);
			});
	};
	const checkForMaps = async () => {
		const chapter = versesState[props.uniqueKey].verses[0].chapter;

		await fetch(`${process.env.REACT_APP_BASE_URL}/verses/checkForMaps/${chapter.book.volume.title}/${chapter.book.title}/${chapter.order}`)
			.then(res => res.json())
			.then(result => {
				result !== 'No Map' && result.link ? result.link && setMapLink(result.link) : setMapLink('');
			});
	};
	const handleChooseVolume = (volumeId, volumeObj) => {
		setVolumeId(volumeId);
		setVolumeObj(volumeObj);
		handleSetBooks({ volumeId, uniqueKey: props.uniqueKey });
		setVolumesBlock(false);
		setBooksBlock(true);
		setChaptersBlock(false);
		setVersesBlock(false);
	};

	const handleChooseBook = (bookId, bookObj) => {
		setBookObj(bookObj);
		handleSetChapters({ bookId, uniqueKey: props.uniqueKey });
		setVolumesBlock(false);
		setBooksBlock(false);
		setChaptersBlock(true);
		setVersesBlock(false);
	};

	const handleShowVolumes = () => {
		setVolumesBlock(true);
		setBooksBlock(false);
		setChaptersBlock(false);
		setVersesBlock(false);
	};

	const handleShowBooks = () => {
		setVolumesBlock(false);
		setBooksBlock(true);
		setChaptersBlock(false);
		setVersesBlock(false);
	};

	const handleShowChapters = () => {
		setVolumesBlock(false);
		setBooksBlock(false);
		setChaptersBlock(true);
		setVersesBlock(false);
	};

	const handleShowVerses = chapterId => {
		setVolumesBlock(false);
		setBooksBlock(false);
		setChaptersBlock(false);
		setVersesBlock(true);
		setChapterId(chapterId);
	};

	/** Adjusts verse text if there is a known incorrect format (currently there's only one) */
	function properFormat(value) {
		let newValue = { ...value };

		if (value.title === 'Doctrine and Covenants') {
			if (value.order === 77 && value.text.includes('?A.')) {
				// Keep below spacing as is or else the display will get screwed up
				newValue.text = value.text.replace('?A.', `?\n    ${value.number > 9 ? '  ' : ''}A.`);
			}
			if (value.order === 84 && [99, 100, 101, 102].includes(value.number)) {
				let verseRegex = /strength;|bowels;|her;|her God;|might,|mercy,|peace,|people;|Zion;|Israel,|grace,|the faith|longer\.|one\.|above\./gi;
				newValue.text = value.text.replace(verseRegex, match => {
					return `${match}\n      ${value.number > 99 ? '  ' : ''}`;
				});
			}
		}
		return newValue;
	}

	const unique = () => {
		return new Date().getTime().toString(36);
	};

	/** Return the chapter number or the official declaration number */
	const retrieveHeadingNumber = () => {
		if (
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses.length &&
			versesState[props.uniqueKey].verses[0].abbreviation !== null &&
			versesState[props.uniqueKey].verses[0].chapter_name !== null
		) {
			if (parseInt(versesState[props.uniqueKey].verses[0].chapter_name) > 0)
				return `Chapter ${versesState[props.uniqueKey].verses[0].chapter_name} `;
			return ` ${versesState[props.uniqueKey].verses[0].chapter_name}`;
		}
		if (bookObj.title.includes('Doctrine and Covenants')) {
			if (chapterNumber === 139) return 'Official Declaration 1';
			if (chapterNumber === 140) return 'Official Declaration 2';
			return `Section ${chapterNumber}:`;
		}
		return `Chapter ${chapterNumber}:`;
	};
	const handleFetchNextPrev = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/verses/getNextPrevChapters/${versesState[props.uniqueKey].verses[0].chapter.id}`)
			.then(response => response.json())
			.then(chapters => setNextPrevChapters(chapters));
	};
	const formatChapter = (title, chapterNumber) => {
		if (
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses.length &&
			versesState[props.uniqueKey].verses[0].abbreviation !== null &&
			versesState[props.uniqueKey].verses[0].chapter_name !== null
		) {
			if (parseInt(versesState[props.uniqueKey].verses[0].chapter_name) > 0 && versesState[props.uniqueKey].verses[0].chapter_name.length < 6)
				return `Ch ${versesState[props.uniqueKey].verses[0].abbreviation} `;
			return `${versesState[props.uniqueKey].verses[0].abbreviation}`;
		}

		if (chapterNumber !== null && title.includes('Doctrine and Covenants') && chapterNumber === 139) {
			return `OD 1`;
		}
		if (chapterNumber !== null && title.includes('Doctrine and Covenants') && chapterNumber === 140) {
			return `OD 2`;
		}
		return `${title.includes('Doctrine and Covenants') ? 'Sec.' : 'Ch.'} ${chapterNumber}`;
	};

	const prevChapterFormat = (chapterNumber, bookObj) => {
		if (
			chapterNumber === 1 &&
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses[0].chapter.previousBook.title &&
			versesState[props.uniqueKey].verses[0].chapter.previousBook.volume_id === versesState[props.uniqueKey].verses[0].volume_id
		) {
			return versesState[props.uniqueKey].verses[0].chapter.previousBook.chapter_name ? (
				<Tooltip content={`${versesState[props.uniqueKey].verses[0].chapter.previousBook.title}`} direction='up' forceDirection={true}>
					{versesState[props.uniqueKey].verses[0].chapter.previousBook.chapterAbbreviation.trim() ===
					versesState[props.uniqueKey].verses[0].chapter.previousBook.abbreviation.trim()
						? versesState[props.uniqueKey].verses[0].chapter.previousBook.chapterAbbreviation
						: `${versesState[props.uniqueKey].verses[0].chapter.previousBook.abbreviation} ${
								versesState[props.uniqueKey].verses[0].chapter.previousBook.chapterAbbreviation
						  }`}
				</Tooltip>
			) : (
				<Tooltip content={`${versesState[props.uniqueKey].verses[0].chapter.previousBook.title}`} direction='up' forceDirection={true}>
					{`${versesState[props.uniqueKey].verses[0].chapter.previousBook.abbreviation} Ch. ${
						versesState[props.uniqueKey].verses[0].chapter.previousBook.chapterOrder
					} `}
				</Tooltip>
			);
		}
		if (bookObj.title.includes('Doctrine and Covenants') && chapterNumber === 140) {
			return `Prev OD 1 `;
		}
		if (bookObj.title.includes('Doctrine and Covenants')) {
			return chapterNumber - 1 !== 0 ? `Prev Sec. ${chapterNumber - 1} ` : null;
		}
		if (nextPrevChapters.length !== 0) {
			let prev =
				versesState[props.uniqueKey] &&
				nextPrevChapters.filter(
					e => e.id === versesState[props.uniqueKey].verses[0].chapter_id - 1 && e.book_id === versesState[props.uniqueKey].verses[0].book_id
				);

			if (prev && prev.length > 0) {
				if (parseInt(prev[0].chapter_name) > 0 && prev[0].chapter_name.length < 6) return `Prev Ch. ${prev[0].chapter_name}`;
				return `Prev ${prev[0].abbreviation} `;
			}
		}
		if (chapterNumber === 0) return;
		return chapterNumber - 1 !== 0 ? `Prev Ch. ${chapterNumber - 1} ` : null;
	};
	const nextChapterFormat = (chapterNumber, bookObj) => {
		if (
			chapterNumber === parseInt(chapterCount) &&
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses[0].chapter.nextBook.title &&
			versesState[props.uniqueKey].verses[0].chapter.nextBook.volume_id === versesState[props.uniqueKey].verses[0].volume_id
		) {
			return versesState[props.uniqueKey].verses[0].chapter.nextBook.chapter_name ? (
				<Tooltip content={`${versesState[props.uniqueKey].verses[0].chapter.nextBook.title}`} direction='up' forceDirection={true}>
					{versesState[props.uniqueKey].verses[0].chapter.nextBook.chapterAbbreviation.trim() ===
					versesState[props.uniqueKey].verses[0].chapter.nextBook.abbreviation.trim()
						? versesState[props.uniqueKey].verses[0].chapter.nextBook.chapterAbbreviation
						: `${versesState[props.uniqueKey].verses[0].chapter.nextBook.abbreviation} ${
								versesState[props.uniqueKey].verses[0].chapter.nextBook.chapterAbbreviation
						  }`}
				</Tooltip>
			) : (
				<Tooltip content={`${versesState[props.uniqueKey].verses[0].chapter.nextBook.title}`} direction='up' forceDirection={true}>
					{`${versesState[props.uniqueKey].verses[0].chapter.nextBook.abbreviation} Ch. ${
						versesState[props.uniqueKey].verses[0].chapter.nextBook.chapterOrder
					} `}
				</Tooltip>
			);
		}
		if (bookObj.title.includes('Doctrine and Covenants') && chapterNumber === 138) {
			return 'Next OD 1';
		}
		if (bookObj.title.includes('Doctrine and Covenants') && chapterNumber === 139) {
			return 'Next OD 2';
		}
		if (bookObj.title.includes('Doctrine and Covenants') && chapterNumber < parseInt(chapterCount)) {
			return `Next Sec. ${chapterNumber + 1} `;
		}
		// if (nextPrevChapters.length !== 0) {
		// 	return `Next ${ nextPrevChapters[1].abbreviation } `;
		// }
		if (nextPrevChapters.length !== 0) {
			let next =
				versesState[props.uniqueKey] &&
				nextPrevChapters.filter(
					e => e.id === versesState[props.uniqueKey].verses[0].chapter_id + 1 && e.book_id === versesState[props.uniqueKey].verses[0].book_id
				);
			if (next && next.length > 0 && next[0].chapter_name) {
				if (parseInt(next[0].chapter_name) > 0 && next[0].chapter_name.length < 6) return `Next Ch. ${next[0].abbreviation} `;
				return `Next ${next[0].abbreviation} `;
			}
			if (next && next.length > 0) return `Next Ch. ${chapterNumber + 1} `;
		}
		return chapterNumber < parseInt(chapterCount) ? ` Next Ch. ${chapterNumber + 1} ` : null;
	};
	const linkLocation = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		panelsBlock.scroll({
			left: panelsBlock.scrollLeft + 500,
			behavior: 'smooth',
		});

		if (bookObj.title.includes('2 Nephi')) {
			if (chapterHeading.includes('Isaiah sees the latter-day temple, gathering of Israel')) {
				handleOpenVerses({ chapterId: 413, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 3')) {
				handleOpenVerses({ chapterId: 414, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 4')) {
				handleOpenVerses({ chapterId: 415, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The Lord’s vineyard (Israel) will become desolate, and His people will be scattered')) {
				handleOpenVerses({ chapterId: 416, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 6')) {
				handleOpenVerses({ chapterId: 417, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 7')) {
				handleOpenVerses({ chapterId: 418, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 8')) {
				handleOpenVerses({ chapterId: 419, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 9')) {
				handleOpenVerses({ chapterId: 420, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 10')) {
				handleOpenVerses({ chapterId: 421, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 11')) {
				handleOpenVerses({ chapterId: 422, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 12')) {
				handleOpenVerses({ chapterId: 423, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 13')) {
				handleOpenVerses({ chapterId: 424, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Isaiah 14')) {
				handleOpenVerses({ chapterId: 425, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('He will not be confounded')) {
				handleOpenVerses({ chapterId: 461, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The redeemed will come to Zion amid great jo')) {
				handleOpenVerses({ chapterId: 462, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Darkness and apostasy will cover the earth in the last days')) {
				handleOpenVerses({ chapterId: 440, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Isaiah')) {
			if (chapterHeading.includes('The proud and wicked will be brought low at the Second Coming')) {
				handleOpenVerses({ chapterId: 1223, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The daughters of Zion are cursed and tormented for their worldliness')) {
				handleOpenVerses({ chapterId: 1224, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Zion and her daughters will be redeemed and cleansed in the millennial day')) {
				handleOpenVerses({ chapterId: 1225, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The Lord will lift an ensign and gather Israel')) {
				handleOpenVerses({ chapterId: 1226, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('A remnant will return')) {
				handleOpenVerses({ chapterId: 1227, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Ephraim and Syria wage war against Judah—Christ will be born of a virgin')) {
				handleOpenVerses({ chapterId: 1228, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Turn to the law and to the testimony for guidance')) {
				handleOpenVerses({ chapterId: 1229, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('reign on David’s throne')) {
				handleOpenVerses({ chapterId: 1230, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Few people will be left after the Lord comes again—The remnant of Jacob will return in that day')) {
				handleOpenVerses({ chapterId: 1231, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The Lord will raise an ensign and gather Israel')) {
				handleOpenVerses({ chapterId: 1232, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('He will dwell among them')) {
				handleOpenVerses({ chapterId: 1233, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('It will be a day of wrath and vengeance—Babylon (the world) will fall forever')) {
				handleOpenVerses({ chapterId: 1234, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Israel will triumph over Babylon (the world)')) {
				handleOpenVerses({ chapterId: 1235, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('He will not be confounded')) {
				handleOpenVerses({ chapterId: 1218, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The redeemed will come to Zion amid great joy')) {
				handleOpenVerses({ chapterId: 1219, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Israel has been chosen in the furnace of affliction and is to depart from Babylon')) {
				handleOpenVerses({ chapterId: 1209, bookId: 67, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Kings will be the nursing fathers of Israel')) {
				handleOpenVerses({ chapterId: 1210, bookId: 67, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('A people (the Nephites) will speak as a voice from the dust')) {
				handleOpenVerses({ chapterId: 1238, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The Messiah will deal prudently and be exalted')) {
				handleOpenVerses({ chapterId: 1219, bookId: 68, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Mosiah 14')) {
				handleOpenVerses({ chapterId: 1411, bookId: 79, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('3 Nephi 22')) {
				handleOpenVerses({ chapterId: 1266, bookId: 69, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('1 Nephi')) {
			if (chapterHeading.includes('The Lord reveals His purposes to Israel')) {
				handleOpenVerses({ chapterId: 459, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('The Messiah will be a light to the Gentiles and will free the prisoners')) {
				handleOpenVerses({ chapterId: 460, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('3 Nephi')) {
			if (chapterHeading.includes('Matthew 5')) {
				handleOpenVerses({ chapterId: 1120, bookId: 61, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Matthew 6')) {
				handleOpenVerses({ chapterId: 1121, bookId: 61, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Matthew 7')) {
				handleOpenVerses({ chapterId: 1122, bookId: 61, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Malachi 3')) {
				handleOpenVerses({ chapterId: 658, bookId: 28, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Malachi 4')) {
				handleOpenVerses({ chapterId: 659, bookId: 28, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Malachi')) {
			if (chapterHeading.includes('The Lord’s messenger will prepare the way for the Second Comin')) {
				handleOpenVerses({ chapterId: 1268, bookId: 69, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('At the Second Coming, the proud and wicked will be burned as stubble')) {
				handleOpenVerses({ chapterId: 1269, bookId: 69, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Genesis')) {
			if (chapterHeading.includes('Moses 2')) {
				handleOpenVerses({ chapterId: 1578, bookId: 89, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Moses 3')) {
				handleOpenVerses({ chapterId: 1579, bookId: 89, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Moses 4')) {
				handleOpenVerses({ chapterId: 1580, bookId: 89, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Moses 5')) {
				handleOpenVerses({ chapterId: 1581, bookId: 89, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Moses 6')) {
				handleOpenVerses({ chapterId: 1582, bookId: 89, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Moses')) {
			if (chapterHeading.includes('Genesis 1')) {
				handleOpenVerses({ chapterId: 343, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Genesis 2')) {
				handleOpenVerses({ chapterId: 344, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Genesis 3')) {
				handleOpenVerses({ chapterId: 345, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Genesis 4')) {
				handleOpenVerses({ chapterId: 346, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Genesis 5')) {
				handleOpenVerses({ chapterId: 347, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Abraham')) {
			if (chapterHeading.includes('Genesis 1')) {
				/* takes you to Genesis 1 */
				handleOpenVerses({ chapterId: 343, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('Genesis 2')) {
				/* takes you to Genesis 2 */
				handleOpenVerses({ chapterId: 344, bookId: 15, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Mosiah')) {
			if (chapterHeading.includes('Isaiah 53')) {
				handleOpenVerses({ chapterId: 464, bookId: 19, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else if (bookObj.title.includes('Matthew')) {
			if (chapterHeading.includes('3 Nephi 12')) {
				handleOpenVerses({ chapterId: 1256, bookId: 69, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('3 Nephi 13')) {
				handleOpenVerses({ chapterId: 1257, bookId: 69, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else if (chapterHeading.includes('3 Nephi 14')) {
				handleOpenVerses({ chapterId: 1258, bookId: 69, originPaneKey: props.uniqueKey, proUser: hasProAccount() });
			} else return null;
		} else return null;
	};

	const handleBulkMove = () => {
		if (bulkMove) {
			setBulkMove(false);
			setSelectAllVerses(false);
			bulkMoveDispatch({ type: 'HANDLE_CLEAR_VERSES' });
		} else setBulkMove(true);
	};
	const affiliateCheck = async () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
			.then(res => res.json())
			.then(userplan => {
				userplan.affliate_code ? setAffiliateCode(userplan.affliate_code) : setAffiliateCode('');
			});
	};
	const linkLocation2 = () => {
		if (bookObj.title.includes('2 Nephi')) {
			handleOpenVerses({ chapterId: 463, bookId: 19, originPaneKey: props.uniqueKey });
		} else if (bookObj.title.includes('Genesis')) {
			if (chapterHeading.includes('Abraham 4')) {
				handleOpenVerses({ chapterId: 1572, bookId: 85, originPaneKey: props.uniqueKey });
			} else if (chapterHeading.includes('Abraham 5')) {
				handleOpenVerses({ chapterId: 1573, bookId: 85, originPaneKey: props.uniqueKey });
			} else return null;
		} else if (bookObj.title.includes('Moses')) {
			if (chapterHeading.includes('Abraham 4')) {
				handleOpenVerses({ chapterId: 1572, bookId: 85, originPaneKey: props.uniqueKey });
			} else if (chapterHeading.includes('Abraham 5')) {
				handleOpenVerses({ chapterId: 1573, bookId: 85, originPaneKey: props.uniqueKey });
			} else return null;
		} else if (bookObj.title.includes('Abraham')) {
			if (chapterHeading.includes('Moses 2')) {
				handleOpenVerses({ chapterId: 1578, bookId: 89, originPaneKey: props.uniqueKey });
			} else if (chapterHeading.includes('Moses 3')) {
				handleOpenVerses({ chapterId: 1579, bookId: 89, originPaneKey: props.uniqueKey });
			} else return null;
		} else return null;
	};
	const compareChapter = () => {
		if (chapterHeading && chapterHeading.includes('—Compare')) {
			const splitCompare = chapterHeading.split('—Compare');
			if (splitCompare[1].includes('and')) {
				const splitCompareAnd = splitCompare[1].split('and');
				return (
					<>
						{splitCompare[0]}
						<LinkText onClick={() => (authState.user.id ? linkLocation() : alert('Please sign in or sign up to use Scripture Notes.'))}>
							—Compare{splitCompareAnd[0]}
						</LinkText>{' '}
						and{' '}
						<LinkText onClick={() => (authState.user.id ? linkLocation2() : alert('Please sign in or sign up to use Scripture Notes.'))}>
							{' '}
							{splitCompareAnd[1]}
						</LinkText>
					</>
				);
			}
			return (
				<>
					{/* {console.log(splitCompare, 'split compare')} */}
					{splitCompare[0]} <LinkText onClick={linkLocation}>—Compare{splitCompare[1]}</LinkText>
				</>
			);
		} else {
			return <p dangerouslySetInnerHTML={{ __html: chapterHeading }}></p>;
		}
	};
	const scrollRight = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		if (toscrollright > 0) {
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
	};
	console.log(versesState);
	const mapIndexLink = () => {
		let { order, volume } = versesState[props.uniqueKey].verses[0].chapter.book;
		const chapterOrder = versesState[props.uniqueKey].verses[0].chapter.order;
		const bookNumber = {
			'Book of Mormon': order - 2,
			'Old Testament': order,
			'New Testament': order + 39,
			'Doctrine and Covenants': 2,
			'Pearl of Great Price': order,
		};
		const volumeNumber = {
			'Book of Mormon': 2,
			'Old Testament': 1,
			'New Testament': 1,
			'Doctrine and Covenants': 3,
			'Pearl of Great Price': 4,
		};

		if (volume.title === 'Book of Mormon') {
			if (versesState[props.uniqueKey].verses[0].chapter.book.title === 'Title Page') return 'https://scriptures.byu.edu/mapscrip/#3:201';
			if (versesState[props.uniqueKey].verses[0].chapter.book.title === 'Introduction') return 'https://scriptures.byu.edu/mapscrip/#3:202';
			if (versesState[props.uniqueKey].verses[0].chapter.book.title === 'Testimony of Three Witnesses')
				return 'https://scriptures.byu.edu/mapscrip/#3:203';
			if (versesState[props.uniqueKey].verses[0].chapter.book.title === 'Testimony of Eight Witnesses')
				return 'https://scriptures.byu.edu/mapscrip/#3:204';
			if (versesState[props.uniqueKey].verses[0].chapter.book.title === 'Testimony of the Prophet Joseph Smith') return;
			if (versesState[props.uniqueKey].verses[0].chapter.book.title === 'Brief Explanation about the Book of Mormon') return;

			// bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 3).toString(), { prefix: false });
			// if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		} else if (volume.title === 'Pearl of Great Price' && order > 2) {
			// bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order).toString(), { prefix: false });
			// if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		}
		// if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.book.order === 1) return 'https://scriptures.byu.edu/#12d::c12d';
		if (volume.title === 'Doctrine and Covenants' && versesState[props.uniqueKey].verses[0].chapter.order === 139)
			return 'https://scriptures.byu.edu/mapscrip/#0:303:1';
		if (volume.title === 'Doctrine and Covenants' && versesState[props.uniqueKey].verses[0].chapter.order === 140)
			return 'https://scriptures.byu.edu/mapscrip/#0:303:2';
		// let chapterIndex = converter.decToHex(props.verse.chapter.order.toString(), { prefix: false });
		// if (chapterIndex.length < 2) chapterIndex = `0${chapterIndex}`;

		return `https://scriptures.byu.edu/mapscrip/#0:${volumeNumber[volume.title]}${
			`${bookNumber[volume.title]}`.length < 2 ? `0${bookNumber[volume.title]}` : bookNumber[volume.title]
		}:${chapterOrder}`;
	};
	const handleResources = async () => {
		const resources = await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getChapterResources/${chapterId}`)
			.then(res => res.json())
			.then(resources => {
				return resources;
			})
			.catch(err => console.log('Error getting previous chapter:', err));
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: props.uniqueKey,
			payload: {
				key: `chapterResources_${unique()} `,
				type: 'chapterResources',
				resources,
				chapter: versesState[props.uniqueKey].verses[0].chapter,
			},
		});
		scrollRight();
	};
	const copyToClipBoard = () => {
		navigator.clipboard.writeText(
			`https://app.scripturenotes.com/?pane=library&libsearch=${bookObj.title.split(' ').join('%20')}&chapter=${chapterId}&book=${bookObj.id}${
				affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''
			}`
		);
	};
	return (
		<Resizable
			className='verses-resizable'
			size={{ width: width, height: '100%' }}
			style={{ zIndex: 0 }}
			minWidth={show ? '45vw' : 400}
			maxWidth={'45vw'}
			enable={{ right: !show ? true : false }}
			handleComponent={{
				right: !show ? (
					<FontAwesomeIcon
						color='#b2b7be'
						icon={faEllipsisV}
						style={{
							top: '50%',
							right: '7px',
							position: 'absolute',
						}}
					/>
				) : null,
			}}
			onResizeStop={(e, direction, ref, d) => {
				setWidth(width + d.width);
			}}
		>
			{!loading ? (
				<ReactCSSTransitionGroup
					transitionName='verse'
					transitionEnterTimeout={500}
					transitionLeaveTimeout={500}
					transitionAppear={true}
					transitionAppearTimeout={500}
				>
					<div style={{ height: '100%' }}>
						<ClosePaneWrapper>
							<Tooltip content='Close Pane' direction='left' forceDirection={true}>
								<FontAwesomeIcon
									icon={faTimes}
									onClick={async () => {
										await props.closePane(props.id);
										versesDispatch({ type: 'DELETE_VERSES_PANE', payload: { uniqueKey: props.uniqueKey } });
									}}
								/>
								{/* {console.log('propsid', props.id)} */}
							</Tooltip>
						</ClosePaneWrapper>

						{versesBlock && (
							<PrintIconWrapper>
								<Tooltip content='Print Report' direction='left' forceDirection={true}>
									<FontAwesomeIcon icon={faPrint} onClick={handleOnPrint} />
								</Tooltip>
							</PrintIconWrapper>
						)}
						{versesBlock && (
							<PortalWithState closeOnOutsideClick closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<LinkIconWrapper>
											<Tooltip content='Share Link' direction='left' forceDirection={true}>
												<FontAwesomeIcon icon={faShareAlt} onClick={openPortal} />
											</Tooltip>
										</LinkIconWrapper>
										{portal(
											<>
												<div
													style={{
														top: 0,
														zIndex: 9999999,
														background: '#ddd',
														width: '100%',
														height: '100%',
														minHeight: '392px',
														opacity: '0.6',
														position: 'absolute',
													}}
												/>

												<DeletePopUpWrapper>
													<ShareLinkTitle>Share this link.</ShareLinkTitle>
													<ShareLink
														onClick={() => {
															copyToClipBoard();
															closePortal();
														}}
													>
														{`https://app.scripturenotes.com/?pane=library&libsearch=${bookObj.title
															.split(' ')
															.join('%20')}&chapter=${chapterId}&book=${bookObj.id}${
															affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''
														}`}
													</ShareLink>
													<DeletePopUpBtnWrapper>
														<ClosePopUpBtn
															style={{ marginRight: '10px' }}
															onClick={() => {
																copyToClipBoard();
																closePortal();
															}}
														>
															Copy
														</ClosePopUpBtn>
														{/* <DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn> */}
													</DeletePopUpBtnWrapper>
												</DeletePopUpWrapper>
											</>
										)}
									</>
								)}
							</PortalWithState>
						)}

						<GrabPaneArea
							style={{
								width: show ? 'calc(100% - 55px)' : '87%',
							}}
							{...props.dragHandleProps}
						>
							{/* <ToggleLDSFootnotes>
								Footnotes: User
								<Switch
									// onChange={() => setToggleLdsFootnotes(!toggleLdsFootnotes)}
									onChange={() => alert('Coming Soon')}
									checked={toggleLdsFootnotes}
									uncheckedIcon={false}
									checkedIcon={false}
									height={18}
									width={32}
								/>
								LDS
							</ToggleLDSFootnotes> */}
							<BreadCrumbWrapper>
								<BreadCrumbList>
									<BreadCrumbItem
										onClick={e => {
											e.stopPropagation();
											e.persist();

											if (e.altKey) {
												dispatch({
													type: 'HANDLE_ADD_PANE',
													originPaneKey: props.uniqueKey,
													payload: { key: `library_${unique()} `, type: 'library' },
												});
											} else if (e.ctrlKey || e.metaKey) {
												return window.open(`${process.env.REACT_APP_MAIN_URL}?pane = library`, '_blank');
											} else {
												handleSetVolumesBooksNChapters({ uniqueKey: props.uniqueKey, userId: props.user.id });
												handleShowVolumes();
											}
										}}
									>
										<BreadCrumbItemText>Library /</BreadCrumbItemText>
									</BreadCrumbItem>

									{(booksBlock || chaptersBlock || versesBlock) && (
										<BreadCrumbItem
											onClick={e => {
												e.stopPropagation();
												e.persist();
												if (e.altKey) {
													handleOpenVolume({ volumeTitle: volumeObj.title, originPaneKey: props.uniqueKey });
												} else if (e.ctrlKey || e.metaKey) {
													return window.open(
														`${process.env.REACT_APP_MAIN_URL}?pane = library & libsearch=${volumeObj.title} `,
														'_blank'
													);
												} else {
													handleSetBooks({ uniqueKey: props.uniqueKey, volumeId: volumeObj.id });
													handleShowBooks();
												}
											}}
										>
											<BreadCrumbItemText>
												<Tooltip content={volumeObj.title} direction='up' forceDirection={true}>
													{volumeObj.abbreviation} /
												</Tooltip>
											</BreadCrumbItemText>
										</BreadCrumbItem>
									)}

									{(chaptersBlock || versesBlock) && (
										<BreadCrumbItem
											onClick={e => {
												if (e.altKey) {
													handleOpenBook({ bookName: bookObj.title, volumeTitle: volumeObj.title, originPaneKey: props.uniqueKey });
												} else if (e.ctrlKey || e.metaKey) {
													return window.open(
														`${process.env.REACT_APP_MAIN_URL}?pane = library & libsearch=${volumeObj.title} / ${bookObj.title}`,
														'_blank'
													);
												} else {
													handleSetChapters({ uniqueKey: props.uniqueKey, bookId: bookObj.id });
													handleShowChapters();
												}
											}}
										>
											<BreadCrumbItemText>
												<Tooltip content={bookObj.title} direction='up' forceDirection={true}>
													{bookObj && bookObj !== null && bookObj.title === volumeObj.title
														? null
														: chapterCount === 1
														? `${bookObj.abbreviation} `
														: `${bookObj.abbreviation} /`}
												</Tooltip>
											</BreadCrumbItemText>
										</BreadCrumbItem>
									)}

									{versesBlock && chapterCount !== 1 && (
										<BreadCrumbItem>
											<h6 style={{ fontSize: 18 }}>{formatChapter(bookObj.title, chapterNumber)}</h6>
										</BreadCrumbItem>
									)}
								</BreadCrumbList>
							</BreadCrumbWrapper>

							<ToggleSideWrapper>
								<NextChapterWrapper>
									{versesBlock && (
										<>
											{/* PREV CHAPTER */}
											<NextChapterText
												onClick={e => {
													if (authState.user.id) {
														const prevChapterNumber =
															chapterNumber === 1 && versesState[props.uniqueKey].verses[0].chapter.previousBook.title
																? parseInt(versesState[props.uniqueKey].verses[0].chapter.previousBook.chapterOrder)
																: chapterNumber - 1;
														const bookId =
															chapterNumber === 1 && versesState[props.uniqueKey].verses[0].chapter.previousBook.title
																? versesState[props.uniqueKey].verses[0].chapter.previousBook.id
																: bookObj.id;
														const bookTitle =
															chapterNumber === 1 && versesState[props.uniqueKey].verses[0].chapter.previousBook.title
																? versesState[props.uniqueKey].verses[0].chapter.previousBook.title
																: bookObj.title;
														const prevBookObj = {
															title: versesState[props.uniqueKey].verses[0].chapter.previousBook.title,
															id: bookId,
															volume_id: versesState[props.uniqueKey].verses[0].chapter.previousBook.volume_id,
															order: versesState[props.uniqueKey].verses[0].chapter.previousBook.order,
															abbreviation: versesState[props.uniqueKey].verses[0].chapter.previousBook.abbreviation,
														};
														if (e.altKey) {
															fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getChapterByOrder/${bookId}/${prevChapterNumber}`)
																.then(res => res.json())
																.then(chapter => {
																	if (chapter && chapter['id']) {
																		handleOpenVerses({
																			chapterId: chapter.id,
																			bookId: bookId,
																			originPaneKey: props.uniqueKey,
																			proUser: isProUser(),
																		});
																	}
																})
																.catch(err => console.log('Error getting previous chapter:', err));
														} else if (e.ctrlKey || e.metaKey) {
															fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getChapterByOrder/${bookId}/${prevChapterNumber}`)
																.then(res => res.json())
																.then(chapter => {
																	if (chapter && chapter['id']) {
																		return window.open(
																			`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${bookTitle}&chapter=${chapter.id}&book=${bookId}`,
																			'_blank'
																		);
																	}
																})
																.catch(err => console.log('Error getting next chapter:', err));
														} else {
															if (chapterNumber === 1 && versesState[props.uniqueKey].verses[0].chapter.previousBook.title) {
																setBookObj(prevBookObj);
																handleSetChapters({ bookId, uniqueKey: props.uniqueKey });
																handleChooseChapterByNumber({
																	userId: props.user.id,
																	bookId: bookId,
																	chapterNumber: prevChapterNumber,
																	uniqueKey: props.uniqueKey,
																}).then(newChapterId => {
																	handleUpdateVersesPane({
																		bookId: bookId,
																		chapterId: newChapterId,
																		paneKey: props.uniqueKey,
																	});
																	setChapterId(newChapterId);
																});
															} else {
																handleChooseChapterByNumber({
																	userId: props.user.id,
																	bookId: bookId,
																	chapterNumber: prevChapterNumber,
																	uniqueKey: props.uniqueKey,
																}).then(newChapterId => {
																	handleUpdateVersesPane({
																		bookId: bookId,
																		chapterId: newChapterId,
																		paneKey: props.uniqueKey,
																	});
																	setChapterId(newChapterId);
																});
															}
														}
													} else alert('Please sign in or sign up to use Scripture Notes.');
												}}
											>
												{prevChapterFormat(chapterNumber, bookObj)} /
											</NextChapterText>
											{/* NEXT CHAPTER */}
											<NextChapterText
												onClick={e => {
													if (authState.user.id) {
														const nextChapterNumber =
															chapterNumber === parseInt(chapterCount) && versesState[props.uniqueKey].verses[0].chapter.nextBook.title
																? parseInt(versesState[props.uniqueKey].verses[0].chapter.nextBook.chapterOrder)
																: chapterNumber + 1;
														const bookId =
															chapterNumber === parseInt(chapterCount) && versesState[props.uniqueKey].verses[0].chapter.nextBook.title
																? versesState[props.uniqueKey].verses[0].chapter.nextBook.id
																: bookObj.id;
														const bookTitle =
															chapterNumber === parseInt(chapterCount) && versesState[props.uniqueKey].verses[0].chapter.nextBook.title
																? versesState[props.uniqueKey].verses[0].chapter.nextBook.title
																: bookObj.title;
														const nextBookObj = {
															title: versesState[props.uniqueKey].verses[0].chapter.nextBook.title,
															id: bookId,
															volume_id: versesState[props.uniqueKey].verses[0].chapter.nextBook.volume_id,
															order: versesState[props.uniqueKey].verses[0].chapter.nextBook.order,
															abbreviation: versesState[props.uniqueKey].verses[0].chapter.nextBook.abbreviation,
														};
														if (e.altKey) {
															fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getChapterByOrder/${bookId}/${nextChapterNumber}`)
																.then(res => res.json())
																.then(chapter => {
																	if (chapter && chapter['id']) {
																		handleOpenVerses({
																			chapterId: chapter.id,
																			bookId: bookId,
																			originPaneKey: props.uniqueKey,
																			proUser: isProUser(),
																		});
																	}
																})
																.catch(err => console.log('Error getting next chapter:', err));
														} else if (e.ctrlKey || e.metaKey) {
															fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getChapterByOrder/${bookId}/${nextChapterNumber}`)
																.then(res => res.json())
																.then(chapter => {
																	if (chapter && chapter['id']) {
																		return window.open(
																			`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${bookTitle}&chapter=${chapter.id}&book=${bookId}`,
																			'_blank'
																		);
																	}
																})
																.catch(err => console.log('Error getting next chapter:', err));
														} else {
															if (
																chapterNumber === parseInt(chapterCount) &&
																versesState[props.uniqueKey].verses[0].chapter.nextBook.title
															) {
																setBookObj(nextBookObj);
																handleSetChapters({ bookId, uniqueKey: props.uniqueKey });
																handleChooseChapterByNumber({
																	userId: props.user.id,
																	bookId: bookId,
																	chapterNumber: nextChapterNumber,
																	uniqueKey: props.uniqueKey,
																}).then(newChapterId => {
																	handleUpdateVersesPane({
																		bookId: bookId,
																		chapterId: newChapterId,
																		paneKey: props.uniqueKey,
																	});
																	setChapterId(newChapterId);
																});
															} else {
																handleChooseChapterByNumber({
																	userId: props.user.id,
																	bookId: bookObj.id,
																	chapterNumber: nextChapterNumber,
																	uniqueKey: props.uniqueKey,
																}).then(newChapterId => {
																	handleUpdateVersesPane({
																		bookId: bookObj.id,
																		chapterId: newChapterId,
																		paneKey: props.uniqueKey,
																	});
																	setChapterId(newChapterId);
																});
															}
														}
													} else alert('Please sign in or sign up to use Scripture Notes.');
												}}
											>
												{nextChapterFormat(chapterNumber, bookObj)}
											</NextChapterText>
										</>
									)}
									<LinkInfo>
										<Tooltip
											content='Alt-click: open in new pane; Ctrl-click: open in new tab; Works on all links.'
											direction='up'
											forceDirection={true}
										>
											<FontAwesomeIcon icon={faInfoCircle} />
										</Tooltip>
									</LinkInfo>
								</NextChapterWrapper>
								{versesBlock && (
									<MultiDragWrapper>
										<Tooltip content='Bulk Move' direction='up' forceDirection={true}>
											<FontAwesomeIcon icon={faClone} onClick={() => authState.user.id && handleBulkMove()} />
										</Tooltip>
										{bulkMove && (
											<Tooltip content='Select All Verses' direction='up' forceDirection={true}>
												<input
													style={{ marginLeft: '5px' }}
													type='checkbox'
													checked={selectAllVerses}
													onChange={() => setSelectAllVerses(!selectAllVerses)}
												/>
											</Tooltip>
										)}
									</MultiDragWrapper>
								)}
								{show ? (
									<OpenPaneIconWrapper>
										<FontAwesomeIcon icon={faChevronLeft} onClick={() => handleExtendPane(true)} />
									</OpenPaneIconWrapper>
								) : (
									<OpenPaneIconWrapper>
										<FontAwesomeIcon icon={faChevronRight} onClick={() => authState.user.id && handleExtendPane(false)} />
									</OpenPaneIconWrapper>
								)}
							</ToggleSideWrapper>
						</GrabPaneArea>

						<VersesDisplayWrapper>
							<Scrollbars autoHide ref={verseScrollRef} onScroll={handleScroll}>
								{versesState[props.uniqueKey] && versesState[props.uniqueKey].volumes && volumesBlock && (
									<Volumes
										id={props.id}
										volumes={versesState[props.uniqueKey].volumes}
										handleChooseVolume={handleChooseVolume}
										uniqueKey={props.uniqueKey}
										closePane={props.closePane}
									/>
								)}
								{versesState[props.uniqueKey] && versesState[props.uniqueKey].books && booksBlock && (
									<Books
										volumeId={volumeId}
										bookObj={bookObj}
										books={versesState[props.uniqueKey].books}
										handleChooseBook={handleChooseBook}
									/>
								)}
								{versesState[props.uniqueKey] && versesState[props.uniqueKey].chapters && chaptersBlock && (
									<VersesChapters
										bookId={props.bookId}
										volumeId={volumeId}
										chapters={versesState[props.uniqueKey].chapters}
										userId={props.user.id}
										handleShowChapters={handleShowChapters}
										handleShowVerses={handleShowVerses}
										uniqueKey={props.uniqueKey}
										isSignedIn={authState.user.id ? true : false}
									/>
								)}
								{versesBlock ? (
									<ContentWrapper>
										<ChapterHeadingWrapper ref={chapterHeadingEl}>
											<ChapterHeadingTextWrapper style={{ flexBasis: show ? '50%' : 'auto', paddingRight: show ? '15px' : 0 }}>
												<TitleContainer>
													<ChapterHeadingNumber>
														{retrieveHeadingNumber(chapterNumber)}
														{/* {`Chapter ${chapterNumber}:`} */}
													</ChapterHeadingNumber>
													{resources && (
														<InfoIcon>
															<Tooltip content='Resources' direction='up' forceDirection={true}>
																<ResourceButton onClick={() => handleResources()}>R</ResourceButton>
															</Tooltip>
														</InfoIcon>
													)}
													{mapLink.length > 0 && (
														<InfoIcon>
															<Tooltip content='Maps' direction='up' forceDirection={true}>
																<MapLink onClick={() => window.open(mapLink, '_blank')}>
																	<FontAwesomeIcon icon={faMap} />
																</MapLink>
																{/* <ResourceButton onClick={() => window.open(mapLink, '_blank')}>M</ResourceButton> */}
															</Tooltip>
														</InfoIcon>
													)}
												</TitleContainer>
												<ChapterHeadingText>
													{compareChapter()}
													{/* {chapterHeading} */}
												</ChapterHeadingText>
												{chapterImageUrl.length > 0 && (
													<ChapterImageContainer>
														<ChapterImage
															src={chapterImageUrl}
															alt=''
															onClick={() => {
																dispatch({
																	type: 'HANDLE_ADD_PANE',
																	originPaneKey: props.uniqueKey,
																	payload: {
																		key: `image_${unique()}`,
																		type: 'image',
																		imageUrl: chapterImageUrl,
																	},
																});
																setTimeout(() => {
																	const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
																	const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
																	panelsBlock.scroll({
																		right: 800,
																		behavior: 'smooth',
																	});
																}, 100);
															}}
														/>
													</ChapterImageContainer>
													// <PortalWithState closeOnOutsideClick closeOnEsc>
													// 	{({ openPortal, closePortal, isOpen, portal }) => (
													// 		<>

													// 			{/* <DeleteCnWrapper>
													// 			<Tooltip content='Delete Collection Note'>
													// 				<FontAwesomeIcon key='CollectionNotePortal' icon={faTrashAlt} onClick={openPortal} />
													// 			</Tooltip>
													// 		</DeleteCnWrapper> */}
													// 			{portal(
													// 				<>
													// 					<div
													// 						style={{
													// 							top: 0,
													// 							zIndex: 9999999,
													// 							background: '#ddd',
													// 							width: '100%',
													// 							height: '100%',
													// 							minHeight: '392px',
													// 							opacity: '0.6',
													// 							position: 'absolute',
													// 						}}
													// 					/>
													// 					<ImagePopUpWrapper>
													// 						<ChapterImageModal src={chapterImageUrl} alt='' onClick={openPortal} />
													// 						<FontAwesomeIcon style={{position: 'absolute', right: 15, top: 15}} icon={faTimes} color='#7e8087' className='back-btn' onClick={closePortal} />
													// 					</ImagePopUpWrapper>
													// 				</>
													// 			)}
													// 		</>
													// 	)}
													// </PortalWithState>
												)}
											</ChapterHeadingTextWrapper>
											{show && (
												<VerseNoteTextboxWrapper style={{ flexBasis: '50%' }}>
													<TextareaAutosize
														// disabled={!isProUser()}
														style={{
															overflow: 'hidden',
															overflowWrap: 'break-word',
															padding: '8px',
															border: '1px solid #cccccc',
															resize: 'none',
															width: '100%',
															lineHeight: '20px',
															backgroundColor: verseNoteUpdating ? '#efefef10' : 'white',
															// width: '365px',
															// width: '258px',
															// marginRight: '50px',
															// marginRight: '44px',
														}}
														maxRows={20}
														name={'chapterNoteText'}
														value={chapterNoteText}
														onChange={e => {
															if (!verseNoteUpdating) setVerseNoteUpdating(true);
															setChapterNoteText(e.target.value);
														}}
														onBlur={e => {
															props.socket.emit('update_chapter_heading_note', {
																newNoteText: chapterNoteText,
																chapterId: verses[0].chapter_id,
																userId: authState.user.id,
															});
															updateUserChapterNote({ chapterId: chapterId, userId: props.user.id, chapterNoteText: chapterNoteText });
															// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }).emit(
															//  'update_chapter_heading_note',
															//  { newNoteText: chapterNoteText, chapterId: verses[0].chapter_id, userId: authState.user.id }
															// );
															setVerseNoteUpdating(false);
														}}
														tabIndex='1'
													/>
												</VerseNoteTextboxWrapper>
											)}
										</ChapterHeadingWrapper>

										<div>
											<Droppable droppableId={props.uniqueKey} type='QUOTE'>
												{(dropProvided, snapshot) => (
													<div
														ref={dropProvided.innerRef}
														{...dropProvided.droppableProps}
														style={getListStyle(snapshot.isDraggingOver)}
													>
														<TextFormatter ref={textFormatter} style={{ display: 'none' }} className='TextFormatterClass'>
															<FormatBtn title='Websters 1828 Dict' onClick={findMeaning}>
																1828
															</FormatBtn>
															<FormatBtn title='Lexicon' onClick={findLexicon}>
																L
															</FormatBtn>
															<FormatBtn title='Thesaurus' onClick={findThesaurus}>
																T
															</FormatBtn>
															<FormatBtn title='Etymology' onClick={findEtymology}>
																E
															</FormatBtn>
															<FormatBtnBold id='toggleBold' data-do-bold='true' onClick={makeBold}>
																B
															</FormatBtnBold>
															<FormatBtnItalic id='toggleItalic' data-do-italic='true' onClick={makeItalic}>
																I
															</FormatBtnItalic>
															<FormatBtnUnderline id='toggleUnderline' data-do-underline='true' onClick={makeUnderline}>
																U
															</FormatBtnUnderline>
															<FormatBtnDobUnderline id='toggleStrike' data-do-strike='true' onClick={makeDobUnderline}>
																U
															</FormatBtnDobUnderline>
															<FormatBtnColor onClick={showColors}>
																A
																<TextColorWrapper ref={showTextColors}>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#00000');
																		}}
																		style={{ backgroundColor: 'rgb(0, 0, 0)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#0000ff');
																		}}
																		style={{ backgroundColor: 'rgb(0, 0, 255)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#00b000');
																		}}
																		style={{ backgroundColor: 'rgb(0, 176, 0)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#ff0000');
																		}}
																		style={{ backgroundColor: 'rgb(255, 0, 0)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#6600ff');
																		}}
																		style={{ backgroundColor: 'rgb(102, 0, 255)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#e37a07');
																		}}
																		style={{ backgroundColor: 'rgb(227, 122, 7)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextColors('#fafafa');
																		}}
																		style={{ backgroundColor: 'rgb(255, 255, 255)' }}
																	></TextColorHolder>
																</TextColorWrapper>
															</FormatBtnColor>
															<FormatBtnBgColor onClick={showBgColors}>
																A
																<TextColorWrapper ref={showTextBgColors} style={{ width: '105px' }}>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#ff7b7b');
																		}}
																		style={{ backgroundColor: 'rgb(255, 123, 123)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#ffdba6');
																		}}
																		style={{ backgroundColor: 'rgb(255, 219, 166)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#ffffae');
																		}}
																		style={{ backgroundColor: 'rgb(255, 255, 174)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#ccfeb4');
																		}}
																		style={{ backgroundColor: 'rgb(204, 254, 180)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#cce0ff');
																		}}
																		style={{ backgroundColor: 'rgb(204, 224, 255)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#e8c6ff');
																		}}
																		style={{ backgroundColor: 'rgb(232, 198, 255)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#fdc8c9');
																		}}
																		style={{ backgroundColor: 'rgb(253, 200, 201)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#d9b3b3');
																		}}
																		style={{ backgroundColor: 'rgb(217, 179, 179)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#dddddd');
																		}}
																		style={{ backgroundColor: 'rgb(221, 221, 221)' }}
																	></TextColorHolder>
																	<TextColorHolder
																		onClick={() => {
																			adTextBgColors('#000000');
																		}}
																		style={{ backgroundColor: 'rgb(0, 0, 0)' }}
																	></TextColorHolder>
																</TextColorWrapper>
															</FormatBtnBgColor>
															<FormatBtnClear onClick={makeClear} title='Clean'>
																T<span style={{ fontSize: '10px', fontStyle: 'normal', fontWeight: '600' }}>x</span>
															</FormatBtnClear>
															<PortalWithState closeOnOutsideClick closeOnEsc>
																{({ openPortal, closePortal, isOpen, portal }) => (
																	<>
																		<FormatBtnDelete onClick={openPortal}>
																			R<span style={{ fontSize: '10px', fontStyle: 'normal', fontWeight: '600' }}>x</span>
																		</FormatBtnDelete>
																		{portal(
																			<div>
																				<div
																					style={{
																						top: 0,
																						zIndex: 9999999,
																						background: '#ddd',
																						width: '100%',
																						height: '100%',
																						minHeight: '392px',
																						opacity: '0.6',
																						position: 'absolute',
																					}}
																				/>

																				<DeletePopUpWrapper>
																					<p>Are you sure? This will remove ALL markup on this verse.</p>
																					<DeletePopUpBtnWrapper>
																						<DeletePopUpBtn
																							onClick={closePortal}
																							onClickCapture={() => {
																								deleteMarkup();
																							}}
																						>
																							Delete
																						</DeletePopUpBtn>
																						<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																					</DeletePopUpBtnWrapper>
																				</DeletePopUpWrapper>
																			</div>
																		)}
																	</>
																)}
															</PortalWithState>
														</TextFormatter>
														{verses[0].chapter_id === 3956 ? (
															<table>
																<thead></thead>
																<tbody>
																	{verses.map((value, index) => {
																		return (
																			<Verse
																				key={value.id}
																				verseScrollRef={verseScrollRef}
																				textFormatter={textFormatter}
																				verse={properFormat(value)}
																				show={show}
																				user={props.user}
																				uniqueKey={props.uniqueKey}
																				toggleLdsFootnotes={toggleLdsFootnotes}
																				socket={props.socket}
																				draggableId={`${props.uniqueKey}-${value.id}`}
																				index={index}
																				bulkMoveState={bulkMoveState}
																				addVerse={addVerse}
																				removeVerse={removeVerse}
																				bulkMove={bulkMove}
																				selectAllVerses={selectAllVerses}
																				bulkMoveModal={props.bulkMoveModal}
																				userMarkup={value.userMarkup}
																				seenList={seenList}
																				setSeenList={setSeenList}
																				unique={unique}
																				scrollRight={scrollRight}
																				hideDone={props.hideDone}
																				hideMine={props.hideMine}
																				setHideDone={props.setHideDone}
																				setHideMine={props.setHideMine}
																				// isDragging={snapshot.isDragging && bulkMoveState.length > 1 ? true : false}
																			/>
																		);
																	})}
																</tbody>
															</table>
														) : (
															verses.map((value, index) => {
																let additionalProps =
																	value.number === currentPane.verseNumber && chapterId === props.initialChapterId
																		? { ref: activeVerseEl }
																		: {};

																return (
																	<div {...additionalProps} key={`${props.uniqueKey}-${value.id}`}>
																		{/* {console.log('TESTING', bookObj)} */}
																		<Draggable
																			key={`${props.uniqueKey}-${value.id}`}
																			draggableId={`${props.uniqueKey}-${value.id}`}
																			index={index}
																			placeholder={<div style={{ height: 200, width: 200, color: 'black' }}></div>}
																		>
																			{(dragProvided, snapshot) =>
																				snapshot.isDragging ? (
																					<DraggingItem ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
																						<FontAwesomeIcon
																							icon={
																								bulkMoveState.length > 1 &&
																								bulkMoveState.some(e => e.draggableId === `${props.uniqueKey}-${value.id}`)
																									? faCopy
																									: faFileAlt
																							}
																						/>
																					</DraggingItem>
																				) : (
																					<Verse
																						key={value.id}
																						verseScrollRef={verseScrollRef}
																						textFormatter={textFormatter}
																						verse={properFormat(value)}
																						show={show}
																						dragProvided={dragProvided}
																						isDragging={snapshot.isDragging}
																						user={props.user}
																						uniqueKey={props.uniqueKey}
																						toggleLdsFootnotes={toggleLdsFootnotes}
																						socket={props.socket}
																						draggableId={`${props.uniqueKey}-${value.id}`}
																						index={index}
																						bulkMoveState={bulkMoveState}
																						addVerse={addVerse}
																						removeVerse={removeVerse}
																						bulkMove={bulkMove}
																						selectAllVerses={selectAllVerses}
																						bulkMoveModal={props.bulkMoveModal}
																						userMarkup={
																							value.userMarkup ? value.userMarkup : { verse_text_with_markup: value.text }
																						}
																						seenList={seenList}
																						setSeenList={setSeenList}
																						unique={unique}
																						scrollRight={scrollRight}
																						hideDone={props.hideDone}
																						hideMine={props.hideMine}
																						setHideDone={props.setHideDone}
																						setHideMine={props.setHideMine}
																						// isDragging={snapshot.isDragging && bulkMoveState.length > 1 ? true : false}
																					/>
																				)
																			}
																		</Draggable>
																	</div>
																);
															})
														)}

														{dropProvided.placeholder}
													</div>
												)}
											</Droppable>
										</div>
									</ContentWrapper>
								) : (
									<div {...props.dragHandleProps}></div>
								)}
							</Scrollbars>
						</VersesDisplayWrapper>
					</div>
				</ReactCSSTransitionGroup>
			) : (
				<div
					{...props.dragHandleProps}
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', paddingBottom: '140px' }}
				>
					<ClosePaneWrapper>
						<Tooltip content='Close Pane' direction='up' forceDirection={true}>
							<FontAwesomeIcon
								icon={faTimes}
								onClick={async () => {
									await props.closePane(props.id);
									versesDispatch({ type: 'DELETE_VERSES_PANE', payload: { uniqueKey: props.uniqueKey } });
								}}
							/>
							{/* {console.log('propsid', props.id)} */}
						</Tooltip>
					</ClosePaneWrapper>
					<Loading />
				</div>
			)}
		</Resizable>
	);
};

export default Verses;
