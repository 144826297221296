import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import DarkLogo from '../../assets/logo_dark.png';
import { AuthContext } from '../../ContextProviders/AuthProvider';

import styled from 'styled-components';

const LogoImage = styled.img`
	position: absolute;
	top: 32px;
	left: 32px;
	width: 84px;
	height: 41px;
`;

const ForgotEmailWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100vh;
`;

const ContentWrapper = styled.div`
	padding: 0px 20px;
	width: 400px;
`;

const LoginSignupTextWrapper = styled.h1`
	display: flex;
	margin-bottom: 30px;
	font-family: SFUIDisplay-Bold;
	font-size: 32px;
	color: #dedede;
`;

const LoginSignupText = styled.h2`
	margin-left: 5px;
	padding: 0px 7px 0px 7px;
`;

const EmailRecoveryText = styled.p`
	margin-bottom: 25px;
	font-family: OpenSansSemiBold;
	font-size: 14px;
	color: #7f7f7f;
`;

const EmailRecoveryInput = styled.input`
	width: 100%;
	display: block;
	padding: 16px 10px;
	font-family: SFUIDisplay-Bold;
	font-size: 22px;
	line-height: 28px;
	border: none;
	border-radius: 0;
	color: #000;
	background-color: #ebebeb;
	margin-bottom: 20px;
`;

const EmailLabel = styled.label`
	font-size: 18px;
	font-weight: 500;
	font-family: SFUIDisplay-Bold;
	display: block;
`;

const EmailResetBtn = styled.button`
	cursor: pointer;
	font-family: SFUIDisplay-Bold;
	background-color: inherit;
	border: 2px solid #000;
	width: 120px;
	height: 50px;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
`;
const BackButton = styled.button`
	background-color: #222;
	color: #fff;
	border: none;
	padding: 8px 15px;
	border-radius: 3px;
`;
const ChangeEmail = props => {
	const { authState, isProUser, isTrialUser, hasProAccount } = useContext(AuthContext);
	const [isValidToken, setIsValidToken] = useState(null);
	const [successfulReset, setSuccessfulReset] = useState(false);
	const [oldEmail, setOldEmail] = useState(authState.user.email);
	// const [oldEmail, setOldEmail] = useState('sendtofareedi@gmail.com')
	let { token, email } = useParams();

	const logout = () => {
		localStorage.clear();
		// localStorage.setItem('prev-email', authState.user.email);
		props.history.push('/login');
	};
	useEffect(() => {
		const resetEmail = async () => {
			try {
				let changeEmailRes = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/changeEmail`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ oldEmail, email }),
				}).then(res => res.json());
				console.log('changeEmailRes: ' + changeEmailRes);

				if (changeEmailRes === 'Email successfully updated') {
					setSuccessfulReset(true);
				}
			} catch (err) {
				console.log('Error resetting Email:', err);
			}
		};
		const validateToken = async () => {
			try {
				let verifyToken = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/verifyToken/${token}/${email}`).then(res => res.json());
				console.log('verifyToken result: ' + JSON.stringify(verifyToken));
				if (verifyToken === 'ok') {
					setIsValidToken(true);
					resetEmail();
				} else setIsValidToken(false);
			} catch (err) {
				console.log('Error validating token:', err);
			}
		};

		validateToken();
		console.log('oldEmailemaillll: ' + oldEmail);
	}, []);

	if (isValidToken === null) return null;

	if (isValidToken === false)
		return (
			<div>
				<ForgotEmailWrapper>
					<LoginSignupText>Invalid Link</LoginSignupText>
				</ForgotEmailWrapper>
			</div>
		);

	return (
		<div>
			<LogoImage key='logo' src={DarkLogo} alt='Scripture Notes Icon' />
			<ForgotEmailWrapper style={{ flexDirection: 'column', justifyContent: 'center' }} key='loginFrom'>
				<ContentWrapper>
					{successfulReset ? (
						<div style={{ textAlign: 'center' }}>
							<EmailLabel>Your email has been changed.</EmailLabel>
							<BackButton onClick={() => logout()}>Back to login</BackButton>
						</div>
					) : (
						<div>
							<EmailLabel>Waiting for response.</EmailLabel>
						</div>
					)}
				</ContentWrapper>
			</ForgotEmailWrapper>
		</div>
	);
};

export default ChangeEmail;
