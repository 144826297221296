import React, { useEffect, useContext, useState, useRef } from 'react';
import { PortalWithState } from 'react-portal';
import CollectionNoteVerse from './CollectionNoteVerse';
import CollectionNoteEditor from './CollectionNoteEditor';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import jsPDF from 'jspdf';
import _, { uniq } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faPrint from '@fortawesome/fontawesome-free-solid/faPrint';
import faTrashAlt from '@fortawesome/fontawesome-free-solid/faTrashAlt';
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import { faLink, faShare } from '@fortawesome/fontawesome-free-solid/';
import { faChevronRight, faChevronLeft, faClone, faFileAlt, faCopy, faClipboardCheck, faShareAlt } from '@fortawesome/fontawesome-free-solid/';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Resizable } from 're-resizable';
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV';
import { Scrollbars } from 'react-custom-scrollbars';
import SplitPane from 'react-split-pane';
import '../../assets/css/collectionNote.css';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components';
import Loading from '../../components/Helpers/Loading.js';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
import { BulkMoveContext } from '../../ContextProviders/BulkMoveProvider';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

// import { NavContext } from '../../ContextProviders/NavProvider';
import {
	removeMarkTag,
	bbwBold,
	bbwUnderline,
	bbwItalic,
	bbwStrike,
	bbwProcessToggle,
	goodToProceedToggle,
	bbwClear,
	replaceAllMarks,
	bbwIsSelectionValid,
	unnecessaryTags,
} from '../BBWFormatter/BBWFormatter';
import {
	DeletePopUpBtn,
	DeletePopUpBtnWrapper,
	ShareModalWrapper,
	ClosePopUpBtn,
	EditorPopUpWrapper,
	SaveChangesBtn,
	DeletePopUpWrapper,
} from '../Shared/SharedStyles';

import io from 'socket.io-client';
import { AuthContext } from '../../ContextProviders/AuthProvider';

const hoverColor = '#1a56eb';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: ${hoverColor};
	}
`;

const PrintIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 30px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: #1a56eb;
	}
`;

const DeleteCnWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	right: 75px;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;

const CollectionNoteFormWrapper = styled.div`
	width: calc(100% - 35px);
`;

const AllVersesWrapper = styled.div`
	width: calc(100% - 54px);
	height: 100%;
	z-index: 3;
`;

const AllVersesHeightWrapper = styled.div`
	height: 100%;
`;

const VerseWithPrevNextButtonsWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const VerseOverlay = styled.div`
	width: 90%;
	color: rgb(14, 173, 58);
	position: absolute;
	z-index: 5000;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: flex-end;
	height: 90%;
	top: 0;
`;

const SaveCnBtn = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 12px;
	border-radius: 10px;
	cursor: pointer;
	padding: 6px 12px;
	text-align: center;
	height: 50px;
	// width: 100px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;

const CreateCnBtn = styled.button`
	padding: 12px 5px;
	line-height: 10px;
	width: 100%;
	font-size: 15px;
	max-width: none;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	font-weight: 700;
	cursor: pointer;
	&:hover {
		color: #fff;
		background-color: #324599;
	}
	&:focus {
		outline: none;
	}
`;

const ToggleSideWrapper = styled.div`
	display: block;
	position: absolute;
	top: 4px;
	cursor: pointer;
	z-index: 1;
	right: 110px;
`;

const VerseCountWrapper = styled.div`
	display: block;
	position: absolute;
	top: 7px;
	cursor: pointer;
	z-index: 1;
	right: 110px;
`;

const ToggleOpenPaneWrapper = styled.div`
	display: block;
	position: absolute;
	z-index: 1;
	font-size: 22px;
	color: #7d8085;
	cursor: pointer;
	&:hover {
		color: #1a56eb;
	}
`;

const PromotedAndUnpromotedHeader = styled.div`
	//display: flex;
	text-align: center;
	justify-content: space-between;
	color: #324599;
	//margin: 5px 0px;
	font-size: 20px;
	font-weight: 700;
`;

const MultiDragWrapper = styled.div`
	display: flex;
	position: absolute;
	right: 20%;
	cursor: pointer;
	color: #6a7583;
	font-size: 18px;
`;
const SelectionCount = styled.div`
	position: absolute;
	top: 5px;
	text-align: center;
	font-size: 0.8rem;
`;
const DraggingItem = styled.div`
	background-color: transparent;
	height: 50px;
	width 50px;
	color: #6a7583;
	font-size: 75px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-left: 150px;
	padding-bottom: 50px;
`;

const BulkMoveButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
const TextFormatter = styled.div`
	position: absolute;
	background-color: #444444;
	border-radius: 13px;
	padding: 2px 15px;
	z-index: 999;
`;
const FormatBtn = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	outline: 0;
	font-family: serif;
`;
const FormatBtnBold = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
`;
const FormatBtnBgColor = styled.button`
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
	background-color: #727272;
	padding: 0 2px;
	line-height: 16px;
	margin: 0 4px;
`;

const FormatBtnItalic = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	font-family: serif;
`;
const FormatBtnClear = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	font-family: serif;
`;
const FormatBtnUnderline = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	text-decoration: underline;
	outline: 0;
	font-family: serif;
`;
const FormatBtnDobUnderline = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	text-decoration: underline;
	outline: 0;
	font-family: serif;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
`;
const FormatBtnColor = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	position: relative;
`;
const TextColorWrapper = styled.ul`
	position: absolute;
	list-style: none;
	padding: 4px;
	margin: 0;
	width: 96px;
	top: 23px;
	z-index: 99;
	background-color: #444444;
	text-align: left;
	display: none;
`;
const TextColorHolder = styled.li`
	display: inline-block;
	width: 15px;
	height: 15px;
	margin: 2px;
`;
const LinkIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 55px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;
const ShareLink = styled.p`
	overflow-wrap: break-word;
	word-wrap: break-word;
	&:hover {
		cursor: pointer;
	}
`;
const ShareLinkTitle = styled.h3`
	color: #324599;
	width: 100%;
	text-align: center;
`;
const ShareSettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
`;
const ShareExpiresContainer = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	justify-content: flex-start;
	flex-direction: row;
`;
const EditButtonsContainer = styled.div`
	width: 100%;
	display: flex;
`;
const FormatBtnDelete = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	font-family: serif;
`;
const getListStyle = isDraggingOver => ({
	background: isDraggingOver && 'lightblue',
	padding: '5px 10px 5px 5px',
	zIndex: 1000,
	height: '95%',
});

const CollectionNote = props => {
	const [show, setShow] = useState(false);
	const [width, setWidth] = useState(430);
	// const [zIndex, setZIndex] = useState(1);
	// const [heightSecondPane, setHeightSecondPane] = useState(window.innerHeight - 500 + 'px');
	// const [defaultSize, setDefaultSize] = useState(420);
	// const [loadingAllVerses, setLoadingAllVerses] = useState(false);
	// const [allVersesLoaded, setAllVersesLoading] = useState(false);
	// const [loadedOnce, setLoadedOnce] = useState(false);
	// const [ldsFootnotes, setLdsFootnotes] = useState(false);
	// const [versesInCollections, setVersesInCollections] = useState([]);
	const [collection, setCollection] = useState({});
	const [toggleLdsFootnotes, setToggleLdsFootnotes] = useState(false);
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [verseOverlay, setShowVerseOverlay] = useState(false);
	const [detailedVerseCount, setDetailedVerseCount] = useState(50);
	const [promotedVerses, setPromotedVerses] = useState([]);
	const [promotedBulkMove, setPromotedBulkMove] = useState(false);
	const [bulkMove, setBulkMove] = useState(false);
	const [selectAllPromoted, setSelectAllPromoted] = useState(false);
	const [selectAllVerses, setSelectAllVerses] = useState(false);
	const [customMarkup, setCustomMarkup] = useState(false);
	const [shareSettings, setShareSettings] = useState('master');
	const [shareExpires, setShareExpires] = useState('2023-09-11');
	const [shareEdit, setShareEdit] = useState(false);
	const [shareCode, setShareCode] = useState('');
	const [modalView, setModalView] = useState('settings');
	const [sharedNote, setSharedNote] = useState('');
	const [affiliateCode, setAffiliateCode] = useState('');
	const [isPublic, setIsPublic] = useState(false);
	const [seenList, setSeenList] = useState([]);

	const editor = useRef(null);

	const { updateVerseNote, saveUserFootnote, saveCnSpecificMarkup, deleteCnSpecificMarkup } = useContext(CollectionsContext);
	const {
		collectionState,
		fetchUserCollectionNote,
		deleteCollectionNote,
		createCnWithVerse,
		createNewCn,
		collectionDispatch,
		toggleCustomMarkup,
		fetchSharedCollectionNote,
		fetchSharedNonUser,
		deleteSharedCollection,
		deleteUserMarkup,
	} = useContext(CollectionsContext);
	const { authState, isProUser } = useContext(AuthContext);
	const { bulkMoveState, addVerse, removeVerse, bulkMoveDispatch } = useContext(BulkMoveContext);
	const textFormatter = useRef(0);
	const showTextColors = useRef(0);
	const showTextBgColors = useRef(0);
	const verseScrollRef = useRef(0);
	// try {
	// 	textFormatter.current.style.display = 'none';
	// } catch { }
	const replaceAll = (str, find, replace) => {
		return str.replace(new RegExp(find, 'g'), replace);
	};
	const isOwner = () => {
		if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection) {
			return authState.user.id === collectionState[props.uniqueKey].collection.user_id || !collectionState[props.uniqueKey].collection.user_id;
		}
	};
	const canEdit = () => {
		if (isProUser()) {
			if (props.collectionNoteId === null) {
				return false;
			}
			if (isOwner()) {
				return false;
			}
			if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection) {
				if (collectionState[props.uniqueKey].collection.can_save) {
					console.log('collection can save', props.uniqueKey);
					return false;
				}
				return true;
			}
			return true;
		} else return true;
	};
	const makeClear = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwClear();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		//return;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};

		//newTextVar=replaceAll(newTextVar,"<div>","");
		//newTextVar=replaceAll(newTextVar,"</div>","");
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');

		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};
	const deleteMarkup = async () => {
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');

		if (!customMarkup) {
			const verse = await deleteUserMarkup({
				userId: userId,
				verseId: verseId,
			});
			collectionDispatch({ type: 'DELETE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, text: null, verseId } });
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: verse.text, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			const verse = await deleteCnSpecificMarkup({
				userId: userId,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			console.log(verse);
			collectionDispatch({ type: 'UPDATE_CN_SPECIFIC_MARKUP', payload: { uniqueKey: props.uniqueKey, text: null, verseId } });
			// props.socket.emit('update_verse_markup', { verseId: verseId, markupText: verse.text, userId: authState.user.id, paneId: props.uniqueKey });
		}
	};
	const makeBold = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		// if(!goodToProceedToggle()){
		//   alert("Invalid selection");
		//   return;
		// }
		// console.log(window.getSelection().getRangeAt(0))

		bbwBold();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		//return;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};

		//newTextVar=replaceAll(newTextVar,"<div>","");
		//newTextVar=replaceAll(newTextVar,"</div>","");
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};
	const makeItalic = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwItalic();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};
	const makeUnderline = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwUnderline();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};

	const makeDobUnderline = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwStrike();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};
	const findMeaning = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;
		window.open('https://webstersdictionary1828.com/Dictionary/' + finalStr, '_blank');
	};
	const findLexicon = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.blueletterbible.org/search/search.cfm?Criteria=' + finalStr + '&t=KJV&lexcSt=2#s=s_lexiconc', '_blank');
	};
	const findThesaurus = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.powerthesaurus.org/' + finalStr + '/synonyms', '_blank');
	};
	const findEtymology = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.etymonline.com/search?q=' + finalStr, '_blank');
	};

	const adTextColors = color => {
		// alert(color)
		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');

		span.append(selectedText);

		var spansList = span.querySelectorAll('span');
		for (var i = 0; i < spansList.length; i++) {
			spansList[i].style.removeProperty('color');
		}

		span.setAttribute('style', 'color:' + color);

		selection.insertNode(span);
		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};
	const adTextBgColors = color => {
		// alert(color)
		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.append(selectedText);

		var spansList = span.querySelectorAll('span');
		for (var i = 0; i < spansList.length; i++) {
			spansList[i].style.removeProperty('background-color');
		}

		span.setAttribute('style', 'background-color:' + color);

		selection.insertNode(span);
		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		if (!customMarkup) {
			saveUserFootnote({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
			});
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
			props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
		}
		if (customMarkup) {
			saveCnSpecificMarkup({
				userId: userId,
				delta: delta,
				text: newTextVar,
				verseId: verseId,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
			collectionDispatch({
				type: 'UPDATE_CN_SPECIFIC_MARKUP',
				payload: {
					uniqueKey: props.uniqueKey,
					text: newTextVar,
					verseId,
				},
			});
		}
	};

	const showColors = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		showTextBgColors.current.style.display = 'none';
		if (showTextColors.current.style.display == 'block') {
			showTextColors.current.style.display = 'none';
		} else {
			showTextColors.current.style.display = 'block';
		}
	};
	const showBgColors = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		showTextColors.current.style.display = 'none';
		if (showTextBgColors.current.style.display == 'block') {
			showTextBgColors.current.style.display = 'none';
		} else {
			showTextBgColors.current.style.display = 'block';
		}
	};
	useEffect(() => {
		if (authState.user.id) {
			if (props.collectionId === null && props.shareCode) {
				fetchSharedCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, shareCode: props.shareCode });
				if (!isProUser()) {
					alert(
						`You are using the free version of Scripture Notes. As such, you can't save this Collection Note you have opened. If you would like to be able to save it, or send your own, please subscribe to support ongoing development efforts of this software. To subscribe, click Account Settings in your Dashboard.`
					);
				}
			} else if (props.collectionId !== null) {
				fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
			} else if (props.collectionId === null && props.collectionObj.verses) {
				createCnWithVerse({ uniqueKey: props.uniqueKey, userId: props.user.id, verse: props.collectionObj.verses });
			} else if (props.collectionId === null) {
				createNewCn({ uniqueKey: props.uniqueKey, userId: props.user.id, text: props.text ? props.text : '' });
			}
			affiliateCheck();
		} else {
			fetchSharedNonUser({ uniqueKey: props.uniqueKey, shareCode: props.shareCode });
		}
		setIsInitialRender(false);

		// socket.on('get_updated_cn_list', (msg) => {
		// 	if (props.collectionNoteId) {
		// 		fetchUserCollectionNote({
		// 			uniqueKey: props.uniqueKey,
		// 			userId: props.user.id,
		// 			collectionId: props.collectionId,
		// 			noSave: true
		// 		});
		// 	}
		// });

		// socket.on('get_updated_verse_notes', (params) => {
		// 	if (props.collectionId) {
		// 		fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
		// 	}
		// });

		// socket.on('get_updated_verse_markup', (params) => {
		// 	if (props.collectionId) {
		// 		fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
		// 	}
		// });

		// return () => {
		// 	socket.off('get_updated_cn_list');
		// 	socket.off('get_updated_verse_notes');
		// 	socket.off('get_updated_verse_markup');
		// }
	}, []);
	useEffect(() => {
		// const socket = io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] });
		const socket = props.socket;

		if (authState && authState.user && authState.user.id) {
			socket.on('get_updated_cn_list_' + authState.user.id, ({ uniqueKey, collectionId }) => {
				if (props.uniqueKey !== uniqueKey && collectionId === props.collectionId) {
					if (props.collectionNoteId) {
						fetchUserCollectionNote({
							uniqueKey: props.uniqueKey,
							userId: props.user.id,
							collectionId: props.collectionId,
							noSave: true,
						});
					}
				}
			});

			// socket.on('get_updated_verse_notes_' + authState.user.id, params => {
			// 	console.log('get_updated_verse_notes');
			// 	if (props.collectionId) {
			// 		fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
			// 	}
			// });

			// socket.on('get_updated_verse_markup_' + authState.user.id, ({ uniqueKey }) => {
			// 	if (props.uniqueKey !== uniqueKey) {
			// 		console.log('get_updated_verse_markup');
			// 		if (props.collectionId) {
			// 			fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
			// 		}
			// 	}
			// });

			return () => {
				socket.off('get_updated_cn_list_' + authState.user.id);
				// socket.off('get_updated_verse_notes_' + authState.user.id);
				socket.off('get_updated_verse_markup_' + authState.user.id);
			};
		}
	}, [authState]);
	useEffect(() => {
		if (collectionState[props.uniqueKey]) {
			if (
				collection &&
				collection.verses &&
				!arraysEqual(
					collectionState[props.uniqueKey].collection.verses.map(({ id }) => id),
					collection.verses.map(({ id }) => id)
				)
			) {
				//verseAdded();
			}

			if (collectionState[props.uniqueKey].fromVerse) {
				let collectionCopy = { ...collection };
				collectionCopy.verses = collectionState[props.uniqueKey].collection.verses;
				setCollection(collectionCopy);
				//verseAdded();
			} else {
				setCollection(collectionState[props.uniqueKey].collection);
				collectionState[props.uniqueKey].collection.shared_note
					? setSharedNote(collectionState[props.uniqueKey].collection.shared_note)
					: setSharedNote(collectionState[props.uniqueKey].collection.text);
				collectionState[props.uniqueKey].collection.share_settings &&
					collectionState[props.uniqueKey].collection.share_settings !== 'scriptures' &&
					collectionState[props.uniqueKey].collection.can_save !== true &&
					setShareEdit(true);
				if (collectionState[props.uniqueKey].collection.share_expires !== null) {
					const exp = new Date(Date.parse(collectionState[props.uniqueKey].collection.share_expires) + 86400000);

					const expYear = exp.getFullYear();
					let expMonth = exp.getMonth() + 1;
					let expDay = exp.getDate();
					// console.log(expMonth.toString().length, expDay.toString().length)
					setShareExpires(
						`${expYear}-${expMonth.toString().length > 1 ? expMonth : `0${expMonth}`}-${expDay.toString().length > 1 ? expDay : `0${expDay}`}`
					);
				} else setShareExpires('never');
				collectionState[props.uniqueKey].collection.share_settings &&
					setShareSettings(collectionState[props.uniqueKey].collection.share_settings);
				collectionState[props.uniqueKey].collection.public && setIsPublic(collectionState[props.uniqueKey].collection.public);
			}

			let promoted = [];

			collectionState[props.uniqueKey].collection.verses.forEach((verse, verseIndex) => {
				if (verse.promoted == true) {
					verse.verseIndex = verseIndex;
					promoted.push(verse);
				}
			});

			setPromotedVerses(sortPromotedVerses(promoted));
			collectionState[props.uniqueKey].collection.custom_markup_active ||
			(collectionState[props.uniqueKey].collection.user_id && collectionState[props.uniqueKey].collection.user_id !== authState.user.id)
				? setCustomMarkup(true)
				: setCustomMarkup(false);
		}
	}, [collectionState]);
	useEffect(() => {
		// Callback when you click on extend pane icon
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;
		var nwidth = oldwidth;
		nwidth = nwidth - oldwidth;
		var toscrollright = nwidth - rightposition;
		console.log('toscrollright: ' + toscrollright);
		if (show && !isInitialRender) {
			if (remaingLeft > nwidth && toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		} else if (!show && !isInitialRender) {
			// panelsBlock.scroll({
			// 	left: panelsBlock.scrollLeft - 750,
			// 	behavior: 'smooth',
			// });
		}
	}, [show]);
	const sortPromotedVerses = (verses = []) => {
		if (!verses.length) return [];
		if (verses.some(e => e.order !== null)) {
			verses = _.sortBy(verses, [verse => verse.order], ['asc']);
			return verses;
		}
		verses = _.sortBy(
			verses,
			[verse => verse.chapter.book.volume.order, verse => verse.chapter.book.order, verse => verse.chapter.order, verse => verse.number],
			['asc', 'asc', 'asc', 'asc']
		);
		return verses;
	};
	const scrollRight = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		console.log('toscrollright: ' + toscrollright);

		if (toscrollright > 0) {
			console.log('Should be scroll:');
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
	};
	const handleCopyToClipBoard = () => {
		let stringHtml = '',
			doc = new jsPDF();

		const hyphenFix = new RegExp(/—/g);
		const apostropheFix = new RegExp(/’/g);
		const returnHTML = value => {
			return (
				<p>
					<p>
						<b>{`${value.chapter.book.title} ${value.chapter.order}:${value.number}`}</b>
					</p>
					<p>{value.userMarkup ? value.userMarkup.verse_text_with_markup : value.text}</p>
					<p>
						{value.verseNote && value.verseNote.verse_note
							? `<span style="font-weight: bold">Verse Notes: </span> ${value.verseNote.verse_note}`
							: ''}
					</p>
				</p>
			);
		};
		const verses =
			collection &&
			collection.verses.map(value => {
				return (
					<p>
						<p>
							<b>{`${value.chapter.book.title} ${value.chapter.order}:${value.number}`}</b>
						</p>
						<p>{value.userMarkup ? value.userMarkup.verse_text_with_markup : value.text}</p>
						<p>
							{value.verseNote && value.verseNote.verse_note
								? `<span style="font-weight: bold">Verse Notes: </span> ${value.verseNote.verse_note}`
								: ''}
						</p>
					</p>
				);
			});
		// doc.fromHTML(
		// 	`${stringHtml}`,
		// 	15,
		// 	18,
		// 	{ width: 180 }
		// );
		// console.log(<p>{verses}</p>);
		// richText = richText.replace(/<!--[\s\S]*?-->/ig,"")
		// navigator.clipboard.writeText(htmlbject)
		navigator.clipboard.writeText(<p>{verses}</p>);
	};
	const handleOnPrint = () => {
		const mappedVerses = collection.verses.map((verse, i) => {
			const verseFormat = verseText => {
				let verseArr = verseText.replace(/<p[^>]*>/g, '').split('.');
				verseArr[0] = `<b>${verseArr[0]}</b>`;
				return verseArr.join('.');
			};

			// console.log(verseNumber(verse.text));
			const verseText = verse.userMarkup ? verse.userMarkup.verse_text_with_markup : verse.text;
			// return 'blank';
			return `
			<p style='display: inline;'>${verse.promoted ? `<span style='margin-right: 5px;'>&#9733;</span>` : ''}<span><b>${verse.chapter.book.title} ${
				verse.chapter.order
			}:</b></span>${verseFormat(verseText)}</p>
			<p style='margin-left: 10px;'>
			${
				verse.verseNote && verse.verseNote.verse_note
					? `<span style="font-weight: bold">Verse Notes: </span> ${verse.verseNote.verse_note.split(`\n`).join('<br />')}`
					: ''
			}</p>`;
		});
		const favorites = promotedVerses.map(verse => {
			const { chapter } = verse;
			const { book } = chapter;
			return `<span>
			<b>${book.title} ${chapter.order}:${verse.number}</b>
		</span>`;
		});
		let stringHtml = '',
			doc = new jsPDF();

		const hyphenFix = new RegExp(/—/g);
		const apostropheFix = new RegExp(/’/g);
		const openQuoteFix = new RegExp(/“/g);
		const closeQuoteFix = new RegExp(/”/g);
		const elipsesFix = new RegExp(/…/g);

		collection &&
			collection.verses.forEach(value => {
				stringHtml += `${value.promoted && '<span>&starf</span>'}<p><b>${value.chapter.book.title} ${value.chapter.order}:${value.number}</b></p>
			<p> ${value.userMarkup ? value.userMarkup.verse_text_with_markup : `${value.text}`}</p>
			<p>${value.verseNote && value.verseNote.verse_note ? `<span style="font-weight: bold;">Verse Notes: </span> ${value.verseNote.verse_note}` : ''}</p>`;
				stringHtml = stringHtml.replace(hyphenFix, '-').replace(apostropheFix, "'");
			});

		let tags = collection.tags.map(tag => tag.label);
		let categories = collection.categories.map(category => category.label);
		let CNTitle = `<h1 style="font-size: 32px; font-family: arial;">${collection.title}</h1>`;

		tags = `<p><b>Tags</b> ${tags.join(', ')}</p>`;
		categories = `<p><b>Categories</b> ${categories.join(', ')}</p>`;

		{
			let child = window.open('about:blank', `Collection ${collection.title}`);
			child.document.write(`<html>
			  <head><title>${collection.title} Report</title></head>
			  <body>
			  <div style='padding: 0; margin: 0; width: 100vw; '>
			  	${`${CNTitle}${tags}${categories}${collectionState[props.uniqueKey].collection.text
					.replace(apostropheFix, "'")
					.replace(openQuoteFix, '"')
					.replace(closeQuoteFix, '"')
					.replace(elipsesFix, '...')
					.replace(hyphenFix, '-')}`}
			  </div>
			  ${
					favorites.length > 0
						? `<div><span style='font-size: 20px; font-weight: 800;'>Favorites (<span style='font-size: 15px
					;'>&#9733;</span>):</span> ${favorites.join(';')}</div></br>`
						: ''
				}
			  <div style='margin-left: 20px;'>${mappedVerses.join('')}</div>
			  </body>
			 </html>`);
			child.document.close();
		}
		// doc.fromHTML(
		// 	`${CNTitle}${tags}${categories}${
		// 		collection
		// 			? collection.text
		// 					.replace(apostropheFix, "'")
		// 					.replace(openQuoteFix, '"')
		// 					.replace(closeQuoteFix, '"')
		// 					.replace(elipsesFix, '...')
		// 					.replace(hyphenFix, '-')
		// 			: ''
		// 	}
		//   ${stringHtml}`,
		// 	15,
		// 	18,
		// 	{ width: 180 }
		// );

		// window.open(doc.output('bloburl'), '_blank');
	};

	const arraysEqual = (a, b) => {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (var i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}

		return true;
	};
	const handleToggleLdsFootnotes = () => {
		setToggleLdsFootnotes(!toggleLdsFootnotes);
	};
	const renderPromotedAndUnpromotedVerses = (versesPassedIn, headerText, promoted = false) => {
		if (versesPassedIn.length != 0) {
			return (
				<div>
					<MultiDragWrapper>
						<BulkMoveButtonsContainer>
							<Tooltip content='Bulk Copy' direction='up' forceDirection={true}>
								<FontAwesomeIcon
									icon={faClone}
									onClick={() => (headerText === 'Favorites' ? setPromotedBulkMove(!promotedBulkMove) : setBulkMove(!bulkMove))}
								/>
							</Tooltip>
							{/* <Tooltip content='Copy to clipboard' direction='up' forceDirection={true}>
								<FontAwesomeIcon
									icon={faClipboardCheck}
									onClick={() => handleCopyToClipBoard()}

								/>
							</Tooltip> */}
						</BulkMoveButtonsContainer>
						{promotedBulkMove && headerText === 'Favorites' && (
							<Tooltip content='Select All Verses' direction='up' forceDirection={true}>
								<input
									style={{ marginLeft: '5px' }}
									type='checkbox'
									checked={selectAllPromoted}
									onChange={() => setSelectAllPromoted(!selectAllPromoted)}
								/>
							</Tooltip>
						)}
						{bulkMove && headerText === 'All Verses' && (
							<Tooltip content='Select All Verses' direction='up' forceDirection={true}>
								<input
									style={{ marginLeft: '5px' }}
									type='checkbox'
									checked={selectAllVerses}
									onChange={() => setSelectAllVerses(!selectAllVerses)}
								/>
							</Tooltip>
						)}
					</MultiDragWrapper>
					<PromotedAndUnpromotedHeader>{headerText}</PromotedAndUnpromotedHeader>

					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<div style={{ backgroundColor: '#324599', height: '3px', width: '80%', marginBottom: '15px' }}></div>
					</div>

					<div>{orderVerses(versesPassedIn, promoted)}</div>
				</div>
			);
		}
	};
	const changeFavoriteOrder = async (currentIndex, offset) => {
		const promotedCopy = [...promotedVerses];
		const endIndex = currentIndex + offset;
		const result = Array.from(promotedCopy),
			[removed] = result.splice(currentIndex, 1);
		result.splice(endIndex, 0, removed);
		const verseIds = result.map(e => e.verse_id);
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/changePromotedOrder/${props.user.id}/${props.collectionId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				verseIds,
			}),
		}).then(res => res.json());
		setPromotedVerses(result);
		fetchUserCollectionNote({
			uniqueKey: props.uniqueKey,
			userId: props.user.id,
			collectionId: props.collectionId,
			noSave: true,
		});
	};
	const handleExtendPane = bool => {
		if (bool === false) {
			// setWidth(1170);
			setWidth(650);
			setShow(true);
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
			setTimeout(() => {
				droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
			}, 1500);
		} else {
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
			setTimeout(() => {
				droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
			}, 1500);
			setWidth(430);
			setShow(false);
		}
	};
	const removeCollectionNote = () => {
		if (collection && collection.verses) {
			collection.verses.forEach(({ id }) => {
				io(process.env.REACT_APP_BASE_URL).emit('update_verse', { verseId: id, userId: authState.user.id });
			});
		}

		deleteCollectionNote({ id: collectionState[props.uniqueKey].collection.id, userId: props.user.id });
		props.closePane(props.id);
		io(process.env.REACT_APP_BASE_URL).emit('update_cn_list', {
			fromVerse: false,
			userId: authState.user.id,
			collectionId: props.collectionNoteId,
			uniqueKey: props.uniqueKey,
		});
	};
	/** Adjusts verse text if there is a known incorrect format (currently there's only one) */
	const properFormat = value => {
		let newValue = { ...value };

		if (value.title === 'Doctrine and Covenants') {
			if (value.order === 77 && value.number < 10) {
				// Keep below spacing as is or else the display will get screwed up
				newValue.text = newValue.text.replace(' A.', `  A.`);
			}

			if (value.order === 84 && value.number === 99) {
				let verseRegex = /The Lord hath redeemed his people, Israel|According|Which was|And covenant/gi;
				newValue.text = newValue.text.replace(verseRegex, match => ` ${match}`);
			}
		}
		return newValue;
	};
	const verseAdded = () => {
		setShowVerseOverlay(true);
		setTimeout(() => setShowVerseOverlay(false), 1000);
	};
	const handleCustomMarkup = () => {
		if (collectionState[props.uniqueKey].collection.user_id === props.user.id) {
			toggleCustomMarkup({
				uniqueKey: props.uniqueKey,
				customMarkup,
				userId: props.user.id,
				collectionId: collectionState[props.uniqueKey].collection.id,
			});
		} else {
			setCustomMarkup(!customMarkup);
		}
		// collectionDispatch({ type: 'TOGGLE_CUSTOM_MARKUP', payload: { uniqueKey: props.uniqueKey, customMarkup, userId: props.user.id, collectionId: collectionState[props.uniqueKey].collection.id } })
		// props.setCustomMarkup(!props.customMarkup)
	};
	const handleUpdateDate = date => {
		// updateJournalDate(data)
		setShareExpires(date);
	};
	const unique = () => {
		return new Date().getTime().toString(36);
	};
	const affiliateCheck = async () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
			.then(res => res.json())
			.then(userplan => {
				userplan.affliate_code ? setAffiliateCode(userplan.affliate_code) : setAffiliateCode('');
			});
	};
	const copyLinkToClipBoard = () => {
		navigator.clipboard.writeText(
			`https://app.scripturenotes.com/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`
		);
		// navigator.clipboard.writeText(`https://muz3.ddns.net/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`)
		// navigator.clipboard.writeText(`http://localhost:3000/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`)
	};
	const handleStopSharing = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/stopSharing/${props.user.id}/${collectionState[props.uniqueKey].collection.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				can_save: null,
				shareExpires: null,
				shareSettings: null,
				shareCode: null,
				sharedNote: null,
				public: false,
			}),
		})
			.then(res => res.json())
			.then(shareCode => {
				fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
				// setShareExpires(null)
				// setShareCode('')
				// setShareEdit(null)
				// setShareSettings('')
			});
	};
	const handleGenerateLink = async () => {
		if (shareExpires === 'never') {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}/collections/editShareSettings/${props.user.id}/${collectionState[props.uniqueKey].collection.id}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						can_save: !shareEdit,
						shareExpires: shareExpires,
						shareSettings,
						shareCode: unique(),
						shared_note: sharedNote,
						public: isPublic,
					}),
				}
			)
				.then(res => res.json())
				.then(shareCode => {
					props.socket.emit('update_cn_list', {
						fromVerse: false,
						userId: props.user.id,
						uniqueKey: props.uniqueKey,
						collectionId: props.collectionId,
					});
					fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
					setShareCode(shareCode);
				});
		} else {
			const currentDate = Date.parse(new Date());
			const selectedDate = Date.parse(shareExpires);
			if (selectedDate > currentDate) {
				await fetch(
					`${process.env.REACT_APP_BASE_URL}/collections/editShareSettings/${props.user.id}/${collectionState[props.uniqueKey].collection.id}`,
					{
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							can_save: !shareEdit,
							shareExpires: shareExpires,
							shareSettings,
							shareCode: unique(),
							shared_note: sharedNote,
							public: isPublic,
						}),
					}
				)
					.then(res => res.json())
					.then(shareCode => {
						props.socket.emit('update_cn_list', {
							fromVerse: false,
							userId: props.user.id,
							uniqueKey: props.uniqueKey,
							collectionId: props.collectionId,
						});
						fetchUserCollectionNote({ uniqueKey: props.uniqueKey, userId: props.user.id, collectionId: props.collectionId });
						setShareCode(shareCode);
					});
				return;
			} else alert('Please select a valid date.');
		}
	};
	const orderVerses = (verses, promoted) => {
		return verses.map((value, index) => (
			<Draggable
				key={`${props.uniqueKey}-${value.id}`}
				draggableId={promoted ? `${props.uniqueKey}-promoted-${value.id}` : `${props.uniqueKey}-${value.id}`}
				index={promoted ? value.verseIndex : index}
			>
				{(dragProvided, snapshot) => {
					let draggableId = promoted ? `${props.uniqueKey}-promoted-${value.id}` : `${props.uniqueKey}-${value.id}`;
					return snapshot.isDragging ? (
						<DraggingItem ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
							<FontAwesomeIcon
								icon={bulkMoveState.length > 1 && bulkMoveState.some(e => e.draggableId === draggableId) ? faCopy : faFileAlt}
							/>
						</DraggingItem>
					) : (
						<div ref={dragProvided.innerRef}>
							{index === detailedVerseCount + 1 && (
								<EditButtonsContainer>
									<CreateCnBtn
										type='button'
										onClick={() => setDetailedVerseCount(detailedVerseCount + 50)}
										style={{ marginBottom: '32px', marginTop: '24px', marginRight: '5px' }}
									>
										Edit Next 50
									</CreateCnBtn>
									<CreateCnBtn
										type='button'
										onClick={() => setDetailedVerseCount(collectionState[props.uniqueKey].collection.verses.length)}
										style={{ marginBottom: '32px', marginTop: '24px' }}
									>
										Edit all
									</CreateCnBtn>
								</EditButtonsContainer>
							)}

							<CollectionNoteVerse
								show={show}
								key={value.id}
								verseScrollRef={verseScrollRef}
								textFormatter={textFormatter}
								index={promoted ? value.verseIndex : index}
								verse={properFormat(value)}
								allCnVerses={verses}
								dragProvided={dragProvided}
								isDragging={snapshot.isDragging}
								collectionObj={props.collectionObj}
								studyHelpTopic={collection ? collection.study_help_topic : null}
								studyHelpName={collection ? collection.study_help_name : null}
								user={props.user}
								uniqueKey={props.uniqueKey}
								collectionId={props.collectionId}
								//allCnPromotedVerses={props.promotedVerses}
								toggleLdsFootnotes={toggleLdsFootnotes}
								isSimple={index > detailedVerseCount}
								verseAdded={verseAdded}
								socket={props.socket}
								bulkMoveState={bulkMoveState}
								addVerse={addVerse}
								removeVerse={removeVerse}
								draggableId={promoted ? `${props.uniqueKey}-promoted-${value.id}` : `${props.uniqueKey}-${value.id}`}
								selectAllVerses={promoted ? selectAllPromoted : selectAllVerses}
								bulkMove={promoted ? promotedBulkMove : bulkMove}
								// isDragging={snapshot.isDragging && bulkMoveState.length > 1 ? true : false}
								proUser={isProUser()}
								bulkMoveModal={props.bulkMoveModal}
								promotedRender={promoted}
								promotedRenderIndex={index}
								lastPromoted={index < promotedVerses.length - 1}
								changeFavoriteOrder={changeFavoriteOrder}
								customMarkup={customMarkup}
								canEdit={canEdit}
								seenList={seenList}
								setSeenList={setSeenList}
								scrollRight={scrollRight}
								unique={unique}
								hideDone={props.hideDone}
								hideMine={props.hideMine}
								setHideDone={props.setHideDone}
								setHideMine={props.setHideMine}
							/>
						</div>
					);
				}}
			</Draggable>
		));
	};
	const handleEditorChange = (content, editor) => {
		// console.log(content);
		// setText(content);

		setSharedNote(content);
	};
	const getSunEditorInstance = sunEditor => {
		editor.current = sunEditor;
	};
	const handleExpireDateChange = date => {
		const currentDate = Date.parse(new Date());
		const selectedDate = Date.parse(date);
		if (selectedDate > currentDate) setShareExpires(date);
		else alert('Please select a valid date.');
	};
	return (
		<div style={{ height: 'calc(100%)', overflow: 'hidden' }}>
			<Resizable
				className='collectionNote-resizable'
				minWidth={show ? '45vw' : 440}
				maxWidth={width}
				// maxWidth={'45vw'}
				size={{ width, height: '100%' }}
				enable={{ right: false }}
				handleComponent={
					{
						// right: (
						// 	<FontAwesomeIcon
						// 		icon={faEllipsisV}
						// 		color='#b2b7be'
						// 		style={{
						// 			top: '50%',
						// 			right: '7px',
						// 			position: 'absolute',
						// 		}}
						// 	/>
						// ),
					}
				}
				onResizeStop={(e, direction, ref, d) => {
					setWidth(width + d.width);
				}}
				style={customMarkup ? { border: '3px solid #5c6694', padding: '110px 5px 5px 20px' } : {}}
			>
				<TextFormatter ref={textFormatter} style={{ display: 'none' }} className='TextFormatterClass'>
					<FormatBtn title='Websters 1828 Dict' onClick={findMeaning}>
						1828
					</FormatBtn>
					<FormatBtn title='Lexicon' onClick={findLexicon}>
						L
					</FormatBtn>
					<FormatBtn title='Thesaurus' onClick={findThesaurus}>
						T
					</FormatBtn>
					<FormatBtn title='Etymology' onClick={findEtymology}>
						E
					</FormatBtn>
					<FormatBtnBold id='toggleBold' data-do-bold='true' onClick={makeBold}>
						B
					</FormatBtnBold>
					<FormatBtnItalic id='toggleItalic' data-do-italic='true' onClick={makeItalic}>
						I
					</FormatBtnItalic>
					<FormatBtnUnderline id='toggleUnderline' data-do-underline='true' onClick={makeUnderline}>
						U
					</FormatBtnUnderline>
					<FormatBtnDobUnderline id='toggleStrike' data-do-strike='true' onClick={makeDobUnderline}>
						U
					</FormatBtnDobUnderline>
					<FormatBtnColor onClick={showColors}>
						A
						<TextColorWrapper ref={showTextColors}>
							<TextColorHolder
								onClick={() => {
									adTextColors('#00000');
								}}
								style={{ backgroundColor: 'rgb(0, 0, 0)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextColors('#0000ff');
								}}
								style={{ backgroundColor: 'rgb(0, 0, 255)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextColors('#00b000');
								}}
								style={{ backgroundColor: 'rgb(0, 176, 0)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextColors('#ff0000');
								}}
								style={{ backgroundColor: 'rgb(255, 0, 0)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextColors('#6600ff');
								}}
								style={{ backgroundColor: 'rgb(102, 0, 255)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextColors('#e37a07');
								}}
								style={{ backgroundColor: 'rgb(227, 122, 7)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextColors('#fafafa');
								}}
								style={{ backgroundColor: 'rgb(255, 255, 255)' }}
							></TextColorHolder>
						</TextColorWrapper>
					</FormatBtnColor>
					<FormatBtnBgColor onClick={showBgColors}>
						A
						<TextColorWrapper ref={showTextBgColors} style={{ width: '105px' }}>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#ff7b7b');
								}}
								style={{ backgroundColor: 'rgb(255, 123, 123)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#ffdba6');
								}}
								style={{ backgroundColor: 'rgb(255, 219, 166)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#ffffae');
								}}
								style={{ backgroundColor: 'rgb(255, 255, 174)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#ccfeb4');
								}}
								style={{ backgroundColor: 'rgb(204, 254, 180)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#cce0ff');
								}}
								style={{ backgroundColor: 'rgb(204, 224, 255)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#e8c6ff');
								}}
								style={{ backgroundColor: 'rgb(232, 198, 255)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#fdc8c9');
								}}
								style={{ backgroundColor: 'rgb(253, 200, 201)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#d9b3b3');
								}}
								style={{ backgroundColor: 'rgb(217, 179, 179)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#dddddd');
								}}
								style={{ backgroundColor: 'rgb(221, 221, 221)' }}
							></TextColorHolder>
							<TextColorHolder
								onClick={() => {
									adTextBgColors('#000000');
								}}
								style={{ backgroundColor: 'rgb(0, 0, 0)' }}
							></TextColorHolder>
						</TextColorWrapper>
					</FormatBtnBgColor>
					<FormatBtnClear onClick={makeClear} title='Clean'>
						T<span style={{ fontSize: '10px', fontStyle: 'normal', fontWeight: '600' }}>x</span>
					</FormatBtnClear>
					<PortalWithState closeOnOutsideClick closeOnEsc>
						{({ openPortal, closePortal, isOpen, portal }) => (
							<>
								<FormatBtnDelete onClick={openPortal}>
									R<span style={{ fontSize: '10px', fontStyle: 'normal', fontWeight: '600' }}>x</span>
								</FormatBtnDelete>
								{portal(
									<div>
										<div
											style={{
												top: 0,
												zIndex: 9999999,
												background: '#ddd',
												width: '100%',
												height: '100%',
												minHeight: '392px',
												opacity: '0.6',
												position: 'absolute',
											}}
										/>

										<DeletePopUpWrapper>
											<p>
												{props.customMarkup
													? 'Are you sure? This will remove ALL markup on this verse.'
													: 'Are you sure? This will remove the CUSTOM markup only on this verse.'}
											</p>
											<DeletePopUpBtnWrapper>
												<DeletePopUpBtn
													onClick={closePortal}
													onClickCapture={() => {
														deleteMarkup();
													}}
												>
													Delete
												</DeletePopUpBtn>
												<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
											</DeletePopUpBtnWrapper>
										</DeletePopUpWrapper>
									</div>
								)}
							</>
						)}
					</PortalWithState>
				</TextFormatter>
				<div {...props.dragHandleProps} />

				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='left' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				<div>
					<PrintIconWrapper>
						<Tooltip content='Print Report' direction='left' forceDirection={true}>
							<FontAwesomeIcon icon={faPrint} onClick={() => (isProUser() ? handleOnPrint() : alert('Must be a Pro user for this feature'))} />
						</Tooltip>
					</PrintIconWrapper>
					<PortalWithState closeOnOutsideClick closeOnEsc>
						{({ openPortal, closePortal, isOpen, portal }) => (
							<>
								{collectionState[props.uniqueKey] &&
									props.user &&
									collectionState[props.uniqueKey].collection.user_id === props.user.id &&
									!collectionState[props.uniqueKey].collection.originated_from && (
										<LinkIconWrapper>
											<Tooltip content='Share Link' direction='left' forceDirection={true}>
												<FontAwesomeIcon icon={faShareAlt} onClick={openPortal} />
											</Tooltip>
										</LinkIconWrapper>
									)}
								{portal(
									<>
										<div
											style={{
												top: 0,
												zIndex: 9999999,
												background: '#ddd',
												width: '100%',
												height: '100%',
												minHeight: '392px',
												opacity: '0.6',
												position: 'absolute',
											}}
										/>

										{!shareCode.length > 0 ? (
											<div>
												<ShareModalWrapper style={modalView === 'settings' ? {} : { display: 'none' }}>
													<ShareLinkTitle>Share Settings</ShareLinkTitle>
													<ShareSettingsContainer>
														<p style={{ color: 'red', textAlign: 'start' }}>
															Before sharing a collection note, please make sure you have included enough content to explain your note
															to a person new to the concept and how it relates to the verses you are sharing.
														</p>
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																flexDirection: 'row',
																width: '100%',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'flex-start',
																	flexDirection: 'column',
																	width: '100%',
																	alignItems: 'flex-start',
																}}
															>
																<div>
																	<input
																		style={{ marginRight: 5 }}
																		type='radio'
																		id='master'
																		value={'master'}
																		checked={shareSettings === 'master'}
																		onClick={() => setShareSettings('master')}
																	/>
																	<label htmlFor='master'>Master Note and Scriptures</label>
																</div>
																<div>
																	<input
																		style={{ marginRight: 5 }}
																		type='radio'
																		id='scriptures'
																		value={'scriptures'}
																		checked={shareSettings === 'scriptures'}
																		onClick={() => {
																			setShareEdit(false);
																			setShareSettings('scriptures');
																		}}
																	/>
																	<label htmlFor='scriptures'>Scriptures Only</label>
																</div>
															</div>
															<SaveCnBtn
																disabled={shareSettings === 'scriptures'}
																onClick={() => setModalView('editCN')}
																style={
																	shareSettings === 'scriptures'
																		? { color: 'grey', borderColor: 'grey', backgroundColor: '#fff' }
																		: {}
																}
															>
																Edit Shared Master Note
															</SaveCnBtn>
														</div>
														<ShareExpiresContainer>
															<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
																<p style={{ margin: 3 }}>Share Expires: </p>
																<input
																	type='date'
																	onChange={e => {
																		handleExpireDateChange(e.target.value);
																	}}
																	value={shareExpires}
																/>
															</div>
															<div style={{ marginTop: 3 }}>
																<input
																	style={{ marginRight: 5, marginLeft: 15 }}
																	type='checkbox'
																	id='never'
																	value={'never'}
																	checked={shareExpires === 'never' ? true : false}
																	onClick={() => setShareExpires('never')}
																/>
																<label htmlFor='never'>Never</label>
															</div>
														</ShareExpiresContainer>
														<div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																	width: '100%',
																	justifyContent: 'flex-start',
																}}
															>
																<div
																	style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
																>
																	<div style={{ width: '100%', textAlign: 'start' }}>
																		<input
																			disabled={shareSettings === 'scriptures'}
																			style={{ marginRight: 5 }}
																			type='checkbox'
																			id='edit'
																			value={'edit'}
																			checked={isPublic}
																			onClick={() => setIsPublic(!isPublic)}
																		/>
																		<label
																			htmlFor='edit'
																			style={shareSettings === 'scriptures' ? { marginTop: 5, color: 'grey' } : { marginTop: 5 }}
																		>
																			Make Collection Public
																		</label>
																	</div>
																	<p
																		style={{ color: 'red', textAlign: 'start' }}
																	>{` (Adds this note to the public collection note list for all users to see.)`}</p>
																</div>
															</div>
															<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
																<input
																	disabled={shareSettings === 'scriptures'}
																	style={{ marginRight: 5 }}
																	type='checkbox'
																	id='public'
																	value={'public'}
																	checked={shareEdit}
																	onClick={() => setShareEdit(!shareEdit)}
																/>
																<label
																	htmlFor='public'
																	style={shareSettings === 'scriptures' ? { marginTop: 5, color: 'grey' } : { marginTop: 5 }}
																>
																	Make note read-only so users can’t edit after saving. (This should be rare.)
																</label>
															</div>
															<div style={{ margin: '5px 0px' }}>
																<p style={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>
																	{/* How your name will appear: <span style={{ color: 'blue' }}>{authState.user.name}</span> */}
																	How your name will appear:{' '}
																	<span style={{ color: 'blue', margin: '0px 1.5px' }}>
																		{' '}
																		{authState.user.name && authState.user.name}{' '}
																	</span>
																	{` (You can edit this in the account settings tab on the dashboard.)`}
																</p>
																{/* <p
																	style={{ display: 'flex', justifyContent: 'flex-start',  textAlign: 'left' }}
																>{`(You can edit this in the account settings tab on the dashboard.)`}</p> */}
															</div>

															<p
																style={{
																	color: 'red',
																	display: 'flex',
																	justifyContent: 'flex-start',

																	textAlign: 'left',
																}}
															>
																Note: Anyone with the link can open this collection. If they save your note and you stop sharing the
																note, they will still have the original in their library. If you wish to update your shared Master
																Note, click Edit Shared Master Note above. If you modify the note, people will not receive updated
																notes after you save the new version. Only people who open your shared note after you have modified it
																will get the updated note.
															</p>
														</div>
													</ShareSettingsContainer>
													<DeletePopUpBtnWrapper>
														<SaveChangesBtn
															style={{ marginRight: '10px' }}
															onClick={() => {
																// copyToClipBoard()
																handleGenerateLink();
															}}
														>
															{collectionState[props.uniqueKey] &&
															collectionState[props.uniqueKey].collection &&
															collectionState[props.uniqueKey].collection.share_settings
																? 'Save and get link'
																: 'Generate Link'}
														</SaveChangesBtn>
														<DeletePopUpBtn onClick={closePortal}>Cancel</DeletePopUpBtn>
														{collectionState[props.uniqueKey] &&
															collectionState[props.uniqueKey].collection &&
															collectionState[props.uniqueKey].collection.share_settings && (
																<DeletePopUpBtn
																	onClick={() => {
																		closePortal();
																		handleStopSharing();
																	}}
																>
																	Stop sharing
																</DeletePopUpBtn>
															)}
													</DeletePopUpBtnWrapper>
												</ShareModalWrapper>

												<EditorPopUpWrapper style={modalView === 'editCN' ? {} : { display: 'none' }}>
													<ShareLinkTitle>Edit Shared Note</ShareLinkTitle>
													<SunEditor
														getSunEditorInstance={getSunEditorInstance}
														setDefaultStyle='color: #393f47; font-size: 15px; font-family: -apple-system, BlinkMacSystemFont, sans-serif;'
														setContents={sharedNote}
														height='150px'
														disable={canEdit()}
														setOptions={{
															imageFileInput: false,
															videoFileInput: false,
															height: 'auto',
															minHeight: '50px',
															maxHeight: '55vh',
															showPathLabel: false,
															charCounter: true,
															maxCharCount: 200000,
															buttonList: [
																['undo', 'redo'],
																[
																	// ':i-More Misc-default.more_vertical',
																	'font',
																	'fontSize',
																	'formatBlock',
																],
																['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
																['removeFormat'],
																['outdent', 'indent'],
																['align', 'list'],
															],
														}}
														onChange={handleEditorChange}
													/>
													<DeletePopUpBtnWrapper style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
														<ClosePopUpBtn
															style={{ marginRight: '10px' }}
															onClick={() => {
																// copyToClipBoard()
																setModalView('settings');
															}}
														>
															Save
														</ClosePopUpBtn>
														<DeletePopUpBtn
															onClick={() => {
																setModalView('settings');
																collectionState[props.uniqueKey].collection.shared_note
																	? setSharedNote(collectionState[props.uniqueKey].collection.shared_note)
																	: setSharedNote(collectionState[props.uniqueKey].collection.text);
															}}
														>
															Cancel
														</DeletePopUpBtn>
													</DeletePopUpBtnWrapper>
												</EditorPopUpWrapper>
											</div>
										) : (
											<ShareModalWrapper>
												<ShareLinkTitle>Share this link.</ShareLinkTitle>
												<ShareLink
													onClick={() => {
														copyLinkToClipBoard();
														closePortal();
														setShareCode('');
													}}
												>
													{`https://app.scripturenotes.com/?pane=collectionNote&shareCode=${shareCode}${
														affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''
													}`}
													{/* {`https://muz3.ddns.net/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`} */}

													{/* {`http://localhost:3000/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`} */}
												</ShareLink>
												<DeletePopUpBtnWrapper>
													<ClosePopUpBtn
														style={{ marginRight: '10px' }}
														onClick={() => {
															setShareCode('');
															copyLinkToClipBoard();
															closePortal();
														}}
													>
														Copy
													</ClosePopUpBtn>
													{/* <DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn> */}
												</DeletePopUpBtnWrapper>
											</ShareModalWrapper>
										)}
									</>
								)}
							</>
						)}
					</PortalWithState>

					{collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection.id && !canEdit() ? (
						<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									{isOwner() && (
										<DeleteCnWrapper>
											<Tooltip content='Delete Collection Note'>
												<FontAwesomeIcon key='CollectionNotePortal' icon={faTrashAlt} onClick={openPortal} />
											</Tooltip>
										</DeleteCnWrapper>
									)}
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<ShareModalWrapper>
												<p>Are you sure, you want to delete Collection Note?</p>
												<DeletePopUpBtnWrapper>
													<DeletePopUpBtn
														onClick={closePortal}
														onClickCapture={() => {
															removeCollectionNote();
														}}
													>
														Delete
													</DeletePopUpBtn>
													<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
												</DeletePopUpBtnWrapper>
											</ShareModalWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>
					) : null}
				</div>
				<div>
					<VerseCountWrapper>
						{collection.verses && <span style={{ width: 'max-content' }}>{`${collection.verses.length}: Verses`}</span>}
					</VerseCountWrapper>
					<ToggleSideWrapper>
						{show ? (
							<ToggleOpenPaneWrapper>
								<FontAwesomeIcon icon={faChevronLeft} onClick={() => handleExtendPane(true)} />
							</ToggleOpenPaneWrapper>
						) : (
							<ToggleOpenPaneWrapper>
								<FontAwesomeIcon icon={faChevronRight} onClick={() => handleExtendPane(false)} />
							</ToggleOpenPaneWrapper>
						)}
					</ToggleSideWrapper>
				</div>

				<div>
					<SplitPane
						split='horizontal'
						defaultSize='420'
						// defaultSize={show ? 345 : 420}
						// minSize='100'
						// maxSize='550'
						primary='first'
					>
						<CollectionNoteFormWrapper>
							{verseOverlay ? (
								<VerseOverlay>
									<FontAwesomeIcon
										icon={faCheckCircle}
										style={{ height: '50px', width: '50px', marginBottom: '10px', backgroundColor: 'white', borderRadius: '50%' }}
									/>
								</VerseOverlay>
							) : null}
							{collectionState[props.uniqueKey] && collectionState[props.uniqueKey].categories && (
								<CollectionNoteEditor
									studyHelpInfo={props.studyHelpInfo}
									collectionObj={props.collectionObj}
									uniqueKey={props.uniqueKey}
									user={props.user}
									dragHandleProps={props.dragHandleProps}
									show={show}
									collection={collectionState[props.uniqueKey]}
									tags={collectionState[props.uniqueKey].collection.tags}
									categories={collectionState[props.uniqueKey].collection.categories}
									allCategories={collectionState[props.uniqueKey].categories}
									// fetchAllCategories={fetchCategories}
									collectionNoteId={collectionState[props.uniqueKey].collection.id}
									// handleExtendPane={handleExtendPane}
									// handleToggleLdsFootnotes={handleToggleLdsFootnotes}
									toggleLdsFootnotes={toggleLdsFootnotes}
									verseAdded={verseAdded}
									socket={props.socket}
									customMarkup={customMarkup}
									setCustomMarkup={setCustomMarkup}
									handleCustomMarkup={handleCustomMarkup}
								/>
							)}
						</CollectionNoteFormWrapper>
						{collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection.verses.length >= 0 ? (
							<AllVersesWrapper>
								<AllVersesHeightWrapper>
									<Droppable droppableId={props.uniqueKey} type='QUOTE'>
										{(dropProvided, snapshot) => (
											<div
												ref={dropProvided.innerRef}
												{...dropProvided.droppableProps}
												className='collection-note-content'
												// style={[{ height: '95%' }, getListStyle(snapshot.isDraggingOver)]}
												// style={{ height: '95%' }}
												style={getListStyle(snapshot.isDraggingOver)}
											>
												<Scrollbars ref={verseScrollRef} style={{ marginLeft: 12 }}>
													{collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection.verses.length !== 0 ? (
														<VerseWithPrevNextButtonsWrapper>
															{renderPromotedAndUnpromotedVerses(promotedVerses, 'Favorites', true)}
															{renderPromotedAndUnpromotedVerses(collectionState[props.uniqueKey].collection.verses, 'All Verses')}
														</VerseWithPrevNextButtonsWrapper>
													) : (
														<span>Empty</span>
													)}
												</Scrollbars>
												{dropProvided.placeholder}
											</div>
										)}
									</Droppable>
								</AllVersesHeightWrapper>
							</AllVersesWrapper>
						) : (
							<Loading />
						)}
					</SplitPane>
				</div>
			</Resizable>
		</div>
	);
};

export default CollectionNote;
