import React, { useState, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import ToggleDisplay from 'react-toggle-display';
import StripeCheckout from 'react-stripe-checkout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faEdit, faSave, faInfoCircle, faBook, faBookmark } from '@fortawesome/fontawesome-free-solid/';
import { PortalWithState } from 'react-portal';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
import './dashboard.css';

import { Scrollbars } from 'react-custom-scrollbars';

import styled from 'styled-components';

import { DashboardContext } from '../../ContextProviders/DashboardProvider';

import { NavContext } from '../../ContextProviders/NavProvider';

import ClearHistory from '../Helpers/ClearHistory';
import Tooltip from 'react-tooltip-lite';
import YearlyStripe from '../Stripe/YearlyStripe';
import MonthlyStripe from '../Stripe/MonthlyStripe';
import CancelStripe from '../Stripe/CancelStripe';
import FreeBanner from '../FreeBanner/FreeBanner';

// import io from 'socket.io-client';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { set } from 'lodash';

import Modal from 'react-modal';
import ImportNotes from './ImportNotes';
import Popup from './Popup';
import PopupCC from './PopupCC';
import PopupSub from './PopupSub';
import PopupMember from './PopupMember';
import PopupUpgrade from './PopupUpgrade';
import UserPlanChange from './UserPlanChange';

import Papa from 'papaparse';

const AffiliateSection = styled.div`
	// margin-top: 15px;
`;

const DashboardWrapper = styled.div`
	position: relative;

	display: inline-block;

	vertical-align: top;

	margin-right: 25px;

	height: 100%;

	min-width: 555px;

	border-radius: 10px;

	font-size: 14px;

	z-index: 1;
`;

const ClosePaneWrapper = styled.div`
	display: block;

	position: absolute;

	top: 10px;

	right: 10px;

	cursor: pointer;

	z-index: 1;

	background-size: cover;

	background-repeat: no-repeat;

	color: rgb(178 183 190);

	&:hover {
		color: #1a56eb;
	}
`;

const DragPaneWrapper = styled.div`
	margin: 0 30px 0 25px;

	padding: 15px 0 0px;

	position: absolute;

	top: 0;

	left: 0;

	width: calc(100% - 25px - 30px);

	overflow: hidden;

	padding-top: 35px;
	z-index: 999;
	background-color: #fafafa;
`;

const ProfileInfoWrapper = styled.div`
	display: flex;

	justify-content: space-between;
`;

const ProfileInfoLeft = styled.div`
	min-width: 250px;
`;

const ProfileInfoLeft2 = styled.div`
	display: flex;
	min-width: 250px;
`;

const UserDataName = styled.h4`
	margin-bottom: 10px;
	color: #000;
	font-size: 25px;
	line-height: 25px;
`;

const UserDataEmail = styled.p`
	margin-bottom: 5px;
	color: #6a7583;
	font-size: 15px;
	line-height: 19px;
`;

const ActionList = styled.ul`
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	display: flex;
	align-items: center;
`;

const ActionItem = styled.li`
	margin-right: 5px;
	cursor: pointer;
	font-size: 13px;
	line-height: 16px;
`;

const ProfileInfoRight = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: right;
`;

const BrowserBannerText = styled.span`
	font-size: 12px;
	margin-bottom: 3px;
`;

const HistoryText = styled.h2`
	margin-bottom: 0;
	color: #94b3db;
	font-size: 22px;
`;

const HistoryTextColumnWrapper = styled.div`
	display: inline-flex;
`;

const HistoryColumnWidth = styled.div`
	width: 160px;
	margin-right: 15px;
`;

const HistoryColumnText = styled.h3`
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;

	-o-text-overflow: ellipsis;

	text-overflow: ellipsis;

	font-size: 18px;
`;
const AccountSettingsWrapper = styled.div`
	margin-top: 25px;

	width: 300px;

	margin-right: 15px;

	position: relative;

	max-width: 423px;

	height: 90%;
`;

const AccountSettingsText = styled.h2`
	margin-bottom: 5;
	color: #94b3db;
	font-size: 22px;
	// margin-top: 10px;
`;

const StripeBtn = styled.button`
	cursor: pointer;

	color: #000;

	border: 2px solid black;

	font-weight: 400;

	text-align: center;

	width: 100%;

	padding: 5px 10px;

	background-color: #fff;

	&:hover {
		color: #fff;

		background-color: #000;
	}
`;

const ChangePasswordText = styled.h3`
	margin-bottom: 10px;

	color: #94b3db;

	font-size: 22px;

	margin-top: 10px;
`;

const ForgotPasswordForm = styled.form`
	margin-top: 0px;
`;

const FormGroup = styled.div`
	margin-bottom: 8px;
`;

const ChangePasswordLabel = styled.label`
	padding-left: 10px;

	margin-bottom: 10px;

	color: #000;

	font-size: 18px;

	width: 100%;
`;
const ChangeNameLabel = styled.label`
	padding-left: 10px;

	margin-bottom: 10px;

	color: #000;

	font-size: 18px;

	width: 100%;
`;

const ChangePasswordInput = styled.input`
	display: block;

	width: 100%;

	padding: 6px 10px;

	border: none;

	border-radius: 0;

	color: #000;

	background-color: #ebebeb;
`;
const ChangeNameInput = styled.input`
	display: block;

	width: 100%;

	padding: 6px 10px;

	border: none;

	border-radius: 0;

	color: #000;

	background-color: #ebebeb;
`;

const ChangePasswordBtn = styled.button`
	cursor: pointer;

	width: 100%;

	padding: 8px 12px;

	color: #000;

	background-color: inherit;

	border-radius: 0;

	border: 2px solid black;

	&:hover {
		background-color: #000;

		color: #ffffff;
	}
`;

const ImportNotesBtn = styled.button`
	cursor: pointer;

	width: 100%;

	margin-top: 10px;

	padding: 12px;

	color: #000;

	background-color: inherit;

	border-radius: 0;

	border: 2px solid black;

	&:hover {
		background-color: #000;

		color: #ffffff;
	}
`;

const ImportNotesModalBtn = styled.button`
	width: 100%;
	padding: 5px 5px;
	font-weight: 700;
	font-size: 12px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;

const ContentPaneWrapper = styled.div`
	position: relative;

	padding-bottom: 13px;

	height: 100%;

	min-width: 315px;
`;

const ContentBottomDashboard = styled.div`
	width: 100%;

	height: 100%;

	display: inline-flex;
`;

const ContentColumnWrapper = styled.div`
	width: 170px;

	margin-right: 15px;
`;

const ContentPane = styled.div`
	width: 100%;

	height: 100%;

	visibility: visible;
`;

const DashboardListWrapper = styled.ul`
	padding-left: 0;

	margin-bottom: 60px;

	list-style: none;

	overflow-x: hidden;
`;

const DashboardListItem = styled.li`
	margin-bottom: 6px;
	white-space: nowrap;
	text-overflow: elipsis;

	cursor: pointer;

	font-size: 15px;

	line-height: 16px;

	color: #6a7583;

	&:hover {
		background-color: #ececec;
	}
`;

const ErrorMessage = styled.div`
	color: red;

	margin: 8px 0;

	font-size: 15px;
`;

const SuccessMessage = styled.div`
	color: green;

	margin: 8px 0;

	font-size: 15px;
`;
const Highlighter = styled.span`
	font-weight: 500;
	color: #4a73bc;
`;
const Para = styled.p`
	margin-bottom: 6px;
`;
const CcUpdateLink = styled.span`
	cursor: pointer;
	color: #2e83ba;
	display: inline-block;
	padding: 2px 0px;
	border-radius: 3px;
	&:hover {
		text-decoration: underline;
	}
`;
const SubUpdateLink = styled.span`
	cursor: pointer;
	color: #2e83ba;
	display: block;
	padding: 2px 0px;
	border-radius: 3px;
	&:hover {
		text-decoration: underline;
	}
`;
const SubUpgradeLink = styled.button`
	cursor: pointer;
	color: #fff;
	display: block;
	padding: 6px 15px;
	border-radius: 3px;
	background-color: #2e83ba;
	border: none;
`;
const importNotesModalStyle = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	overlay: { zIndex: 500 },
};
const ChangeEmailBtn = styled.button`
	// display: flex;
	// justify-content: center;
	// align-items: center;
	padding: 5px 5px;
	line-height: 10px;
	font-weight: 700;
	font-size: 12px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	height: 80%;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;

const ImportNotesContainer = styled.div``;

const ImportNotesTitle = styled.h3`
	color: #94b3db;

	font-size: 22px;

	margin-top: 10px;
`;

const ProgressBarContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const UpdateFieldsContainer = styled.div`
	margin-bottom: 15px;
	margin-bottom: 15px;
	color: #6a7583;
	font-size: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
`;

const ChaptersContainer = styled.div`
	display: flex;
	cursor: pointer;
`;
const BookmarkContainer = styled.div`
	display: flex;
	width: 100%;
	// justify-content: sp;
	cursor: pointer;
`;
const HistoryViewContainer = styled.div`
	display: flex;
`;
const EditNameSpan = styled.span``;

const Dashboard = props => {
	// Local State
	const [affilateCode, setAffilateCode] = useState('');

	const [isOpenPopup, setIsOpenPopup] = useState(false);
	const [isOpenPopup2, setIsOpenPopup2] = useState(false);
	const [isOpenPopup3, setIsOpenPopup3] = useState(false);
	const [isOpenPopup4, setIsOpenPopup4] = useState(false);
	const [isOpenPopup5, setIsOpenPopup5] = useState(false);
	const [upgradeTo, setUpgradeTo] = useState('');
	const [upgradeToPlan, setUpgradeToPlan] = useState('');

	const [panel, setPanel] = useState('default');

	const [oldPassword, setOldPassword] = useState('');

	const [newPassword, setNewPassword] = useState('');

	const [newName, setNewName] = useState();

	const [userId, setUserId] = useState('');

	const [errors, setErrors] = useState(false);

	const [changePasswordError, setChangePasswordError] = useState('');

	const [plan, setPlan] = useState('');

	const [userBrowser, setUserBrowser] = useState('');

	const [subscriptionDaysLeft, setSubscritionDaysLeft] = useState('');

	const [stripePlanName, setStripePlanName] = useState('noplan');

	const [cardLastFour, setCardLastFour] = useState(false);

	const [successfulPasswordUpdate, setSuccessfulPasswordUpdate] = useState(false);
	const [successfulNameChange, setSuccessfulNameChange] = useState(false);

	const { dashboardState, dashboardDispatch, fetchUserHistory, deleteBookmark } = useContext(DashboardContext);

	const { handleAddCollection, handleOpenVerses, handleAddSearchResult, state, dispatch } = useContext(NavContext);

	const { authState, isProUser, isTrialUser, hasProAccount, hasProCancelled, hasCancelled, authDispatch } = useContext(AuthContext);

	const [userPlan, setUserPlan] = useState('');

	const [nextPaymentDate, setNextPaymentDate] = useState('');
	const [importIsOpen, setImportIsOpen] = useState(false);
	const [trialDate, setTrialDate] = useState(new Date());
	const [importLoading, setImportLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [importLength, setImportLength] = useState(0);
	const [dateCheck, setDateCheck] = useState(false);
	const [importComplete, setImportComplete] = useState(false);
	const [csvFile, setCsvFile] = useState(null);
	const [familyMembers, setFamilyMembers] = useState('');
	const [diffDays, setDiffDays] = useState('');
	const [newDays, setnewDays] = useState('');
	const [historyView, setHistoryView] = useState('history');
	const [planType, setPlanType] = useState('');
	const [newYearlyDate, setNewYearlyDate] = useState();
	useEffect(() => {
		getUserBrowser();
		fetchUserHistory({ uniqueKey: props.uniqueKey, userId: props.user.id });
		userplan();
		var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		var firstDate = new Date(authState.user.stripe_subscription_expires_at);
		var secondDate = new Date();

		var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
		setDiffDays(diffDays);
	}, []);
	useEffect(() => {
		var firstDate = new Date(props.user.stripe_subscription_expires_at);
		if (upgradeTo == '3year') {
			var newDays = new Date(firstDate.setFullYear(firstDate.getFullYear() + 3));
		} else if (upgradeTo == '5year') {
			var newDays = new Date(firstDate.setFullYear(firstDate.getFullYear() + 5));
		} else if (upgradeTo == '10year') {
			var newDays = new Date(firstDate.setFullYear(firstDate.getFullYear() + 10));
		} else {
			var newDays = new Date(firstDate.setFullYear(firstDate.getFullYear() + 1));
		}
		// var newDays=new Date(firstDate.setFullYear(firstDate.getFullYear() + 3));
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};
		var splitDate = newDays.toLocaleString('en-US', options);
		var onlyDate = splitDate[0].split('/');
		setnewDays(splitDate);
		setNewYearlyDate(firstDate.toLocaleString());
	}, [upgradeTo]);
	//TODO Sockets for user history
	useEffect(() => {
		if (props.user.stripe_card_last_four) {
			setCardLastFour(true);
		}
		if (authState && authState.user && authState.user.id) {
			const socket = props.socket;

			socket.on('get_updated_cn_list_' + authState.user.id, msg => {
				fetchUserHistory({ uniqueKey: props.uniqueKey, userId: props.user.id });
			});
			socket.on('update_bookmark_list_' + authState.user.id, msg => {
				fetchUserHistory({ uniqueKey: props.uniqueKey, userId: props.user.id });
			});
			// socket.on('get_updated_search_history_' + authState.user.id, msg => {

			// 	fetchUserHistory({ uniqueKey: props.uniqueKey, userId: props.user.id });
			// })

			return () => {
				socket.off('get_updated_cn_list_' + authState.user.id);
				// socket.off('get_updated_search_history_' + authState.user.id)
			};
		}
	}, [authState]);
	useEffect(() => {
		let key;
		if (state) {
			key = state.filter(key => props.uniqueKey === key.key);
			if (key[0] && key[0].panel) {
				key[0].panel === 'account' && key[0].key === props.uniqueKey ? setPanel('account') : setPanel('default');
			}
		}
	}, [state]);

	const stripeKey = `${process.env.REACT_APP_STRIPE_API_KEY}`;

	const onToken = (token, plan) => {
		let { userId } = this.state;

		let errors = this.getValidationErrors();

		if (!errors) {
			try {
				this.props.billingSubscribe({ token: token.id, plan_id: plan }, userId);

				window.open('https://scripturenotes.com/thank-you-for-subscribing');
			} catch (err) {}
		}
	};
	const handleDeleteBookmark = obj => {
		props.socket.emit('update_bookmark_list', { userId: authState.user.id, verseId: obj.verseId, bookmarkId: null });
		deleteBookmark({ userId: props.user.id, bookmarkId: obj.bookmarkId, uniqueKey: props.uniqueKey });
	};
	const handleLibraryDownload = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/downloadLibrary`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then(res => res.json())
			.then(res => {
				const { verses } = res;
				// const versesCSV = Papa.unparse(verses);
				// Convert each JSON object in the array to a single line string
				const jsonlString = verses.map(obj => JSON.stringify(obj)).join('\n');
				// Create a blob from the JSONL string
				const blob = new Blob([jsonlString], { type: 'text/plain' });

				// Create a link element to trigger the download
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'data.jsonl';
				link.style.display = 'none';

				// Append the link to the body, click it, and remove it
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				return;
			});
	};
	/** Changes the user's password if they correctly submit the current one as well */
	const changePassword = async () => {
		try {
			if (oldPassword && newPassword) {
				setSuccessfulPasswordUpdate(false);
				let changePasswordRes = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/changePassword`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ oldPassword, newPassword, userId: props.user.id }),
				}).then(res => res.json());

				if (changePasswordRes === 'Incorrect password') setChangePasswordError('Incorrect password');
				else {
					setChangePasswordError('');
					setSuccessfulPasswordUpdate(true);
					setOldPassword('');
					setNewPassword('');
				}
			}
		} catch (err) {}
	};
	const changeName = async () => {
		try {
			setSuccessfulNameChange(false);
			let changeNameRes = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/changeName/${authState.user.id}`, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: newName }),
			}).then(res => res.json());

			if (changeNameRes !== 'OK') console.log('name wasnt changed');
			else {
				authDispatch({ type: 'UPDATE_NAME', payload: { name: newName } });
				setNewName('');
				setSuccessfulNameChange(true);
			}
		} catch (err) {}
	};

	const changeEmail = async () => {
		setIsOpenPopup(!isOpenPopup);
	};
	const changeCC = async () => {
		setIsOpenPopup2(!isOpenPopup2);
	};
	const changeSub = async plan => {
		setUpgradeTo(plan);
		if (plan == 'Yearly') {
			setUpgradeToPlan('Yearly');
		}
		if (plan == '3year') {
			setUpgradeToPlan('3 Year');
		}
		if (plan == '5year') {
			setUpgradeToPlan('5 Year');
		}
		if (plan == '10year') {
			setUpgradeToPlan('10 Year');
		}
		if (plan == 'Yearly') {
			setPlanType('single');
		}
		setIsOpenPopup3(!isOpenPopup3);
	};
	const addMember = async () => {
		setIsOpenPopup4(!isOpenPopup4);
	};
	const upgradePlan = async () => {
		setIsOpenPopup5(!isOpenPopup5);
	};
	const saveAffiliate = async () => {
		try {
			let savingAffiliateCode = await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/updateAffilateCode`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: props.user.email, code: affilateCode }),
			}).then(res => res.json());
		} catch (err) {}
	};

	const showInfo = async () => {};
	const userplan = async () => {
		let plan = fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
			.then(res => res.json())
			.then(userplan => {
				setStripePlanName(userplan.stripe_plan_name);
				setAffilateCode(userplan.affliate_code);
				setFamilyMembers(userplan.family_members);
				if (authState.user.giver_email === '' || authState.user.giver_email === null) {
					var showGift = ' ';
				} else {
					var showGift = ' Gift ';
				}
				if (userplan.stripe_plan_name == 'Pro') setUserPlan('You are on Trial Period');
				if (userplan.stripe_plan_name == 'Pro - Monthly') setUserPlan('Monthly Pro plan');
				if (userplan.stripe_plan_name == 'family-member') setUserPlan('Child plan');
				if (userplan.stripe_plan_name == 'Pro - Yearly') setUserPlan('Yearly Pro plan');
				if (userplan.stripe_plan_name == 'Pro - 3Yearly') setUserPlan('3 Year' + showGift + 'plan');
				if (userplan.stripe_plan_name == 'Pro - 5Yearly') setUserPlan('5 Year' + showGift + 'plan');
				if (userplan.stripe_plan_name == 'Pro - 10Yearly') setUserPlan('10 Year' + showGift + 'plan');
				if (userplan.stripe_plan_name == 'noplan')
					setUserPlan('You are on the free plan. Please consider subscribing to help future development.');
			});
	};

	const handleSearch = (searchQuery, fullObj) => {
		handleAddSearchResult(props.uniqueKey, searchQuery, fullObj.searchSection || 'scripturesOnly', 'none', 'none', 'none');
	};

	//   changeFootnotes(checked) {

	//     //  props function to alter value of ldsFootnotesDisplay

	//   }

	//   shouldComponentUpdate(nextProps) {

	//     if (nextProps.index !== this.props.index) return false;

	//     return true

	//   }

	//   componentDidMount() {

	//     if (!this.props.search) this.props.fetchHistoryAction();

	//     this.props.fetchChaptersReadAction();

	//     this.props.fetchBillingSettingsAction();

	//     this.props.fetchCollectionsViewedAction();

	//     this.getUserBrowser();

	//     this.renderBrowserWarning();

	//     ReactDom.findDOMNode(this).scrollIntoView({

	//       block: 'end',

	//       behavior: 'smooth'

	//     });

	//   }

	//   const handleHistoryItemClick = (searchText, selectedOption) => {

	//     this.props.handleAddSearchResults('search', {

	//       text: searchText,

	//       option: selectedOption

	//     }, this.props.componentIndex);

	//   };

	//   const handleAddVerses = (chapterId, volumeObj, bookObj, chapterNumber) => {

	//     this.props.handleAddVerses(chapterId, volumeObj, bookObj, chapterNumber, this.props.componentIndex)

	//   };

	//   const getValidationErrors = () => {

	//     if (this.props.errors) {

	//       return [...this.props.errors];

	//     } else {

	//       return false;

	//     }

	//   }

	const getUserBrowser = () => {
		let sBrowser;

		const sUsrAg = navigator.userAgent;

		if (sUsrAg.indexOf('Firefox') > -1) {
			sBrowser = 'Firefox';
		} else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
			sBrowser = 'Samsung';
		} else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
			sBrowser = 'Opera';
		} else if (sUsrAg.indexOf('Trident') > -1) {
			sBrowser = 'IE';
		} else if (sUsrAg.indexOf('Edge') > -1 || sUsrAg.indexOf('Edg') > -1) {
			sBrowser = 'Edge';
		} else if (sUsrAg.indexOf('Chrome') > -1) {
			sBrowser = 'Chrome';
		} else if (sUsrAg.indexOf('Safari') > -1) {
			sBrowser = 'Safari';
		} else {
			sBrowser = 'unknown';
		}

		setUserBrowser(sBrowser);
	};

	//   const renderStatusMessage = () => {

	//     if (this.state.errors) {

	//       return <Errors errors={this.state.errors} />;

	//     }

	//     if (this.props.change && this.props.change.errors) {

	//       return <Errors errors={[this.props.change.errors]} />;

	//     }

	//     if (this.props.change && this.props.change.success) {

	//       return <Success message={['Password changed success']} />;

	//     }

	//     return null;

	//   }

	const renderPlanTextMessage = () => {
		const data = props.user;

		// setTrialDate(data.stripe_trial_ends_at);

		const moment = require('moment');
		const ted = moment(data.stripe_trial_ends_at).format('MM-DD-YYYY');
		const stripeSubExpire = moment(data.stripe_subscription_expires_at).format('MM-DD-YYYY');
		const stripeSubExpireformated = moment(data.stripe_subscription_expires_at).format('MM-DD-YYYY');

		const cardExpire = moment(`${data.stripe_card_exp_month}/${data.stripe_card_exp_year}`, 'M-YYYY');
		if (stripePlanName.trim() === 'Pro') {
			if (data.meta_data) {
				var decoded = JSON.parse(data.meta_data);
				var accountStatus = decoded.accountStatus;
			} else {
				var accountStatus = '';
			}

			if (accountStatus == 'Cancelled') {
				return (
					<div>
						<Para>You have cancelled your membership but remain on the Pro trial until {ted}.</Para>
					</div>
				);
			} else {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>14 Days Pro Trial Plan</Highlighter>.&nbsp; Your trial converts to the pro version on {ted}
						</Para>
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'Free' || stripePlanName.trim() === 'noplan') {
			if (data.meta_data) {
				var decoded = JSON.parse(data.meta_data);
				var accountStatus = decoded.accountStatus;
			} else {
				var accountStatus = '';
			}

			if (accountStatus == 'Cancelled') {
				return (
					<div>
						<Para> You have cancelled your subscription. Please re-subscribe for pro features to support future enhancements.</Para>
					</div>
				);
			} else {
				return (
					<div>
						<Para> You are currently on free plan. Please subscribe for pro features to support future enhancements.</Para>
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'Pro - Monthly') {
			if ('active' === 'active') {
				if (data.meta_data) {
					var decoded = JSON.parse(data.meta_data);
					var accountStatus = decoded.accountStatus;
				} else {
					var accountStatus = '';
				}

				if (accountStatus == 'Cancelled') {
					return (
						<div>
							<Para>You have cancelled your membership but remain on the Pro version until {stripeSubExpireformated}.</Para>
						</div>
					);
				} else {
					return (
						<div>
							<Para>
								You are currently on the <Highlighter>Pro - Monthly Plan</Highlighter> which will renew on{' '}
								<Highlighter>{stripeSubExpire}</Highlighter> for <Highlighter>&#36;4.95.</Highlighter>
							</Para>

							{data.stripe_card_last_four ? (
								<Para>
									Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
									<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
								</Para>
							) : null}
						</div>
					);
				}
			} else if (data.stripe_subscription_status === 'cancelled') {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>Pro - Monthly Plan</Highlighter> which will expire on{' '}
							<Highlighter>{stripeSubExpire} </Highlighter>
						</Para>

						{data.stripe_card_last_four ? (
							<Para>
								Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
								<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
							</Para>
						) : null}
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'family-member') {
			if ('active' === 'active') {
				if (data.meta_data) {
					var decoded = JSON.parse(data.meta_data);
					var accountStatus = decoded.accountStatus;
				} else {
					var accountStatus = '';
				}

				if (accountStatus == 'Cancelled') {
					return (
						<div>
							<Para>Your membership has been canceled but remain on until {stripeSubExpireformated}.</Para>
						</div>
					);
				} else {
					return (
						<div>
							<Para>
								You are currently on the <Highlighter>Child Plan</Highlighter>
							</Para>
						</div>
					);
				}
			} else if (data.stripe_subscription_status === 'cancelled') {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>Pro - Monthly Plan</Highlighter> which will expire on{' '}
							<Highlighter>{stripeSubExpire} </Highlighter>
						</Para>

						{data.stripe_card_last_four ? (
							<Para>
								Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
								<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
							</Para>
						) : null}
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'Pro - Yearly') {
			if ('active' === 'active') {
				if (data.meta_data) {
					var decoded = JSON.parse(data.meta_data);
					var accountStatus = decoded.accountStatus;
				} else {
					var accountStatus = '';
				}

				if (accountStatus == 'Cancelled') {
					return (
						<div>
							<Para>You have cancelled your membership but remain on the Pro version until {stripeSubExpire}</Para>
						</div>
					);
				} else {
					if (data.stripe_card_last_four) {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>Pro - Yearly Plan</Highlighter> which will renew on{' '}
									<Highlighter>{stripeSubExpire}</Highlighter> for <Highlighter>&#36;49.95.</Highlighter>
								</Para>

								<Para>
									Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
									<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
								</Para>
							</div>
						);
					} else {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>Pro - Yearly Plan</Highlighter> which will expire{' '}
									<Highlighter>{stripeSubExpire}</Highlighter>
								</Para>
							</div>
						);
					}
				}
			} else if (data.stripe_subscription_status === 'cancelled') {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>Pro - Yearly Plan</Highlighter> which will expire on{' '}
							<Highlighter>{stripeSubExpire} </Highlighter>
						</Para>

						{data.stripe_card_last_four ? (
							<Para>
								Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
								<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
							</Para>
						) : null}
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'Pro - 3Yearly') {
			if ('active' === 'active') {
				if (data.meta_data) {
					var decoded = JSON.parse(data.meta_data);
					var accountStatus = decoded.accountStatus;
				} else {
					var accountStatus = '';
				}

				if (accountStatus == 'Cancelled') {
					return (
						<div>
							<Para>You have cancelled your membership but remain on the Pro version until {stripeSubExpire}</Para>
						</div>
					);
				} else {
					if (data.stripe_card_last_four) {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>{userPlan}</Highlighter> which will renew on{' '}
									<Highlighter>{stripeSubExpire}</Highlighter> for <Highlighter>&#36;49.95.</Highlighter>
								</Para>

								<Para>
									Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
									<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
								</Para>
							</div>
						);
					} else {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>{userPlan}</Highlighter> which will expire{' '}
									<Highlighter>{stripeSubExpire}</Highlighter>
								</Para>
							</div>
						);
					}
				}
			} else if (data.stripe_subscription_status === 'cancelled') {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>{userPlan}</Highlighter> which will expire on <Highlighter>{stripeSubExpire} </Highlighter>
						</Para>

						{data.stripe_card_last_four ? (
							<Para>
								Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
								<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
							</Para>
						) : null}
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'Pro - 5Yearly') {
			if ('active' === 'active') {
				if (data.meta_data) {
					var decoded = JSON.parse(data.meta_data);
					var accountStatus = decoded.accountStatus;
				} else {
					var accountStatus = '';
				}

				if (accountStatus == 'Cancelled') {
					return (
						<div>
							<Para>You have cancelled your membership but remain on the Pro version until {stripeSubExpire}</Para>
						</div>
					);
				} else {
					if (data.stripe_card_last_four) {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>{userPlan}</Highlighter> which will renew on{' '}
									<Highlighter>{stripeSubExpire}</Highlighter> for <Highlighter>&#36;49.95.</Highlighter>
								</Para>

								<Para>
									Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
									<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
								</Para>
							</div>
						);
					} else {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>{userPlan}</Highlighter> which will expire{' '}
									<Highlighter>{stripeSubExpire}</Highlighter>
								</Para>
							</div>
						);
					}
				}
			} else if (data.stripe_subscription_status === 'cancelled') {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>{userPlan}</Highlighter> which will expire on <Highlighter>{stripeSubExpire} </Highlighter>
						</Para>

						{data.stripe_card_last_four ? (
							<Para>
								Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
								<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
							</Para>
						) : null}
					</div>
				);
			}
		} else if (stripePlanName.trim() === 'Pro - 10Yearly') {
			if ('active' === 'active') {
				if (data.meta_data) {
					var decoded = JSON.parse(data.meta_data);
					var accountStatus = decoded.accountStatus;
				} else {
					var accountStatus = '';
				}

				if (accountStatus == 'Cancelled') {
					return (
						<div>
							<Para>You have cancelled your membership but remain on the Pro version until {stripeSubExpire}</Para>
						</div>
					);
				} else {
					if (data.stripe_card_last_four) {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>{userPlan}</Highlighter> which will renew on{' '}
									<Highlighter>{stripeSubExpire}</Highlighter> for <Highlighter>&#36;49.95.</Highlighter>
								</Para>

								<Para>
									Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
									<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
								</Para>
							</div>
						);
					} else {
						return (
							<div>
								<Para>
									You are currently on the <Highlighter>{userPlan}</Highlighter> which will expire{' '}
									<Highlighter>{stripeSubExpire}</Highlighter>
								</Para>
							</div>
						);
					}
				}
			} else if (data.stripe_subscription_status === 'cancelled') {
				return (
					<div>
						<Para>
							You are currently on the <Highlighter>{userPlan}</Highlighter> which will expire on <Highlighter>{stripeSubExpire} </Highlighter>
						</Para>

						{data.stripe_card_last_four ? (
							<Para>
								Your current credit card on file ending with <Highlighter>{data.stripe_card_last_four}</Highlighter> expires on{' '}
								<Highlighter>{cardExpire.format('MM/YY')}.</Highlighter>
							</Para>
						) : null}
					</div>
				);
			}
		}
	};

	const renderBrowserWarning = () => {
		const moment = require('moment');

		const today = moment();

		const subscriptionExpirationDate = moment(this.props.user.data.stripe_subscription_expires_at);

		let daysLeft = subscriptionExpirationDate.diff(today, 'days');

		this.setState({
			subscriptionDaysLeft: daysLeft,
		});
	};

	//   const onSubmit = event => {

	//     event.preventDefault();

	//     let errors = this.getValidationErrors();

	//     if (!errors) {

	//       let { userId, oldPassword, newPassword } = this.state;

	//       this.props.userChangePassword({ oldPassword, newPassword }, userId, event.target.key.value);

	//       this.setState({

	//         oldPassword: '',

	//         newPassword: ''

	//       });

	//     }

	//   };
	const onChange = event => {
		this.setState({
			...this.state,

			userId: this.props.user.data.id,

			[event.target.name]: event.target.value,
		});

		if (this.props.change && this.props.change.errors) {
			this.props.change.errors = false;
		}
	};
	const onSubscriptionCancel = () => {
		let { userId } = this.state;

		let errors = this.getValidationErrors();

		var moment = require('moment');

		const cancelling = true;

		let stripeSubExpire = moment(this.props.user.data.stripe_subscription_expires_at);

		if (
			window.confirm(`Are you sure you want to cancel your subscription? It will remain active until ${stripeSubExpire} after you cancel.`) &&
			!errors
		) {
			this.props.billingSubscribe({ plan_id: this.props.billing.plans.free.id }, userId, cancelling);
		}
	};

	//   const logout = () => {

	//     if (window.confirm('You are logging out of Scripture Notes. Changes you made may not be saved.')) {

	//       this.props.logOutUser();

	//     }

	//   }

	// let { key } = this.props.data,

	//   { remove, user } = this.props;

	const logout = () => {
		localStorage.clear();

		localStorage.setItem('prev-email', authState.user.email);

		props.history.push('/login');
	};
	const titleFormat = (title, order, chapter, verseNumber = null) => {
		if (chapter && chapter.abbreviation !== null) {
			if (title === 'Doctrine and Covenants') {
				if (order === 139 || order === 140) {
					// title = 'OD';
					order === 139 ? (order = 1) : (order = 2);
					return `D&C OD ${order}${verseNumber ? `:${verseNumber}` : ''}`;
				}

				// return `D&C ${order}${verseNumber ? `:${verseNumber}` : ''}`;
			}
			return chapter.chapter_name === title ? `${title}` : `${title} ${chapter.abbreviation}${verseNumber ? `:${verseNumber}` : ''}`;
		}
		if (title === 'Doctrine and Covenants') {
			if (order === 139 || order === 140) {
				// title = 'OD';
				order === 139 ? (order = 1) : (order = 2);
				return `OD ${order}${verseNumber ? `:${verseNumber}` : ''}`;
			}

			return `D&C ${order}${verseNumber ? `:${verseNumber}` : ''}`;
		}
		// if ((title === 'Doctrine and Covenants' && order === 139) || order === 140) {
		// 	title = 'Official Declaration ';
		// 	order === 139 ? (order = 1) : (order = 2);
		// 	return `${title} ${order}${verseNumber ? `:${verseNumber}` : ''}`;
		// }
		return `${title} ${order}${verseNumber ? `:${verseNumber}` : ''}`;
	};

	const [importNotesModalIsOpen, setImportNotesModalIsOpen] = useState(false);

	function handleImportNotes() {
		setImportNotesModalIsOpen(!importNotesModalIsOpen);
	}
	const fileReader = new FileReader();
	const handleImport = () => {
		if (csvFile) {
			Papa.parse(csvFile, {
				header: true,
				skipEmptyLines: true,
				complete: async function (results) {
					if (results.data.length < 1) {
						alert(
							`Unfortunately your csv file is blank. If you know you have notes in Gospel Library, please report this to the Gospel Library team by going to https://www.churchofjesuschrist.org/feedback.”`
						);
						return;
					}
					const scriptures = results.data.filter(
						(e, i) =>
							(e['source location'] && e['source location'].includes('scriptures')) ||
							(e.type === 'journal' && e.title.length > 0) ||
							(e.type === 'journal' && e.notebooks.length > 0) ||
							(e.type === 'journal' && e.tags.length > 0)
						// (e.type === 'journal' &)
					);
					if (scriptures.length < 1) {
						alert(`Unfortunately there are no notes that we can import from your file at this time.`);
						return;
					}
					const journals = results.data.filter(e => e['type'] === 'journal');
					setImportLength(scriptures.length);
					setImportLoading(true);

					for (let i = 0; i < scriptures.length; i++) {
						if (scriptures[i]['type'] === 'highlight' || scriptures[i]['type'] === 'reference') {
							//Declares constant variable of each line in the csv
							// console.log(scriptureObj);
							const scriptureObj = scriptures[i];
							const date = new Date(Date.parse(scriptures[i]['last updated'])).toLocaleDateString('en-US');
							//Splits the link string for better access of relevant info about verse
							const links = scriptures[i]['source location'].split('/');
							//Destructuring scriptureobj
							const { notebooks, title, tags } = scriptureObj;
							console.log('asdf', tags, notebooks);
							//Getting the actual verse note
							const noteText = scriptureObj['note text'];
							if (scriptures[i]['source location'].includes('remission-of-sins')) {
								setProgress(i + 1);

								if (i === scriptures.length - 1) {
									setImportComplete(true);
									setImportLoading(false);
								}
								continue;
							}

							//If Statement to see if theres actual note text
							if (noteText.length > 0) {
								//Checks for notebooks
								if (notebooks.length > 0) {
									console.log('hitting notebooks not');
									//If Tags and Note book create or add to existing cn with name of notebook as title and note text to master note add verse and tag to cn
									if (tags.length > 0) {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteBook/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: noteText,
												dateCheck: dateCheck,
												updatedAt: scriptures[i]['last updated'],
												createdAt: scriptures[i]['created'],
												updatedDate: date,
												notebooks,
												tags,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
									//No tags, just make cn or add verse to existing cn with notebook name, title and note goes to master note
									else {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteBook/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: scriptures[i]['note text'],
												dateCheck: dateCheck,
												updatedDate: date,
												notebooks,
												tags: null,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
								} else {
									//If theres tags but no note book, create or add to existing cn with the name of the tag and add tag and verse to cn. note text goes to verse note
									if (tags.length > 0) {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addTags/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: scriptures[i]['note text'],
												dateCheck: dateCheck,
												updatedDate: date,
												tags,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
									//Just add verse note to verse
									else {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteToVerse/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: scriptures[i]['note text'],
												dateCheck: dateCheck,
												updatedDate: date,
												// notebooks,
												// tags,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
								}
							}
							//This else statement is just incase theres note books/tags just to create cn and add verse to cn
							else {
								if (notebooks.length > 0) {
									//Note book and Tags add/create cn with note book name, Title goes to master note, add verse to collection note
									if (tags.length > 0) {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteBook/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: null,
												dateCheck: dateCheck,
												updatedDate: date,
												notebooks,
												tags,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
									//Just note book no tags, title goes to master note, add verse to collection note
									else {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteBook/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: null,
												dateCheck: dateCheck,
												updatedDate: date,
												notebooks,
												tags: null,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
								} else {
									//No note book just tags, create cn, add verse to cn, title goes to verse note
									if (tags.length > 0) {
										await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addTags/${authState.user.id}`, {
											method: 'PUT',
											headers: {
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												volume: links[5],
												book: links.length === 8 ? links[6] : null,
												chapter: links.length === 8 ? links[7] : links[6],
												note: null,
												dateCheck: dateCheck,
												updatedDate: date,
												tags,
												title,
											}),
										})
											.then(res => res.json())
											.then(res => {
												setProgress(i + 1);

												if (i === scriptures.length - 1) {
													setImportComplete(true);
													setImportLoading(false);
												}
											});
									}
									//Just add title to verse note
									else {
										//If its undefined just continue the loop
										if (title !== 'undefined') {
											console.log('hitting title !== undefined', typeof title, title);

											await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteToVerse/${authState.user.id}`, {
												method: 'PUT',
												headers: {
													'Content-Type': 'application/json',
												},
												body: JSON.stringify({
													volume: links[5],
													book: links.length === 8 ? links[6] : null,
													chapter: links.length === 8 ? links[7] : links[6],
													note: title,
													dateCheck: dateCheck,
													updatedDate: date,
													// notebooks,
													// tags,
													title,
												}),
											})
												.then(res => res.json())
												.then(res => {
													setProgress(i + 1);

													if (i === scriptures.length - 1) {
														setImportComplete(true);
														setImportLoading(false);
													}
												});
										} else {
											setProgress(i + 1);

											if (i === scriptures.length - 1) {
												setImportComplete(true);
												setImportLoading(false);
											}
										}
									}
								}
							}

							//

							//! This is the only api request just for future reference
							// await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteToVerse/${authState.user.id}`, {
							// 	method: 'PUT',
							// 	headers: {
							// 		'Content-Type': 'application/json',
							// 	},
							// 	body: JSON.stringify({
							// 		volume: links[5],
							// 		book: links.length === 8 ? links[6] : null,
							// 		chapter: links.length === 8 ? links[7] : links[6],
							// 		note: scriptures[i]['note text'],
							// 		dateCheck: dateCheck,
							// 		updatedDate: date,
							// 	}),
							// })
							// 	.then(res => res.json())
							// 	.then(res => {
							//
							// 		setProgress(i + 1);

							// 		if (i === scriptures.length - 1) {
							// 			setImportComplete(true);
							// 			setImportLoading(false);
							// 		}
							// 	});
						}
						//This is legacy code for journals to make CNs with the title as the name

						if (
							(scriptures[i]['type'] === 'journal' && scriptures[i].title !== 'undefined') ||
							(scriptures[i]['type'] === 'reference' && scriptures[i].title !== 'undefined')
						) {
							const scriptureObj = scriptures[i];
							const { notebooks, title, tags } = scriptureObj;
							console.log(title);
							// console.log('hitting journal', scriptures[i]);
							if (!tags.length > 0 && !notebooks.length > 0) {
								await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addJournal/${authState.user.id}`, {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({
										note: scriptures[i]['note text'],
										dateCheck: dateCheck,
										updatedAt: scriptures[i]['last updated'],
										createdAt: scriptures[i]['created'],
										title: scriptures[i]['title'],
									}),
								})
									.then(res => res.json())
									.then(res => {
										setProgress(i + 1);
										if (i === scriptures.length - 1) {
											setImportComplete(true);
											setImportLoading(false);
										}
									});
							}
							if (
								(scriptures[i]['type'] === 'journal' && scriptures[i].title === 'undefined') ||
								(scriptures[i]['type'] === 'reference' && scriptures[i].title === 'undefined')
							) {
								setProgress(i + 1);
								if (i === scriptures.length - 1) {
									setImportComplete(true);
									setImportLoading(false);
								}
								continue;
							}
							if (notebooks.length > 0) {
								// console.log('hitting notebooks only');
								//Declares constant variable of each line in the csv
								// console.log(scriptureObj);
								const date = new Date(Date.parse(scriptures[i]['last updated'])).toLocaleDateString('en-US');
								//Splits the link string for better access of relevant info about verse
								const links = scriptures[i]['source location'].split('/');
								//Destructuring scriptureobj

								//Getting the actual verse note
								const noteText = scriptureObj['note text'];
								// console.log(tags);
								//If Tags and Note book create or add to existing cn with name of notebook as title and note text to master note add verse and tag to cn
								if (tags.length > 0) {
									await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteBookNoVerse/${authState.user.id}`, {
										method: 'PUT',
										headers: {
											'Content-Type': 'application/json',
										},
										body: JSON.stringify({
											note: noteText,
											dateCheck: dateCheck,
											updatedAt: scriptures[i]['last updated'],
											createdAt: scriptures[i]['created'],
											updatedDate: date,
											notebooks,
											tags,
											title,
										}),
									})
										.then(res => res.json())
										.then(res => {
											setProgress(i + 1);

											if (i === scriptures.length - 1) {
												setImportComplete(true);
												setImportLoading(false);
											}
										});
								}
								//No tags, just make or add to cn
								else {
									await fetch(`${process.env.REACT_APP_BASE_URL}/importNotes/addNoteBookNoVerse/${authState.user.id}`, {
										method: 'PUT',
										headers: {
											'Content-Type': 'application/json',
										},
										body: JSON.stringify({
											note: scriptures[i]['note text'],
											dateCheck: dateCheck,
											updatedDate: date,
											notebooks,
											tags: null,
											title,
										}),
									})
										.then(res => res.json())
										.then(res => {
											setProgress(i + 1);

											if (i === scriptures.length - 1) {
												setImportComplete(true);
												setImportLoading(false);
											}
										});
								}
							}
						}
					}
				},
			});
		} else alert('Please add a valid import file.');
	};
	const CheckCancelled = () => {
		if (!hasProAccount()) {
			return (
				<div style={{ marginTop: '15px' }}>
					<SubUpgradeLink onClick={() => upgradePlan()}>Upgrade to PRO</SubUpgradeLink>
				</div>
			);
		} else {
			if (hasProCancelled()) {
				return (
					<div style={{ marginTop: '15px' }}>
						<SubUpgradeLink onClick={() => upgradePlan()}>Upgrade to PRO</SubUpgradeLink>
					</div>
				);
			} else if (stripePlanName == 'family-member') {
				return '';
			} else {
				return <CancelStripe {...props} />;
			}
		}
	};
	return (
		<DashboardWrapper
			key='dashboardContent'
			style={{ backgroundColor: '#fafafa', padding: panel === 'default' ? '180px 10px 10px 25px' : '110px 10px 10px 25px' }}
		>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon
						icon={faTimes}
						onClick={() => {
							props.closePane(props.id);
						}}
					/>
				</Tooltip>
			</ClosePaneWrapper>

			{/* <Modal isOpen={importNotesModalIsOpen} style={importNotesModalStyle} onRequestClose={handleImportNotes}>
				<ImportNotes handleImportNotes={handleImportNotes} />
			</Modal> */}

			<DragPaneWrapper>
				<ProfileInfoWrapper>
					<ProfileInfoLeft>
						<UserDataName {...props.dragHandleProps}>
							{props.user.name}
							{panel !== 'default' && (
								<EditNameSpan>
									<PortalWithState closeOnOutsideClick closeOnEsc>
										{({ openPortal, closePortal, isOpen, portal }) => (
											<>
												{/* <ChangeEmailBtn onClick={openPortal}>Change name</ChangeEmailBtn> */}
												<FontAwesomeIcon
													icon={faEdit}
													color='#7e8087'
													data-tip='Scroll Left'
													style={{
														marginBottom: '5px',
														marginLeft: '5px',
														fontSize: '14px',
														cursor: 'pointer',
													}}
													onClick={openPortal}
												/>
												{portal(
													<div>
														<div
															style={{
																top: 0,
																zIndex: 9999999,
																background: '#ddd',
																width: '100%',
																height: '100%',
																minHeight: '392px',
																opacity: '0.6',
																position: 'absolute',
															}}
														/>
														<DeletePopUpWrapper>
															<FormGroup style={{ width: '100%', marginTop: '15px' }}>
																<ChangePasswordText>Edit name</ChangePasswordText>
																{/* <ErrorMessage>{changePasswordError}</ErrorMessage> */}
																<ChangePasswordInput
																	type='text'
																	name='newName'
																	value={newName}
																	placeholder={'Enter new name...'}
																	onChange={e => setNewName(e.target.value)}
																/>
															</FormGroup>
															<DeletePopUpBtnWrapper>
																<DeletePopUpBtn
																	onClick={() => {
																		changeName();
																		closePortal();
																	}}
																>
																	Change Name
																</DeletePopUpBtn>
																<ClosePopUpBtn onClick={closePortal}>Cancel</ClosePopUpBtn>
															</DeletePopUpBtnWrapper>
														</DeletePopUpWrapper>
													</div>
												)}
											</>
										)}
									</PortalWithState>
								</EditNameSpan>
							)}
						</UserDataName>
						<ActionList>
							{panel !== 'default' && (
								<ActionItem>
									<span onClick={() => setPanel('default')}>Back</span> |
								</ActionItem>
							)}

							{panel === 'default' && (
								<ActionItem>
									<span onClick={() => setPanel('account')}>Account Settings</span> |
								</ActionItem>
							)}

							<ActionItem>
								<span onClick={() => logout()}>Sign Out</span>
							</ActionItem>
						</ActionList>

						{panel === 'account' && (
							<>
								<AccountSettingsText>Account Settings</AccountSettingsText>
							</>
						)}
					</ProfileInfoLeft>

					{panel === 'default' && (
						<ProfileInfoRight>
							{/* {userBrowser !== 'Chrome' && userBrowser !== 'Firefox' && panel === 'default' && (
								<BrowserBannerText style={{ color: 'red' }}>
									<b>
										For the best browser compatibility, we recommend using{' '}
										<a target='_blank' rel='noopener noreferrer' href='https://www.google.com/chrome/'>
											Chrome
										</a>{' '}
										or{' '}
										<a target='_blank' rel='noopener noreferrer' href='https://www.mozilla.org/firefox/'>
											Firefox.
										</a>
									</b>
								</BrowserBannerText>
							)} */}

							{/* {stripePlanName === 'noplan' && panel === 'default' && (

								<BrowserBannerText>

									<b>You have <span style={{ color: 'red' }}>{this.state.subscriptionDaysLeft}</span> days remaining on trial.</b>

									<b>

										You have <span style={{ color: 'red' }}>10</span> days remaining on trial.

									</b>

								</BrowserBannerText>

							) */}

							{!hasProAccount() && <FreeBanner setPanel={setPanel} />}
						</ProfileInfoRight>
					)}
				</ProfileInfoWrapper>

				{/*

          =====================================================================

          HISTORY

          =====================================================================

          */}

				{panel === 'default' && (
					<>
						<HistoryText>
							<ChaptersContainer>
								<HistoryViewContainer>
									<div
										onClick={() => {
											setHistoryView('history');
										}}
										style={{
											color: historyView === 'history' ? '#324599' : '#94b3db',
											fontWeight: historyView === 'history' ? '800' : '400',
										}}
									>
										<p>History</p>
									</div>
									{(dashboardState[props.uniqueKey] && dashboardState[props.uniqueKey].chaptersRead.length > 0) ||
									(dashboardState[props.uniqueKey] && dashboardState[props.uniqueKey].notesViewed.length > 0) ||
									(dashboardState[props.uniqueKey] && dashboardState[props.uniqueKey].searchHistory.length > 0) ? (
										<ClearHistory uniqueKey={props.uniqueKey} userId={props.user.id} title={'All'} />
									) : null}
								</HistoryViewContainer>

								<span style={{ marginLeft: '5px', marginRight: '5px' }}>|</span>
								<div
									onClick={() => {
										setHistoryView('bookmarks');
									}}
									style={{
										color: historyView === 'bookmarks' ? '#324599' : '#94b3db',
										fontWeight: historyView === 'bookmarks' ? '800' : '400',
									}}
								>
									<p>Bookmarks</p>
								</div>
							</ChaptersContainer>{' '}
						</HistoryText>
						{historyView === 'bookmarks' && (
							<HistoryColumnWidth>
								<HistoryColumnText>Verse Bookmarks</HistoryColumnText>
							</HistoryColumnWidth>
						)}

						{historyView === 'history' && (
							<HistoryTextColumnWrapper>
								<HistoryColumnWidth>
									<HistoryColumnText>
										Chapter History
										{dashboardState[props.uniqueKey] && dashboardState[props.uniqueKey].chaptersRead.length > 0 && (
											<ClearHistory uniqueKey={props.uniqueKey} userId={props.user.id} title={'Chapters Read'} />
										)}
									</HistoryColumnText>
								</HistoryColumnWidth>

								<HistoryColumnWidth>
									<HistoryColumnText>
										Search History
										{dashboardState[props.uniqueKey] && dashboardState[props.uniqueKey].searchHistory.length > 0 && (
											<ClearHistory uniqueKey={props.uniqueKey} userId={props.user.id} title={'Search History'} />
										)}
									</HistoryColumnText>
								</HistoryColumnWidth>
								<HistoryColumnWidth>
									<HistoryColumnText>
										Collection Notes
										{dashboardState[props.uniqueKey] && dashboardState[props.uniqueKey].notesViewed.length > 0 && (
											<ClearHistory uniqueKey={props.uniqueKey} userId={props.user.id} title={'Notes Viewed'} />
										)}
									</HistoryColumnText>
								</HistoryColumnWidth>
							</HistoryTextColumnWrapper>
						)}
					</>
				)}
			</DragPaneWrapper>

			{/*

        =====================================================================

        ACCOUNT SETTINGS

        =====================================================================

        */}

			{panel === 'account' && (
				<AccountSettingsWrapper>
					{/* <AccountSettingsWrapper style={stripePlanName.trim() == "Pro" ? { marginTop: '200px' } : cardLastFour == false ? { marginTop: '180px' } : {}}> */}
					<Scrollbars>
						<UserDataEmail>
							<span>Update Profile </span>
							<FontAwesomeIcon
								icon={faEdit}
								color='#7e8087'
								data-tip='Scroll Left'
								style={{
									margin: '0 8px',
									fontSize: '14px',
									cursor: 'pointer',
								}}
								onClick={() => changeEmail()}
							/>
							{/* <ChangeEmailBtn onClick={() => changeEmail()}>Change email</ChangeEmailBtn> */}
						</UserDataEmail>

						<UpdateFieldsContainer>
							<div>
								<PortalWithState closeOnOutsideClick closeOnEsc>
									{({ openPortal, closePortal, isOpen, portal }) => (
										<>
											{!props.user.provider_id && <ChangeEmailBtn onClick={openPortal}>Change Password</ChangeEmailBtn>}
											{portal(
												<div>
													<div
														style={{
															top: 0,
															zIndex: 9999999,
															background: '#ddd',
															width: '100%',
															height: '100%',
															minHeight: '392px',
															opacity: '0.6',
															position: 'absolute',
														}}
													/>
													<DeletePopUpWrapper>
														<div>
															<ChangePasswordText>Change password</ChangePasswordText>
															{/* <ForgotPasswordForm onSubmit={this.onSubmit}> */}
															<ForgotPasswordForm>
																{/* {this.renderStatusMessage()} */}
																<FormGroup>
																	<input type='hidden' name='key' />
																	<ChangePasswordLabel>Old password</ChangePasswordLabel>
																	<ChangePasswordInput
																		type='password'
																		name='oldPassword'
																		value={oldPassword}
																		onChange={e => setOldPassword(e.target.value)}
																		style={{ width: '100%' }}
																	/>
																</FormGroup>
																<FormGroup>
																	<ChangePasswordLabel>New password</ChangePasswordLabel>
																	<ChangePasswordInput
																		type='password'
																		name='newPassword'
																		value={newPassword}
																		onChange={e => setNewPassword(e.target.value)}
																		style={{ width: '100%' }}
																	/>
																</FormGroup>
																{/* <ChangePasswordBtn type='button' onClick={() => changePassword()} style={{ width: '100%' }}>
																		Change password
																	</ChangePasswordBtn> */}
															</ForgotPasswordForm>
														</div>
														<DeletePopUpBtnWrapper>
															<DeletePopUpBtn
																onClick={() => {
																	changePassword();
																	closePortal();
																}}
															>
																Change Password
															</DeletePopUpBtn>
															<ClosePopUpBtn onClick={closePortal}>Cancel</ClosePopUpBtn>
														</DeletePopUpBtnWrapper>
														{/* <ChangePasswordBtn
																type='button'
																onClick={() => {
																	changeName();
																	closePortal();
																}}
																style={{ width: '100%' }}
															>
																Change Name
															</ChangePasswordBtn> */}
													</DeletePopUpWrapper>
												</div>
											)}
										</>
									)}
								</PortalWithState>
							</div>
						</UpdateFieldsContainer>

						{successfulNameChange && <SuccessMessage>Name successfully updated!</SuccessMessage>}
						{changePasswordError && <ErrorMessage>{changePasswordError}</ErrorMessage>}
						{successfulPasswordUpdate && <SuccessMessage>Password successfully updated!</SuccessMessage>}
						<AffiliateSection>
							<label>Affiliate Code</label>
							<input
								type='text'
								name='affiliate_code'
								value={affilateCode}
								onChange={e => setAffilateCode(e.target.value)}
								style={{ marginLeft: '3px' }}
							/>
							<FontAwesomeIcon
								icon={faSave}
								color='#7e8087'
								data-tip='Scroll Left'
								style={{
									margin: '0 8px',
									fontSize: '22px',
									cursor: 'pointer',
								}}
								onClick={() => saveAffiliate()}
							/>
							<a target='_blank' href='https://scripturenotes.com/affiliate-program'>
								Affiliate Program Information
							</a>

							<FontAwesomeIcon
								icon={faInfoCircle}
								color='#7e8087'
								data-tip
								data-for='affiliateTip'
								style={{
									margin: '0 8px',
									fontSize: '22px',
									cursor: 'pointer',
								}}
							/>
							<ReactTooltip id='affiliateTip' place='top' effect='solid'>
								For sharing CN's soon...
							</ReactTooltip>
						</AffiliateSection>
						{isOpenPopup && <Popup onClick={() => changeEmail()} handleClose={changeEmail} />}
						{isOpenPopup2 && <PopupCC onClick={() => changeCC()} handleClose={changeCC} />}
						{isOpenPopup3 && (
							<PopupSub
								diffDays={diffDays}
								newDays={newDays}
								newYearlyDate={newYearlyDate}
								expires_at={props.user.stripe_subscription_expires_at}
								upgradeToPlan={upgradeToPlan}
								upgradeTo={upgradeTo}
								planType={planType}
								history={props.history}
								onClick={() => changeSub()}
								handleClose={changeSub}
							/>
						)}
						{isOpenPopup4 && <PopupMember history={props.history} onClick={() => addMember()} handleClose={addMember} />}
						{isOpenPopup5 && <PopupUpgrade history={props.history} onClick={() => upgradePlan()} handleClose={upgradePlan} />}
						{importIsOpen && (
							<ImportNotes
								setDateCheck={setDateCheck}
								dateCheck={setDateCheck}
								setImportIsOpen={setImportIsOpen}
								progress={progress}
								setCsvFile={setCsvFile}
								importLength={importLength}
								importComplete={importComplete}
								importLoading={importLoading}
								dispatch={dispatch}
								handleImport={handleImport}
								csvFile={csvFile}
								uniqueKey={props.uniqueKey}
							/>
						)}
						{renderPlanTextMessage()}
						{!authState.user.giver_email && stripePlanName !== 'family-member' && !hasCancelled() && (
							<CcUpdateLink onClick={() => changeCC()}>Update Credit Card</CcUpdateLink>
						)}

						{stripePlanName === 'Pro - Monthly' && !hasCancelled() && (
							<div>
								<SubUpdateLink onClick={() => changeSub('Yearly')}>Upgrade to Yearly Program $49.95 (Get 2 Months Free)</SubUpdateLink>

								<SubUpdateLink onClick={() => changeSub('3year')}>Upgrade to 3 Year Program $129.95 (28% savings)</SubUpdateLink>

								<SubUpdateLink onClick={() => changeSub('5year')}>Upgrade to 5 Year Program $199.95 (33% savings)</SubUpdateLink>

								<SubUpdateLink onClick={() => changeSub('10year')}>Upgrade to 10 Year Program $349.95 (42% savings)</SubUpdateLink>
							</div>
						)}

						{stripePlanName === 'Pro - Yearly' && !hasCancelled() && (
							<div>
								<SubUpdateLink onClick={() => changeSub('3year')}>Upgrade to 3 Year Program $129.95 (28% savings)</SubUpdateLink>

								<SubUpdateLink onClick={() => changeSub('5year')}>Upgrade to 5 Year Program $199.95 (33% savings)</SubUpdateLink>

								<SubUpdateLink onClick={() => changeSub('10year')}>Upgrade to 10 Year Program $349.95 (42% savings)</SubUpdateLink>
							</div>
						)}

						{stripePlanName === 'Pro - 3Yearly' && !hasCancelled() && (
							<div>
								<SubUpdateLink onClick={() => changeSub('5year')}>Upgrade to 5 Year Program $199.95 (33% savings)</SubUpdateLink>
								<SubUpdateLink onClick={() => changeSub('10year')}>Upgrade to 10 Year Program $349.95 (42% savings)</SubUpdateLink>
							</div>
						)}

						{stripePlanName === 'Pro - 5Yearly' && !hasCancelled() && (
							<SubUpdateLink onClick={() => changeSub('10year')}>Upgrade to 10 Year Program $349.95 (42% savings)</SubUpdateLink>
						)}

						{(stripePlanName === 'Pro - Yearly' ||
							stripePlanName === 'Pro - Monthly' ||
							stripePlanName === 'Pro' ||
							stripePlanName === 'Pro - 3Yearly' ||
							stripePlanName === 'Pro - 5Yearly' ||
							stripePlanName === 'Pro - 10Yearly') && <SubUpdateLink onClick={() => addMember()}>Manage Family Members</SubUpdateLink>}

						<div style={{ paddingRight: '15px' }}>
							<div>
								<div>
									{/* {renderPlanTextMessage()} */}

									{/* {(props.user.stripePlanName === 'noplan' || props.user.stripePlanName === 'Free') && */}

									{/* <Para>Are you ready to subscribe?</Para> && */}

									{/* <StripeCheckout

                                                            name="Scripture Notes" // the pop-in header title

                                                            description={stripeKey.name + " Subscription Option"} // the pop-in header subtitle

                                                            image="https://scripturenotes.com/wp-content/uploads/2018/02/favicon.ico" // the pop-in header image (default none)

                                                            ComponentClass="div"

                                                            amount={this.props.billing.plans.price}

                                                            currency="USD"

                                                            email={props.user.data.email} // pre-populate the email field

                                                            stripeKey={stripeKey}

                                                            locale="auto"

                                                            zipCode={true}

                                                            panelLabel="Subscribe"

                                                            allowRememberMe // "Remember Me" option (default true)

                                                            token={(token) => this.onToken(token, this.props.billing.plans.id)} // submit callback

                                                            opened={this.onOpened} // called when the checkout popin is opened (no IE6/7)

                                                            closed={this.onClosed} // called when the checkout popin is closed (no IE6/7)



                                                            // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason

                                                            // you are using multiple stripe keys

                                                            reconfigureOnUpdate={false}



                                                        // Note: you can change the event to `onTouchTap`, `onClick`, `onTouchStart`

                                                        // useful if you're using React-Tap-Event-Plugin

                                                        // triggerEvent="onTouchTap"

                                                        >

                                                            <StripeBtn>

                                                                 Subscription

                              </StripeBtn>

                                                        </StripeCheckout>





                                            } */}
								</div>
							</div>

							<CheckCancelled />

							{/* { !isProUser() || isTrialUser() ? <div>
									<MonthlyStripe />
									<YearlyStripe />
								</div> : <CancelStripe/>  } */}

							{/* { !isProUser() ? <div>
									<MonthlyStripe />
									<YearlyStripe />
								</div> : !isTrialUser() ? <CancelStripe/> : <div>
									You currently are in a free trial. Once this expires you'll have the option to purchase the pro features.
								</div> } */}

							<hr style={{ marginTop: '10px', marginBottom: '10px' }} />

							{/*

							=====================================================================

							CHANGE PASSWORD

							=====================================================================

						*/}

							{/* <div>
									<ChangePasswordText>Change password</ChangePasswordText>

									{changePasswordError && <ErrorMessage>{changePasswordError}</ErrorMessage>}
									{successfulPasswordUpdate && <SuccessMessage>Password successfully updated!</SuccessMessage>} */}

							{/* <ForgotPasswordForm onSubmit={this.onSubmit}> */}

							{/* <ForgotPasswordForm> */}
							{/* {this.renderStatusMessage()} */}

							{/* <FormGroup>
											<input type='hidden' name='key' />

											<ChangePasswordLabel>Old password</ChangePasswordLabel>

											<ChangePasswordInput
												type='password'
												name='oldPassword'
												value={oldPassword}
												onChange={e => setOldPassword(e.target.value)}
												style={{ width: '100%' }}
											/>
										</FormGroup>

										<FormGroup>
											<ChangePasswordLabel>New password</ChangePasswordLabel>

											<ChangePasswordInput
												type='password'
												name='newPassword'
												value={newPassword}
												onChange={e => setNewPassword(e.target.value)}
												style={{ width: '100%' }}
											/>
										</FormGroup>

										<ChangePasswordBtn type='button' onClick={() => changePassword()} style={{ width: '100%' }}>
											Change password
										</ChangePasswordBtn>
									</ForgotPasswordForm>
								</div>
							 */}

							{/*

							=====================================================================

							IMPORT GOSPEL LIBRARY NOTES

							=====================================================================

						*/}
							<ImportNotesTitle>Import Gospel Library Notes</ImportNotesTitle>
							<ImportNotesModalBtn onClick={() => setImportIsOpen(true)}>Import Notes</ImportNotesModalBtn>
							{(authState.user.email === 'todd@brightbridgeweb.com' || authState.user.email === 'oak@oaknorton.com') && (
								<div>
									<ImportNotesTitle>Download library to CSV</ImportNotesTitle>
									<ImportNotesModalBtn onClick={() => handleLibraryDownload()}>Download</ImportNotesModalBtn>
								</div>
							)}
							{(authState.user.email === 'todd@brightbridgeweb.com' || authState.user.email === 'oak@oaknorton.com') && (
								// <div>
								// 	<ImportNotesTitle>User Account Change</ImportNotesTitle>
								// 	<ImportNotesModalBtn onClick={() => handleSearchUser()}>Search</ImportNotesModalBtn>
								// </div>
								<UserPlanChange />
							)}

							{/* <PortalWithState closeOnOutsideClick closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<ImportNotesModalBtn onClick={openPortal}>Import Notes</ImportNotesModalBtn>
										{portal(
											<div>
												<div
													style={{
														top: 0,
														zIndex: 9999999,
														background: '#ddd',
														width: '100%',
														height: '100%',
														minHeight: '392px',
														opacity: '0.6',
														position: 'absolute',
													}}
												/>
												<DeletePopUpWrapper>
													<ImportNotesContainer>
														<ImportNotesTitle>Import Gospel Library Notes</ImportNotesTitle>
														<p>(Read Instructions First)</p>
														<p>
															1) Instructions{' '}
															<a
																href='https://scripturenotes.com/how-to-import-gospel-library-notes-to-scripture-notes'
																target='_blank'
															>
																HERE
															</a>
														</p>
														<p>2) Click the button to locate import file</p>
														<div>
															<input
																id='Browse'
																type='file'
																accept='.csv'
																onChange={e => {
																	setCsvFile(e.target.files[0]);
																}}
															/>
															<div style={{ marginTop: 10 }}>
																<label htmlFor='noDate'>3) Include Date of notes? (OPTIONAL)</label>
																<input
																	style={{ marginLeft: 5 }}
																	value={dateCheck}
																	type='checkbox'
																	label='noDate'
																	name='noDate'
																	onClick={() => setDateCheck(!dateCheck)}
																/>
															</div>
															<PortalWithState closeOnOutsideClick closeOnEsc>
																{({ openPortal, closePortal, isOpen, portal }) => (
																	<>
																		<ImportNotesBtn onClick={openPortal}>4) Import Notes</ImportNotesBtn>
																		
																		{portal(
																			<div>
																				<div
																					style={{
																						top: 0,
																						zIndex: 9999999,
																						background: '#ddd',
																						width: '100%',
																						height: '100%',
																						minHeight: '392px',
																						opacity: '0.6',
																						position: 'absolute',
																					}}
																				/>

																				<DeletePopUpWrapper>
																					{importLoading ? (
																						<ProgressBarContainer>
																							<h1 style={{ color: '#324599' }}>Importing</h1>
																							<input
																								type='range'
																								max={importLength}
																								value={progress}
																								// disabled
																								style={{ width: '100%', color: '#324599' }}
																							/>
																							<h3>{`${progress} out of ${importLength}`}</h3>
																						</ProgressBarContainer>
																					) : importComplete ? (
																						<div>
																							<p>Your import is complete</p>
																							<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																						</div>
																					) : (
																						<div>
																							<p>
																								Are you sure you'd like to continue? This may take some time. We will now
																								close all panes while the import file processes.
																							</p>
																							<DeletePopUpBtnWrapper>
																								<DeletePopUpBtn
																									onClick={() => {
																										dispatch({
																											type: 'CLOSE_OTHER_PANES',
																											payload: { paneId: props.uniqueKey },
																										});
																										handleImport();
																									}}
																								>
																									Continue
																								</DeletePopUpBtn>
																								<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																							</DeletePopUpBtnWrapper>
																						</div>
																					)}
																					
																				</DeletePopUpWrapper>
																			</div>
																		)}
																	</>
																)}
															</PortalWithState>
														</div>
														<p style={{ color: 'red' }}>
															CAUTION: ONLY DO THIS ONE TIME FOR YOUR NOTES OR YOU WILL WIND UP WITH DOUBLE NOTES ON VERSES AND WE
															CANNOT REMOVE OR UNDO THIS PROCESS.
														</p>
													</ImportNotesContainer>
													<DeletePopUpBtnWrapper>
														<ClosePopUpBtn onClick={closePortal}>Cancel</ClosePopUpBtn>
													</DeletePopUpBtnWrapper>
												</DeletePopUpWrapper>
											</div>
										)}
									</>
								)}
							</PortalWithState> */}
						</div>
					</Scrollbars>
				</AccountSettingsWrapper>
			)}

			{/*

        =====================================================================

        HISTORY

        =====================================================================

        */}

			{panel === 'default' && (
				<ContentPaneWrapper>
					{historyView === 'history' ? (
						<ContentBottomDashboard>
							<ContentColumnWrapper>
								<Scrollbars autoHide>
									<ContentPane>
										<DashboardListWrapper>
											{dashboardState[props.uniqueKey] &&
												dashboardState[props.uniqueKey].chaptersRead.map((value, index) => (
													<DashboardListItem
														DashboardListItem
														key={index}
														onClick={e => {
															e.persist();
															e.stopPropagation();
															if (e.ctrlKey || e.metaKey) {
																return window.open(
																	`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.title}&chapter=${value.id}&book=${value.book_id}`,
																	'_blank'
																);
															} else {
																handleOpenVerses({
																	chapterId: value.id,
																	bookId: value.book_id,
																	originPaneKey: props.uniqueKey,
																	proUser: isProUser(),
																});
															}
														}}
													>
														{titleFormat(value.title, value.order, value).length > 19 ? (
															<Tooltip content={titleFormat(value.title, value.order, value)}>
																<span>{titleFormat(value.title, value.order, value)}</span>
															</Tooltip>
														) : (
															<span>{titleFormat(value.title, value.order, value)}</span>
														)}
													</DashboardListItem>
												))}
										</DashboardListWrapper>
									</ContentPane>
								</Scrollbars>
							</ContentColumnWrapper>
							<ContentColumnWrapper style={{ marginRight: '0px' }}>
								<Scrollbars autoHide>
									<ContentPane>
										<DashboardListWrapper>
											{dashboardState[props.uniqueKey] &&
												dashboardState[props.uniqueKey].searchHistory.map((value, index) => {
													return (
														<DashboardListItem key={index} onClick={() => handleSearch(value.searchKeyword, value)}>
															{value.searchKeyword.length > 23 ? (
																<Tooltip content={value.searchKeyword}>
																	<span>{value.searchKeyword}</span>
																</Tooltip>
															) : (
																<span>{value.searchKeyword}</span>
															)}
														</DashboardListItem>
													);
												})}
										</DashboardListWrapper>
									</ContentPane>
								</Scrollbars>
							</ContentColumnWrapper>
							<ContentColumnWrapper>
								<Scrollbars autoHide>
									<ContentPane>
										<DashboardListWrapper>
											{dashboardState[props.uniqueKey] &&
												dashboardState[props.uniqueKey].notesViewed.map((value, index) => (
													<DashboardListItem
														key={index}
														onClick={e => {
															e.persist();
															e.stopPropagation();
															if (e.ctrlKey || e.metaKey) {
																return window.open(
																	`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=${value.id}`,
																	'_blank'
																);
															} else {
																handleAddCollection(value.id, props.uniqueKey);
															}
														}}
													>
														{value.title.length > 23 ? (
															<Tooltip content={value.title}>
																<span>{value.title}</span>
															</Tooltip>
														) : (
															<span>{value.title}</span>
														)}
														{/* <span>{value.title}</span> */}
													</DashboardListItem>
												))}
										</DashboardListWrapper>
									</ContentPane>
								</Scrollbars>
							</ContentColumnWrapper>
						</ContentBottomDashboard>
					) : (
						<ContentBottomDashboard>
							<Scrollbars autoHide>
								<ContentPane>
									<DashboardListWrapper>
										{dashboardState[props.uniqueKey] &&
											dashboardState[props.uniqueKey].bookmarks.map((value, index) => (
												<DashboardListItem key={index}>
													<BookmarkContainer>
														<div
															onClick={e => {
																e.persist();
																e.stopPropagation();
																if (e.ctrlKey || e.metaKey) {
																	return window.open(
																		`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.bookTitle}&chapter=${value.chapterId}&book=${value.bookId}&verseNumber=${value.verseNumber}`,
																		'_blank'
																	);
																} else {
																	handleOpenVerses({
																		chapterId: value.chapterId,
																		initialChapterId: value.chapterId,
																		bookId: value.bookId,
																		originPaneKey: props.uniqueKey,
																		verseNumber: value.verseNumber,
																		proUser: isProUser(),
																	});
																}
															}}
														>
															{titleFormat(value.bookTitle, value.chapterOrder, value).length > 19 ? (
																<Tooltip content={titleFormat(value.bookTitle, value.chapterOrder, value, value.verseNumber)}>
																	<span>{titleFormat(value.bookTitle, value.chapterOrder, value, value.verseNumber)}</span>
																</Tooltip>
															) : (
																<span>{titleFormat(value.bookTitle, value.chapterOrder, value, value.verseNumber)}</span>
															)}
														</div>
														<div style={{ marginLeft: '15px' }}>
															<Tooltip content='Delete Bookmark' direction='up' forceDirection={true}>
																<FontAwesomeIcon icon={faTimes} onClick={() => handleDeleteBookmark(value)} />
															</Tooltip>
														</div>
													</BookmarkContainer>
												</DashboardListItem>
											))}
									</DashboardListWrapper>
								</ContentPane>
							</Scrollbars>
						</ContentBottomDashboard>
					)}
				</ContentPaneWrapper>
			)}
		</DashboardWrapper>
	);
};

export default Dashboard;
