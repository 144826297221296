import React, { useState, useEffect } from "react";

const AppDownloadBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isAndroid = /android/i.test(userAgent);

    if (isIOS || isAndroid) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) return null;

  // Set platform-specific app links
  const appLink = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    ? "https://apps.apple.com/us/app/scripture-notes/id1611795202"
    : "https://play.google.com/store/apps/details?id=com.snrnappmobile2";

  return (
<div style={{
  position: "fixed",
  bottom: "0",
  background: "#007bff",
  color: "white",
  padding: "15px",
  textAlign: "center",
  fontSize: "16px",
  zIndex: 999999, 
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
  pointerEvents: "auto",
}}>
      <span>📱 For the best mobile experience, {" "}
        <a href={appLink} style={{ color: "yellow", textDecoration: "underline" }}>
          Download the App
        </a>
      </span>
      <button
        onClick={() => setShowBanner(false)}
        style={{
          background: "transparent",
          border: "none",
          color: "white",
          fontSize: "18px",
          cursor: "pointer",
          marginLeft: "10px"
        }}
      >
        ✖
      </button>
    </div>
  );
};

export default AppDownloadBanner;