import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DarkLogo from '../../assets/logo_dark.png';

import styled from 'styled-components';

const LogoImage = styled.img`
	position: absolute;
	top: 32px;
	left: 32px;
	width: 84px;
	height: 41px;
`;

const ForgotPasswordWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100vh;
`;

const ContentWrapper = styled.div`
	padding: 0px 20px;
	width: 400px;
`;

const LoginSignupTextWrapper = styled.h1`
	display: flex;
	margin-bottom: 30px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 32px;
	color: #dedede;
`;

const LoginSignupText = styled.h2`
	margin-left: 5px;
	padding: 0px 7px 0px 7px;
`;

const PasswordRecoveryText = styled.p`
	margin-bottom: 25px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 14px;
	color: #7f7f7f;
`;

const PasswordRecoveryInput = styled.input`
	width: 100%;
	display: block;
	padding: 16px 10px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 22px;
	line-height: 28px;
	border: none;
	border-radius: 0;
	color: #000;
	background-color: #ebebeb;
	margin-bottom: 20px;
`;

const EmailLabel = styled.label`
	font-size: 18px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	display: block;
`;

const PasswordResetBtn = styled.button`
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	background-color: inherit;
	border: 2px solid #000;
	width: 120px;
	height: 50px;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
`;

const ErrorMessage = styled.div`
	color: red;
	font-size: 16px;
`;

const PasswordReset = () => {
	const [email, setEmail] = useState('');
	const [emailSubmit, setEmailSubmit] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');
	const resetPassword = async () => {
		try {
			let sendResetEmailRes = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/sendPasswordReset`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ emailAddress: email.toLowerCase() }),
			}).then(res => res.json());

			if (sendResetEmailRes === 'ok') setEmailSubmit(true);
			else if (sendResetEmailRes === 'Email not found.') setErrorMessage(sendResetEmailRes);
		} catch (err) {
			console.log('Error sending password reset email:', err);
		}
	};

	if (emailSubmit) {
		return (
			<div>
				<LogoImage key='logo' src={DarkLogo} alt='Scripture Notes Icon' />
				<ForgotPasswordWrapper style={{ flexDirection: 'column', justifyContent: 'center' }} key='loginFrom'>
					<ContentWrapper>
						<LoginSignupTextWrapper>
							<Link to='/login'>
								<LoginSignupText>Log in</LoginSignupText>
							</Link>
						</LoginSignupTextWrapper>
						<PasswordRecoveryText>
							A reset link will be sent to <span style={{ color: '#007BFF' }}>{email}</span> if an account exists for that email.
						</PasswordRecoveryText>
					</ContentWrapper>
				</ForgotPasswordWrapper>
			</div>
		);
	} else {
		return (
			<div>
				<LogoImage key='logo' src={DarkLogo} alt='Scripture Notes Icon' />
				<ForgotPasswordWrapper style={{ flexDirection: 'column', justifyContent: 'center' }} key='loginFrom'>
					<ContentWrapper>
						<LoginSignupTextWrapper>
							<Link to='/login'>
								<LoginSignupText>Log in</LoginSignupText>
							</Link>
						</LoginSignupTextWrapper>
						<PasswordRecoveryText>Password Recovery</PasswordRecoveryText>
						{errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
						<form>
							<EmailLabel htmlFor='inputEmail'>Email</EmailLabel>
							<PasswordRecoveryInput
								name='email'
								type='email'
								id='inputEmail'
								value={email}
								onChange={e => setEmail(e.target.value)}
								//   disabled={loading}
								autoFocus={false}
							/>
							<PasswordResetBtn type='button' onClick={() => resetPassword()}>
								Send
							</PasswordResetBtn>
						</form>
					</ContentWrapper>
				</ForgotPasswordWrapper>
			</div>
		);
	}
};

export default PasswordReset;
